import { Box, Button, DataTable, Pagination, FormField, DropButton, TextInput, Text } from 'grommet';
import { FormDown } from 'grommet-icons';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import FormSelect from 'src/components/utils/FormSelect';
import { searchLocationsAction } from 'src/store/location/location.action';
import { getLocations } from 'src/store/location/location.slice';
import { TransferLocation } from 'src/store/location/location.types';
import { RootState } from 'src/store/store';
import { searchTransfersAction } from 'src/store/transfer/transfer.action';
import { getTransfers } from 'src/store/transfer/transfer.slice';
import { SearchTransferPayload } from 'src/store/transfer/transfer.types';

export default function SearchTransfer(props) {
    const dispatch = useDispatch();
    const locations = useSelector((state: RootState) => getLocations(state));
    const transfers = useSelector((state: RootState) => getTransfers(state));

    const [origin, setOrigin] = useState<TransferLocation>();
    const [destination, setDestination] = useState<TransferLocation>();
    const [page, setPage] = useState<number>(1);
    const [searchPayload, setSearchPayload] = useState<SearchTransferPayload>({
        adultCount: 1,
        childCount: 0,
        infantCount: 0,
        babySeatCount: 0,
        page: page,
        take: transfers.meta.take,
    });

    useEffect(() => {
        dispatch(searchLocationsAction());
    }, []);

    const getTransfersRequest = () => {
        dispatch(searchTransfersAction(searchPayload));
    };

    const handleChange = ({ page }) => {
        setSearchPayload({ ...searchPayload, page });
        setPage(page);
        getTransfersRequest();
    };

    return (<Box flex pad="20px">
        <Text size='medium' weight={900} color="dark">Search Transfer</Text>
        <Box direction='row'>
            <FormSelect
                id="originSelect"
                name="origin"
                label="Origin"
                options={locations ?? []}
                value={origin}
                labelKey="name"
                valueKey="id"
                onChange={({ option }) => {
                    setOrigin(option);
                    setSearchPayload({ ...searchPayload, origin: option.id });
                }}
            />
            <Box width="20px" />
            <FormSelect
                id="destinationSelect"
                name="destination"
                label="Destination"
                options={locations ? locations.filter(e => e.id !== origin?.id) : []}
                value={destination}
                labelKey="name"
                valueKey="id"
                onChange={({ option }) => {
                    setDestination(option);
                    setSearchPayload({ ...searchPayload, destination: option.id });
                }}
            />
            <Box width="20px" />
            <PassengerCountDropdown
                adultCount={searchPayload.adultCount}
                adultOnIncrease={() => {
                    setSearchPayload({ ...searchPayload, adultCount: searchPayload.adultCount + 1 });
                }}
                adultOnDecrease={() => {
                    if (searchPayload.adultCount > 1) {
                        setSearchPayload({ ...searchPayload, adultCount: searchPayload.adultCount - 1 });
                    }
                }}
                childCount={searchPayload.childCount}
                childOnIncrease={() => {
                    setSearchPayload({ ...searchPayload, childCount: searchPayload.childCount + 1 });
                }}
                childOnDecrease={() => {
                    if (searchPayload.childCount > 0) {
                        setSearchPayload({ ...searchPayload, childCount: searchPayload.childCount - 1 });
                    }
                }}
                infantCount={searchPayload.infantCount}
                infantOnIncrease={() => {
                    setSearchPayload({ ...searchPayload, infantCount: searchPayload.infantCount + 1 });
                }}
                infantOnDecrease={() => {
                    if (searchPayload.infantCount > 0) {
                        setSearchPayload({ ...searchPayload, infantCount: searchPayload.infantCount - 1 });
                    }
                }}
                babySeatCount={searchPayload.babySeatCount}
                babySeatOnIncrease={() => {
                    setSearchPayload({ ...searchPayload, babySeatCount: searchPayload.babySeatCount + 1 });
                }}
                babySeatOnDecrease={() => {
                    if (searchPayload.babySeatCount > 0) {
                        setSearchPayload({ ...searchPayload, babySeatCount: searchPayload.babySeatCount - 1 });
                    }
                }}
            />
            <Box width="20px" />
            <Box pad={{ top: "32.5px" }}>
                <Button
                    disabled={!origin}
                    primary
                    label="Search"
                    onClick={getTransfersRequest}
                />
            </Box>
        </Box>
        <Box  >
            <DataTable
                columns={[
                    {
                        property: 'name',
                        header: "Name"
                    },
                    {
                        property: 'type',
                        header: 'Type',
                    },
                    {
                        property: 'vehicle',
                        header: 'Vehicle',
                    },
                    {
                        property: 'capacity',
                        header: 'Capacity',
                    },
                    {
                        property: 'price',
                        header: 'Price',
                        render: (datum) => `${datum.price} ${datum.currency}`,
                    },
                    {
                        property: 'origin',
                        header: 'Origin',
                        render: (datum) => datum.origin.name,
                    },
                    {
                        property: 'destination',
                        header: 'Destination',
                        render: (datum) => datum.destination.name,
                    },
                ]}
                data={transfers.data}
                resizeable={true}
                step={transfers.meta.take}
                onClickRow={(event) => {
                    const transfer = transfers.data.find(e => e.id == event.datum.id);
                    props.onTransferSelect(transfer, searchPayload);
                }}
            />
        </Box>
        <Box direction='row' align="center" margin="20px" justify="end">
            <Pagination numberItems={transfers.meta.itemCount} page={page} onChange={handleChange} />
        </Box>
    </Box>);
}


function PassengerCountDropdown(props) {
    const {
        adultCount,
        adultOnIncrease,
        adultOnDecrease,
        childCount,
        childOnIncrease,
        childOnDecrease,
        infantCount,
        infantOnIncrease,
        infantOnDecrease,
        babySeatCount,
        babySeatOnIncrease,
        babySeatOnDecrease,
    } = props;
    const [open, setOpen] = useState(false);
    const onOpen = () => setOpen(true);
    const onClose = () => setOpen(undefined);

    return (
        <FormField label="Passengers">
            <DropButton
                pad={{ right: "10px", left: "20px", vertical: "6px" }}
                label={
                    <Box direction='row' justify="between">
                        <Text>{adultCount + childCount + babySeatCount}</Text>
                        <FormDown />
                    </Box>
                }
                open={open}
                onOpen={onOpen}
                onClose={onClose}
                style={{ border: "none" }}
                dropContent={
                    <Box pad="small">
                        Adult Count
                        <Box direction="row" gap="small">
                            <Button label="-" onClick={adultOnDecrease} />
                            <TextInput textAlign='center' type="number" value={adultCount} />
                            <Button label="+" onClick={adultOnIncrease} />
                        </Box>
                        Child Count
                        <Box direction="row" gap="small">
                            <Button label="-" onClick={childOnDecrease} />
                            <TextInput textAlign='center' type="number" value={childCount} />
                            <Button label="+" onClick={childOnIncrease} />
                        </Box>
                        Infant Count
                        <Box direction="row" gap="small">
                            <Button label="-" onClick={infantOnDecrease} />
                            <TextInput textAlign='center' type="number" value={infantCount} />
                            <Button label="+" onClick={infantOnIncrease} />
                        </Box>
                        Baby Seat Count
                        <Box direction="row" gap="small">
                            <Button label="-" onClick={babySeatOnDecrease} />
                            <TextInput textAlign='center' type="number" value={babySeatCount} />
                            <Button label="+" onClick={babySeatOnIncrease} />
                        </Box>
                    </Box>
                }
                dropAlign={{ top: "bottom" }}
            />
        </FormField>
    )
}
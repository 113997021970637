import { Box, Text } from "grommet";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "src/components/utils/CustomButton";
import SelectLayerDataTable from "src/components/utils/SelectLayerDataTable";
import { getCancellationPoliciesAction } from "src/store/transfer/transfer.action";
import { getPolicies, getTransferDetail, setTransferUpdateDto, setTransferUpdatePolicy } from "src/store/transfer/transfer.slice";

export default function CancelPolicyTab() {
    const dispatch = useDispatch();
    const { transfer, isEditMode, transferUpdate } = useSelector((state) => getTransferDetail(state));
    const policies = useSelector((state) => getPolicies(state));
    const [page, setPage] = useState<number>();

    useEffect(() => {
        if (isEditMode) {
            setPage(1);
        }
    }, [isEditMode]);

    useEffect(() => {
        if (isEditMode)
            getPoliciesRequest();
    }, [page]);

    const getPoliciesRequest = () => {
        dispatch(getCancellationPoliciesAction(page, policies.meta.take));
    };

    const handleChange = ({ page }) => {
        setPage(page);
    };

    const [open, setOpen] = useState(false);
    const onOpen = () => setOpen(true);
    const onClose = () => setOpen(undefined);

    return (
        <Box pad="medium"> {
            isEditMode &&
            <Box>
                <CustomButton label={transferUpdate.selectedPolicy ? "Change" : "Select"} onClick={onOpen} color="dark-3" />
                <SelectLayerDataTable
                    isOpen={open}
                    title="Select Policy"
                    columns={[
                        {
                            property: 'id',
                            header: "Id",
                            primary: true,
                        },
                        {
                            property: 'name',
                            header: "Name"
                        },
                        {
                            property: 'type',
                            header: "Type"
                        },
                        {
                            property: 'description',
                            header: 'Description',
                        }
                    ]}
                    listData={policies}
                    onSelect={(data) => {
                        const policy = policies.data.find(e => e.id === data.id);
                        dispatch(setTransferUpdatePolicy(policy));
                        dispatch(setTransferUpdateDto({ ...transferUpdate.updateDto, cancellationPolicyId: policy.id }));
                    }}
                    onClose={onClose}
                    page={page}
                    onPageChanged={handleChange}
                />
            </Box>
        }
            {transferUpdate.selectedPolicy && isEditMode ?
                <Box pad={{ top: "10px" }}>
                    <Text weight="bold" size='large'>{transferUpdate.selectedPolicy.name}</Text>
                    <Text>{transferUpdate.selectedPolicy.description}</Text>
                    <Text>{transferUpdate.selectedPolicy.type}</Text>
                    {Object.entries(transferUpdate.selectedPolicy.hours).map(([k, v]) => <Text key={k}>{`${k} hours before : ${Number(v) * 100}% refund`}</Text>)}
                </Box> :
                <Box pad={{ top: "10px" }}>
                    <Text weight="bold" size='large'>{transfer.cancellationPolicy.name}</Text>
                    <Text>{transfer.cancellationPolicy.description}</Text>
                    <Text>{transfer.cancellationPolicy.type}</Text>
                    {Object.entries(transfer.cancellationPolicy.hours).map(([k, v]) => <Text key={k}>{`${k} hours before : ${Number(v) * 100}% refund`}</Text>)}
                </Box>}
        </Box>
    )
}
import axios from 'axios';
import { nanoid } from 'nanoid';
import { errorResponseMessage } from '../helpers/utils';
import store from '../store/store';
import { getToken } from 'src/helpers/localdb';
import { logout } from 'src/store/auth/auth.slice';
import { removeToastAction, setToastAction } from 'src/store/util/util.action';

export const {
  REACT_APP_API_VERSION,
  REACT_APP_SERVER_HOST,
} = process.env;

export const apiURL = `${REACT_APP_SERVER_HOST}/${REACT_APP_API_VERSION}`;

axios.defaults.baseURL = apiURL;

axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  config => {
    const accessToken = getToken();
    accessToken && (config.headers["Authorization"] = `Bearer ${accessToken}`);
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const showErrorToast = error?.response?.config?.showErrorToast;
    if (error?.response?.data?.message === 'NOT_SIGNED_IN') {
      store.dispatch(logout());
    } else if (showErrorToast ? showErrorToast(error) : true) {
      const toastId = nanoid();
      store.dispatch(setToastAction(
        'error',
        errorResponseMessage(error?.response?.data || error),
      ));

      setTimeout(() => {
        store.dispatch(removeToastAction(toastId));
      }, 5000);
    }

    return Promise.reject(error);
  }
);

export const http = axios;

import React, { FC, useEffect, useState } from 'react';
import { Box, Button, Card, Form, Layer, Text } from 'grommet';
import PrivatePageLayout from 'src/components/layouts/PrivatePageLayout/PrivatePageLayout';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from 'src/components/utils/CustomButton';
import { deleteCompanyAction, getCompanyAction, setCompanyAdminAction, updateCompanyAction } from 'src/store/company/company.action';
import { useParams } from 'react-router-dom';
import { getCompanyDetails, getCompanyDetailsLoading } from 'src/store/company/company.slice';
import Loading from 'src/components/utils/Loading';
import NotFound from '../NotFound';
import FormTextInput from 'src/components/utils/FormTextInput';
import { validators } from 'src/helpers/validators';
import FormTextArea from 'src/components/utils/FormTextArea';
import { CustomPhoneInput } from 'src/components/utils/CustomPhoneInput/CustomPhoneInput';
import { TransferCompany } from 'src/store/company/company.types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faRemove } from '@fortawesome/free-solid-svg-icons';

const CompanyDetails: FC<any> = (props) => {
  const { id } = useParams<any>()
  const dispatch = useDispatch();

  const company: TransferCompany = useSelector((state) => getCompanyDetails(state));
  const loading = useSelector((state) => getCompanyDetailsLoading(state));
  const [isEditMode, setEditMode] = useState(false);

  const [companyUpdateDto, setCompanyUpdateDto] = useState(undefined);

  useEffect(() => {
    if (!company || (company.id != id)) {
      dispatch(getCompanyAction(id));
    }
  }, []);


  useEffect(() => {
    if (company) {
      setCompanyUpdateDto(company);
      setPhoneNumber(company.contactPhone);
      setPhoneCountryCode(company.contactCountryPhoneCode);
      const numbers = company.phoneNumbers.map(e => {
        const data = {
          phone: e.phone,
          isValid: true,
          countryCode: e.countryPhoneCode,
        }
        return data;
      });
      setPhoneNumbers(numbers);
      console.log(numbers);
    }
  }, [company]);

  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [phoneCountryCode, setPhoneCountryCode] = useState<string>();
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>(true);

  const newPhoneField = {
    phone: "",
    isValid: false,
    countryCode: "90",
  };

  const [phoneNumbers, setPhoneNumbers] = useState<any>([]);
  const [showAdminDialog, setShowAdminDialog] = useState<boolean>(false);
  const [email, setEmail] = useState();

  const handleCompanyPhoneNumbersChange = (countryCode: string, phone: string, valid: boolean, index: number) => {
    const numbers = [...phoneNumbers];
    numbers[index].phone = phone;
    numbers[index].countryCode = countryCode;
    numbers[index].isValid = valid;
    setPhoneNumbers(numbers);
  };

  const handlePhoneNumberChange = (countryCode: string, phone: string, valid: boolean) => {
    setPhoneNumber(phone);
    setPhoneCountryCode(countryCode);
    setIsPhoneValid(valid);

    if (valid) {
      setCompanyUpdateDto({
        ...companyUpdateDto,
        contactPhone: phone,
        contactCountryPhoneCode: countryCode,
      });
    }
  };

  function onSubmit(event) {
    if (!isEditMode) return setEditMode(true);
    if (!isPhoneValid || phoneNumbers.some(e => !e.isValid)) return;
    const body = {
      ...companyUpdateDto,
      phoneNumbers: phoneNumbers.map(e => {
        return {
          phone: e.phone,
          countryPhoneCode: e.countryCode,
        };
      })
    };
    dispatch(updateCompanyAction(id, body));
    setEditMode(false);
  }

  function onAdminEmailSubmit(event) {
    setShowAdminDialog(false);
    dispatch(setCompanyAdminAction(company.id, email));
  }

  return (
    <PrivatePageLayout title='Company Details'>
      {loading ?
        <Loading /> :
        !company ?
          <NotFound /> :
          <Form
            onSubmit={onSubmit}
            validate="change"
          >
            <Box direction='row' justify='between' align='center'>
              <Text textAlign="center" size='large' weight={900} color="dark">Company Details</Text>
              <Box direction='row'>
                {!company.adminId &&
                  <>
                    <CustomButton
                      primary
                      minWidth="140px"
                      type="button"
                      label="Set Admin"
                      onClick={(event) => {
                        setShowAdminDialog(true);
                      }}
                    />
                    {showAdminDialog &&
                      <Layer
                        position="center"
                        onClickOutside={() => {
                          setShowAdminDialog(false);
                        }}
                        onEsc={() => {
                          setShowAdminDialog(false);
                        }}
                      >
                        <Form
                          onSubmit={onAdminEmailSubmit}
                        >
                          <Box margin="medium" justify='center' width="medium">
                            <FormTextInput
                              id="nameInput"
                              name="email"
                              label="User Email"
                              validate={[
                                validators.required("Email"),
                                validators.email("Email format not valid"),
                              ]}
                              onChange={(event) => {
                                setEmail(event.target.value);
                              }}
                            />
                            <Button
                              primary
                              type="submit"
                              label="Set Admin"
                            />
                          </Box>
                        </Form>
                      </Layer>
                    }
                  </>
                }
                <CustomButton
                  primary
                  type="submit"
                  label={isEditMode ? "Save" : "Edit"}
                />
                {isEditMode ?
                  <CustomButton
                    primary
                    type="submit"
                    label="Cancel"
                    onClick={() => {
                      setEditMode(false);
                    }}
                  /> :
                  <CustomButton
                    primary
                    backgroundColor="red"
                    type="button"
                    label="Delete"
                    onClick={() => dispatch(deleteCompanyAction(company.id))}
                  />}
              </Box>
            </Box>
            {isEditMode ?
              <Box pad="medium" width="100%">
                <FormTextInput
                  id="companyNameInput"
                  label="Company Name"
                  name="companyName"
                  validate={[validators.required("Company Name")]}
                  value={companyUpdateDto.companyName}
                  onChange={(event) => setCompanyUpdateDto({ ...companyUpdateDto, companyName: event.target.value })}
                />
                <FormTextInput
                  id="taxNumberInput"
                  label="Tax Number"
                  name="taxNumber"
                  validate={[validators.required("Tax Number")]}
                  value={companyUpdateDto.taxNumber}
                  onChange={(event) => setCompanyUpdateDto({ ...companyUpdateDto, taxNumber: event.target.value })}
                />
                <FormTextArea
                  id="addressInput"
                  label="Address"
                  name="address"
                  type="number"
                  validate={[
                    validators.required("Address"),
                  ]}
                  value={companyUpdateDto.address}
                  onChange={(event) => setCompanyUpdateDto({ ...companyUpdateDto, address: event.target.value })}
                />
                <FormTextInput
                  id="contactNameInput"
                  label="Contact Name"
                  name="contactName"
                  validate={[validators.required("Contact Name")]}
                  value={companyUpdateDto.contactName}
                  onChange={(event) => setCompanyUpdateDto({ ...companyUpdateDto, contactName: event.target.value })}
                />
                <FormTextInput
                  id="contactEmailSelect"
                  label="Contact Email"
                  name="contactEmail"
                  validate={[validators.required("Contact Email")]}
                  value={companyUpdateDto.contactEmail}
                  onChange={(event) => setCompanyUpdateDto({ ...companyUpdateDto, contactEmail: event.target.value })}
                />
                <CustomPhoneInput
                  label='Contact Phone'
                  required
                  value={phoneNumber}
                  countryCode={phoneCountryCode}
                  onChange={handlePhoneNumberChange}
                />
                <Box direction='row' margin={{ top: "10px", bottom: "5px", left: "15px" }}>
                  <Text style={{ fontSize: "14px" }}>Company Phone Numbers</Text>
                  <Button
                    margin={{ left: "10px" }}
                    onClick={() => {
                      setPhoneNumbers([...phoneNumbers, { ...newPhoneField }]);
                    }}><FontAwesomeIcon icon={faAdd} /></Button>
                </Box>
                {phoneNumbers.map((e, i) => {
                  return <Box
                    key={i}
                    direction='row'
                    align='center'
                  >
                    <CustomPhoneInput
                      width='95%'
                      required
                      value={e.phone}
                      countryCode={e.countryCode}
                      onChange={(countryCode: string, phone: string, valid: boolean) => {
                        handleCompanyPhoneNumbersChange(countryCode, phone, valid, i);
                      }}
                    />
                    <Box margin={{ bottom: "10px" }} width="5%" align='center' justify='center'>
                      <Button
                        disabled={i === 0}
                        onClick={() => {
                          const numbers = [...phoneNumbers];
                          numbers.splice(i, 1);
                          setPhoneNumbers(numbers);
                        }}>
                        <FontAwesomeIcon icon={faRemove} />
                      </Button>
                    </Box>
                  </Box>
                })}
              </Box> :
              <Box pad="small" direction='column'>
                <Card pad="large" margin="medium" justify='start' align='start'>
                  <Text weight="bold" size='large'>{company.companyName}</Text>
                  <Text>{company.taxNumber}</Text>
                  <Text>{company.address}</Text>
                  <Text margin={{ top: "20px" }} weight="bold">Contact Info</Text>
                  <Text>{company.contactName}</Text>
                  <Text>{company.contactEmail}</Text>
                  <Text>{`+${company.contactCountryPhoneCode + company.contactPhone}`}</Text>
                  <Text margin={{ top: "20px" }} weight="bold">Company Phone Numbers</Text>
                  {company.phoneNumbers.map((e, i) => <Text key={i} >{`+${e.countryPhoneCode + e.phone}`}</Text>)}
                  <Text margin={{ top: "20px" }} weight="bold">Company Admin</Text>
                  {company.adminId ?
                    <>
                      <Text>{`${company.admin.firstName} ${company.admin.lastName}`}</Text>
                      <Text>{company.admin.email}</Text>
                      <Text>{`+${company.admin.countryPhoneCode + company.admin.phone}`}</Text>
                    </>
                    : "-"}
                </Card>
              </Box>
            }
          </Form >
      }
    </PrivatePageLayout >
  )
};

export default CompanyDetails;
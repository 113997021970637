import { http } from '../../config/http';

export const getTransferLocations = async (): Promise<any> => {
  return http
    .get(`/transfers/locations`)
    .then((res) => res.data);
}

export const addTransferLocation = async (payload: any): Promise<any> => {
  return http
    .post(`/transfers/locations`, payload)
    .then((res) => res.data);
}

export const updateTransferLocation = async (id: number, payload: any): Promise<any> => {
  return http
    .put(`/transfers/locations/${id}`, payload)
    .then((res) => res.data);
}

export const getTransferLocationById = async (id: number): Promise<any> => {
  return http
    .get(`/transfers/locations/${id}`)
    .then((res) => res.data);
}

export const deleteTransferLocation = async (id: number): Promise<any> => {
  return http
    .delete(`/transfers/locations/${id}`)
    .then((res) => res.data);
}
import React, { FC, useEffect, useState } from 'react';
import {
  Box, ColumnConfig, DataTable, Pagination, Text
} from 'grommet';
import PrivatePageLayout from 'src/components/layouts/PrivatePageLayout/PrivatePageLayout';
import CustomButton from 'src/components/utils/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import Loading from 'src/components/utils/Loading';
import { getPolicies, getPoliciesLoading, setPolicyDetail } from 'src/store/policy/policy.slice';
import { PolicyRowType } from 'src/store/policy/policy.types';
import { searchPoliciesAction } from 'src/store/policy/policy.action';
import appHistory from 'src/helpers/history';

const columns: ColumnConfig<PolicyRowType>[] = [
  {
    property: 'id',
    header: "Id",
    primary: true,
  },
  {
    property: 'name',
    header: "Name"
  },
  {
    property: 'type',
    header: "Type"
  },
  {
    property: 'description',
    header: 'Description',
  }
];

const Policies: FC = (props) => {
  const dispatch = useDispatch();

  const policies = useSelector((state: RootState) => getPolicies(state));
  const loading = useSelector((state: RootState) => getPoliciesLoading(state));
  const [page, setPage] = useState(1);
  const [indices, setIndices] = useState([0, policies.data.length]);

  useEffect(() => {
    dispatch(searchPoliciesAction(page));
  }, [page]);

  useEffect(() => {
    setIndices([0, policies.data.length]);
  }, [policies]);

  const handleChange = ({ page, startIndex, endIndex }) => {
    setPage(page);
    setIndices([startIndex, Math.min(endIndex, policies.meta.itemCount)]);
  };

  return (
    <PrivatePageLayout title='Policies'>
      <Box direction='row' justify='between' align='center'>
        <Text textAlign="center" size='large' weight={900} color="dark">Policies</Text>
        <Box>
          <CustomButton
            primary
            type="button"
            label="Add Policies"
            onClick={() => appHistory.push('/policies/add')}
          />
        </Box>
      </Box>
      <Box margin={{ top: "medium" }}>
        {loading ?
          <Loading /> :
          <>
            <DataTable
              columns={columns}
              data={policies.data}
              sortable={false}
              resizeable={true}
              step={policies.meta.take}
              onClickRow={(event) => {
                const policy = policies.data.find(e => e.id == event.datum.id);
                dispatch(setPolicyDetail(policy));
                appHistory.push(`/policies/${event.datum.id}`);
              }}
            />
            <Box direction='row' align="center" margin={{ top: "20px" }} justify="between" height="20px" width="100%">
              <Text>
                Showing {indices[0] + 1} - {indices[1]} of {policies.meta.itemCount}
              </Text>
              <Pagination numberItems={policies.meta.itemCount} page={page} onChange={handleChange} />
            </Box>
          </>
        }
      </Box>
    </PrivatePageLayout>
  )
};

export default Policies;
import * as PolicyService from "src/store/policy/policy.service";
import {
  setPolicies,
  setPoliciesLoading,
  setPolicyDetail,
  setPolicyDetailLoading,
} from "./policy.slice";
import { CancellationPolicy } from "./policy.types";
import appHistory from "src/helpers/history";

export const searchPoliciesAction = (page?: number, take?: number) => {
  return async (dispatch) => {
    try {
      dispatch(setPoliciesLoading(true));
      const payload = await PolicyService.getCancellationPolicies(page, take);
      dispatch(setPolicies(payload));
      dispatch(setPoliciesLoading(false));
    } catch (err: any) {
      dispatch(setPoliciesLoading(false));
    }
  };
};

export const getPolicyAction = (id: number) => {
  return async (dispatch) => {
    try {
      dispatch(setPolicyDetailLoading(true));
      const payload = await PolicyService.getCancellationPolicy(id);
      dispatch(setPolicyDetail(payload));
      dispatch(setPolicyDetailLoading(false));
    } catch (err: any) {
      dispatch(setPolicyDetailLoading(false));
    }
  };
};

export const addPolicyAction = (body: CancellationPolicy) => {
  return async (dispatch) => {
    try {
      dispatch(setPolicyDetailLoading(true));
      const payload = await PolicyService.addCancellationPolicy(body);
      dispatch(setPolicyDetail(payload));
      dispatch(setPolicyDetailLoading(false));
      appHistory.push(`/policies/${payload.id}`);
    } catch (err: any) {
      dispatch(setPolicyDetailLoading(false));
    }
  };
};

export const deletePolicyAction = (id: number) => {
  return async (dispatch) => {
    try {
      dispatch(setPolicyDetailLoading(true));
      await PolicyService.deleteCancellationPolicy(id);
      dispatch(setPolicyDetail(undefined));
      dispatch(setPolicyDetailLoading(false));
      appHistory.push("/policies")
    } catch (err: any) {
      dispatch(setPolicyDetailLoading(false));
    }
  };
};

export const updatePolicyAction = (id: number, body: CancellationPolicy) => {
  return async (dispatch) => {
    try {
      dispatch(setPolicyDetailLoading(true));
      const payload = await PolicyService.updateCancellationPolicy(id, body);
      dispatch(setPolicyDetail(payload));
      dispatch(setPolicyDetailLoading(false));
    } catch (err: any) {
      dispatch(setPolicyDetailLoading(false));
    }
  };
};
import React, { FC, useEffect, useState } from 'react';
import {
  Box, Button, ColumnConfig, DataTable, Layer, Pagination, Spinner, Text
} from 'grommet';
import PrivatePageLayout from 'src/components/layouts/PrivatePageLayout/PrivatePageLayout';
import CustomButton from 'src/components/utils/CustomButton';
import { adminSearchTransfersAction } from 'src/store/transfer/transfer.action';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { getTransfers, getTransfersLoading, setTransferDetail } from 'src/store/transfer/transfer.slice';
import { TransferRowType } from 'src/store/transfer/transfer.types';
import { useHistory } from 'react-router-dom';
import Loading from 'src/components/utils/Loading';

const columns: ColumnConfig<TransferRowType>[] = [
  {
    property: 'id',
    header: "Id",
    primary: true,
  },
  {
    property: 'name',
    header: "Name"
  },
  {
    property: 'status',
    header: "Status"
  },
  {
    property: 'brand',
    header: 'Brand',
  },
  {
    property: 'model',
    header: 'Model',
  },
  {
    property: 'type',
    header: 'Type',
  },
  {
    property: 'vehicle',
    header: 'Vehicle',
  },
  {
    property: 'capacity',
    header: 'Capacity',
  },
  {
    property: 'price',
    header: 'Price',
    render: (datum) => `${datum.price} ${datum.currency}`,
  },
  {
    property: 'origin',
    header: 'Origin',
    render: (datum) => datum.origin.name,
  },
  {
    property: 'destination',
    header: 'Destination',
    render: (datum) => datum.destination.name,
  },
  {
    property: 'duration',
    header: 'Duration',
  },
];

const Transfers: FC = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const transfers = useSelector((state: RootState) => getTransfers(state));
  const loading = useSelector((state: RootState) => getTransfersLoading(state));
  const [page, setPage] = useState(1);
  const [indices, setIndices] = useState([0, 10]);

  useEffect(() => {
    dispatch(adminSearchTransfersAction({ page }));
  }, [page]);

  useEffect(() => {
    setIndices([0, transfers.data.length]);
  }, [transfers]);

  const handleChange = ({ page, startIndex, endIndex }) => {
    setPage(page);
    setIndices([startIndex, Math.min(endIndex, transfers.meta.itemCount)]);
  };

  return (
    <PrivatePageLayout title='Transfers'>
      <Box direction='row' justify='between' align='center'>
        <Text textAlign="center" size='large' weight={900} color="dark">Transfers</Text>
        <Box>
          <CustomButton
            primary
            type="button"
            label="Add Transfer"
            onClick={() => history.push('/transfers/add')}
          />
        </Box>
      </Box>
      <Box margin={{ top: "medium" }}>
        {loading ?
          <Loading /> :
          <>
            <DataTable
              columns={columns}
              data={transfers.data}
              sortable={false}
              resizeable={true}
              step={transfers.meta.take}
              onClickRow={(event) => {
                const transfer = transfers.data.find(e => e.id == event.datum.id);
                dispatch(setTransferDetail(transfer));
                history.push(`/transfers/${event.datum.id}`);
              }}
            />
            <Box direction='row' align="center" margin={{ top: "20px" }} justify="between" height="20px" width="100%">
              <Text>
                Showing {indices[0] + 1} - {indices[1]} of {transfers.meta.itemCount}
              </Text>
              <Pagination numberItems={transfers.meta.itemCount} page={page} onChange={handleChange} />
            </Box>
          </>
        }
      </Box>
    </PrivatePageLayout>
  )
};

export default Transfers;
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { UserState } from "./user.types";

const UserSliceInitialState: UserState = {
    users: {
        data: [],
        meta: {
            hasNextPage: false,
            hasPreviousPage: false,
            itemCount: 0,
            page: 1,
            pageCount: 0,
            take: 10,
        },
    },
    loading: false,
};

export const userSlice: any = createSlice({
    name: "user",
    initialState: UserSliceInitialState,
    reducers: {
        setUsersLoading: (state, action: PayloadAction<any>) => {
            state.loading = action.payload;
        },
        setUsers: (state, action: PayloadAction<any>) => {
            state.users = action.payload;
        },
    },
});

export const {
    setUsersLoading,
    setUsers,
} = userSlice.actions;

export const getUsers = (state: RootState) => state.user.users;
export const getUsersLoading = (state: RootState) => state.user.loading;

export default userSlice.reducer;

import { http } from '../../config/http';
import { stringify as stringifyQuery } from 'querystring';

export const getTransferCompanies = async (page = 1, take = 10, keyword?: string): Promise<any> => {
  const stringifiedQuery = stringifyQuery({ page: page, take: take, keyword: keyword });
  return http
    .get(`/transfers/companies?${stringifiedQuery}`)
    .then((res) => res.data);
}

export const getTransferCompany = async (id: number): Promise<any> => {
  return http
    .get(`/transfers/companies/${id}`)
    .then((res) => res.data);
}

export const addTransferCompany = async (payload: any): Promise<any> => {
  return http
    .post(`/transfers/companies`, payload)
    .then((res) => res.data);
}

export const deleteTransferCompany = async (id: number): Promise<any> => {
  return http
    .delete(`/transfers/companies/${id}`)
    .then((res) => res.data);
}

export const updateTransferCompany = async (id: number, payload: any): Promise<any> => {
  return http
    .put(`/transfers/companies/${id}`, payload)
    .then((res) => res.data);
}

export const setTransferCompanyAdmin = async (id: number, email: string): Promise<any> => {
  return http
    .put(`/transfers/companies/admin`, { companyId: id, email })
    .then((res) => res.data);
}
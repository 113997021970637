import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { AuthState } from "./auth.types";
import { User } from "../user/user.types";

const AuthInitialState: AuthState = {
    user: null,
    isAuthenticated: false,
    login: {
        loading: false,
        error: null,
    },
    register: {
        loading: false,
        error: null,
    },
    resendMailVerificationToken: {
        loading: false,
    },
    forgotPassword: {
        loading: false,
        error: null,
    },
    resetPassword: {
        loading: false,
        error: null,
    },
};

export const authSlice: any = createSlice({
    name: "auth",
    initialState: AuthInitialState,
    reducers: {
        setLoginSuccess: (state, action: PayloadAction<User>) => {
            state.isAuthenticated = true;
            state.user = action.payload;
            state.login.loading = false;
            state.login.error = null;
        },
        setLoginFailed: (state, action: PayloadAction<any>) => {
            state.isAuthenticated = false;
            state.user = null;
            state.login.error = action.payload;
            state.login.loading = false;
        },
        setLoginLoading: (state, action: PayloadAction<boolean>) => {
            state.login.loading = action.payload;
        },
        setRegisterLoading: (state, action: PayloadAction<boolean>) => {
            state.register.loading = action.payload;
        },
        setRegisterSuccess: (state, action: PayloadAction<User>) => {
            state.isAuthenticated = false;
            state.user = action.payload;
            state.register.loading = false;
            state.register.error = null;
        },
        setRegisterFailed: (state, action: PayloadAction<any>) => {
            state.isAuthenticated = false;
            state.user = null;
            state.register.error = action.payload;
            state.register.loading = false;
        },
        logout: (state) => {
            state.isAuthenticated = false;
            state.user = null;
        },
        setResendMailVerificationLoading: (state, action: PayloadAction<any>) => {
            state.resendMailVerificationToken.loading = !state.resendMailVerificationToken.loading;
        },
        setForgotPasswordLoading: (state, action: PayloadAction<boolean>) => {
            state.forgotPassword.loading = action.payload;
        },
        setForgotPasswordFailed: (state, action: PayloadAction<any>) => {
            state.forgotPassword.loading = false;
            state.forgotPassword.error = action.payload;
        },
        setResetPasswordLoading: (state, action: PayloadAction<boolean>) => {
            state.resetPassword.loading = action.payload;
        },
        setResetPasswordFailed: (state, action: PayloadAction<any>) => {
            state.resetPassword.loading = false;
            state.resetPassword.error = action.payload;
        },
        setUser: (state, action: PayloadAction<User>) => {
            state.user = action.payload;
        },
    },
});

export const {
    setLoginSuccess,
    setLoginFailed,
    setLoginLoading,
    setRegisterLoading,
    setRegisterSuccess,
    setRegisterFailed,
    logout,
    setResendMailVerificationLoading,
    setForgotPasswordLoading,
    setForgotPasswordFailed,
    setResetPasswordLoading,
    setResetPasswordFailed,
    setUser,
} = authSlice.actions;

export const getUser = (state: RootState) => state.auth.user;

export default authSlice.reducer;

import React, { FC } from 'react';
import {
  Box,
} from 'grommet';
import CustomSideBar from './SideBar';
import CustomHeader from './Header';

const Desktop: FC = (props) => {
  return (
    <Box elevation="xlarge" width="100%" height='100vh' style={{ height: "100vh" }}>
      <CustomHeader />
      <Box direction="row" width="100%">
        <CustomSideBar />
        <Box pad={{ vertical: 'medium', horizontal: "medium" }} width="100%" style={{ overflow: "auto" }}>
          {props.children}
        </Box>
      </Box>
    </Box >
  )
};

export default Desktop;
import { Button, Image, Box } from "grommet";
import { Close, Return } from "grommet-icons";
import ExtendedBox from "src/components/utils/ExtendedBox";
import { UpdateImageAction } from "src/store/transfer/transfer.types";

export default function UpdateGridImage(props) {
    const { image, fit, onRemove } = props;
    return (
        <Box
            width="210px"
            height="125px"
            style={{ position: 'relative' }}
        >
            <ExtendedBox
                justify="center"
                align="center"
                height="125px"
                width="210px"
                key={image.id}
                border="all"
                round="8px"
            >
                <Image
                    width="100%"
                    height="100%"
                    style={{ borderRadius: "8px" }}
                    fit={fit ?? "contain"}
                    src={image.url || image.base64}
                />
            </ExtendedBox>
            {image.action === UpdateImageAction.REMOVE &&
                <ExtendedBox
                    position="absolute"
                    top="0px"
                    right="0px"
                    left="0px"
                    bottom="0px"
                    border="all"
                    round="8px"
                    background="white"
                    opacity="0.5"
                >
                </ExtendedBox>
            }
            <ExtendedBox
                position="absolute"
                top="6px"
                right='6px'
            >
                <Button
                    margin="0px"
                    pad="0px"
                    onClick={onRemove}
                >
                    {image.action === UpdateImageAction.REMOVE ? <Return /> : <Close />}
                </Button>
            </ExtendedBox>
        </Box>
    )
}

import { Box, Form, Text } from "grommet";
import { useDispatch, useSelector } from "react-redux";
import FormSelect from "src/components/utils/FormSelect";
import FormTextInput from "src/components/utils/FormTextInput";
import { validators } from "src/helpers/validators";
import { addVehicleDetailsTabUpdateField, getTransferDetail, removeVehicleDetailsTabUpdateField, setTransferUpdateDto } from "src/store/transfer/transfer.slice";
import { VehicleType } from "src/store/transfer/transfer.types";

export default function VehicleDetailsTab() {
    const dispatch = useDispatch();
    const {
        transfer,
        isEditMode,
        transferUpdate,
    } = useSelector((state) => getTransferDetail(state));

    function handleUpdateCount(condition: boolean, field: string) {
        if (condition) dispatch(addVehicleDetailsTabUpdateField(field));
        else dispatch(removeVehicleDetailsTabUpdateField(field));
    }

    function brandOnChange(event) {
        dispatch(setTransferUpdateDto({ ...transferUpdate.updateDto, brand: event.target.value }));
        handleUpdateCount(event.target.value != transfer.brand, "brand");
    }

    function modelOnChange(event) {
        dispatch(setTransferUpdateDto({ ...transferUpdate.updateDto, model: event.target.value }));
        handleUpdateCount(event.target.value != transfer.model, "model");
    }

    function capacityOnChange(event) {
        dispatch(setTransferUpdateDto({ ...transferUpdate.updateDto, capacity: event.target.value }));
        handleUpdateCount(event.target.value != transfer.capacity, "capacity");
    }

    function vehicleOnChange({ option }) {
        dispatch(setTransferUpdateDto({ ...transferUpdate.updateDto, vehicle: option }));
        handleUpdateCount(option != transfer.vehicle, "vehicle");
    }

    function luggageCarryOnOnChange(event) {
        dispatch(setTransferUpdateDto({
            ...transferUpdate.updateDto, luggageAllowance: {
                ...transferUpdate.updateDto.luggageAllowance,
                carryOn: event.target.value,
            }
        }));
        handleUpdateCount(event.target.value != transfer.luggageAllowance.carryOn, "luggageAllowanceCarryOn");
    }

    function luggageCheckInOnChange(event) {
        dispatch(setTransferUpdateDto({
            ...transferUpdate.updateDto, luggageAllowance: {
                ...transferUpdate.updateDto.luggageAllowance,
                checkIn: event.target.value,
            }
        }));
        handleUpdateCount(event.target.value != transfer.luggageAllowance.checkIn, "luggageAllowanceCheckIn");
    }

    return (
        <Box pad="medium">
            {
                isEditMode ?
                    <>
                        <FormTextInput
                            id="brandInput"
                            label="Brand"
                            name="brand"
                            value={transferUpdate.updateDto.brand}
                            onChange={brandOnChange}
                        />
                        <FormTextInput
                            id="modelInput"
                            label="Model"
                            name="model"
                            value={transferUpdate.updateDto.model}
                            onChange={modelOnChange}
                        />
                        <FormSelect
                            id="vehicleSelect"
                            label="Vehicle"
                            name="vehicle"
                            options={[VehicleType.CAR, VehicleType.VAN, VehicleType.BUS]}
                            value={transferUpdate.updateDto.vehicle}
                            onChange={vehicleOnChange}
                        />
                        <FormTextInput
                            id="capacityInput"
                            label="Capacity"
                            name="capacity"
                            type="number"
                            validate={[
                                validators.required("Capacity"),
                                validators.bigger(0, "Capacity must be bigger than 0"),
                            ]}
                            value={transferUpdate.updateDto.capacity}
                            onChange={capacityOnChange}
                        />
                        <FormTextInput
                            id="luggageCarryOnInput"
                            label="Luggage Carry On"
                            name="luggageCarryOn"
                            type="number"
                            value={transferUpdate.updateDto.luggageAllowance.carryOn}
                            onChange={luggageCarryOnOnChange}
                        />
                        <FormTextInput
                            id="luggageCheckInInput"
                            label="Luggage Check In"
                            name="luggageCheckIn"
                            type="number"
                            value={transferUpdate.updateDto.luggageAllowance.checkIn}
                            onChange={luggageCheckInOnChange}
                        />
                    </> :
                    <>
                        <Text><b>Brand:</b> {transfer.brand}</Text>
                        <Text><b>Model:</b> {transfer.model}</Text>
                        <Text><b>Vehicle:</b> {transfer.vehicle}</Text>
                        <Text><b>Capacity:</b> {transfer.capacity} person</Text>
                        <Text><b>Luggage Carry On:</b> {transfer.luggageAllowance.carryOn} piece.</Text>
                        <Text><b>Luggage Check In:</b> {transfer.luggageAllowance.checkIn} piece.</Text>
                    </>
            }
        </Box>
    )
}
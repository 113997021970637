import * as LocationService from "src/store/location/location.service";
import {
  setLocationDetail,
  setLocationDetailsLoading,
  setLocations,
  setLocationsLoading,
} from "./location.slice";
import appHistory from "src/helpers/history";

export const searchLocationsAction = () => {
  return async (dispatch) => {
    try {
      dispatch(setLocationsLoading(true));
      const payload = await LocationService.getTransferLocations();
      dispatch(setLocations(payload));
      dispatch(setLocationsLoading(false));
    } catch (err: any) {
      dispatch(setLocationsLoading(false));
    }
  };
};

export const getLocationAction = (id: number) => {
  return async (dispatch) => {
    try {
      dispatch(setLocationDetailsLoading(true));
      const payload = await LocationService.getTransferLocationById(id);
      dispatch(setLocationDetail(payload));
      dispatch(setLocationDetailsLoading(false));
    } catch (err: any) {
      dispatch(setLocationDetailsLoading(false));
    }
  };
};

export const deleteLocationAction = (id: number) => {
  return async (dispatch) => {
    try {
      dispatch(setLocationDetailsLoading(true));
      await LocationService.deleteTransferLocation(id);
      appHistory.push("/locations")
      dispatch(setLocationDetailsLoading(false));
    } catch (err: any) {
      dispatch(setLocationDetailsLoading(false));
    }
  };
};

export const addLocationAction = (payload: any) => {
  return async (dispatch) => {
    try {
      dispatch(setLocationDetailsLoading(true));
      const result = await LocationService.addTransferLocation(payload);
      appHistory.push(`/locations/${result.id}`)
      dispatch(setLocationDetailsLoading(false));
    } catch (err: any) {
      dispatch(setLocationDetailsLoading(false));
    }
  };
};

export const updateLocationAction = (id: number, payload: any) => {
  return async (dispatch) => {
    try {
      dispatch(setLocationDetailsLoading(true));
      const result = await LocationService.updateTransferLocation(id, payload);
      dispatch(setLocationDetail(result));
      dispatch(setLocationDetailsLoading(false));
    } catch (err: any) {
      dispatch(setLocationDetailsLoading(false));
    }
  };
};
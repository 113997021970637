import { PageMetaDto } from "src/models/page-meta-data";

export interface PolicyState {
  policies: {
    data: Array<CancellationPolicy>,
    meta: PageMetaDto,
  },
  loading: boolean,
  details: {
    policy: CancellationPolicy,
    loading: boolean,
  }
}

export interface PolicyRowType {
  id: number;
  name: string;
  type: string;
  description: string;
}

export interface CancellationPolicy {
  id?: number;
  name: string;
  description: string;
  type: CancellationType;
  hours: Record<string, number>;
  createdAt?: Date;
  deletedAt?: Date;
}

export enum CancellationType {
  NON_REFUNDABLE = "NON_REFUNDABLE",
  FULL_REFUNDABLE = "FULL_REFUNDABLE",
  PARTIAL_REFUNDABLE = "PARTIAL_REFUNDABLE"
}
import React, { FC, useState } from 'react';
import {
  Box, ColumnConfig, DataTable, Heading, Meter, Text
} from 'grommet';
import PrivatePageLayout from 'src/components/layouts/PrivatePageLayout/PrivatePageLayout';

const Home: FC = (props) => {

  return (
    <PrivatePageLayout title='Home'>
      Home
    </PrivatePageLayout>
  )
};

export default Home;
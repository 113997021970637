import React, { useEffect, useState } from 'react';
import { Box, Text, TextInput, Button } from 'grommet';
import { FormUp, FormDown } from 'grommet-icons';

export default function TimePicker(props) {
    const { onTimeChange } = props;
    const now = new Date();
    const [hours, setHours] = useState(now.getHours().toString().padStart(2, '0'));
    const [minutes, setMinutes] = useState(now.getMinutes().toString().padStart(2, '0'));

    useEffect(() => {
        onTimeChange(`${hours}:${minutes}`);
    }, [hours, minutes]);

    const handleIncrement = (type) => {
        if (type === 'hours') {
            const value = Number(hours) + 1;
            if (value >= 0 && value < 24) {
                setHours((parseInt(hours, 10) + 1).toString().padStart(2, '0'));
            }
        } else {
            const value = Number(minutes) + 1;
            if (value >= 0 && value < 60) {
                setMinutes((parseInt(minutes, 10) + 1).toString().padStart(2, '0'));
            }
        }
    };

    const handleDecrement = (type) => {
        if (type === 'hours') {
            const value = Number(hours) - 1;
            if (value >= 0 && value < 24) {
                setHours((parseInt(hours, 10) - 1).toString().padStart(2, '0'));
            }
        } else {
            const value = Number(minutes) - 1;
            if (value >= 0 && value < 60) {
                setMinutes((parseInt(minutes, 10) - 1).toString().padStart(2, '0'));
            }
        }
    };

    return (
        <Box direction="row" gap="small" align="center">
            <Box align='center' justify='center'>
                <Button
                    icon={<FormUp />}
                    pad="0px"
                    onClick={() => handleIncrement('hours')}
                />
                <Box align='center'>
                    <TextInput
                        value={hours}
                        onChange={(event) => setHours(event.target.value)}
                        width="40px"
                    />
                </Box>
                <Button
                    pad="0px"
                    icon={<FormDown />}
                    onClick={() => handleDecrement('hours')}
                />
            </Box>
            <Text>:</Text>
            <Box align='center' justify='center'>
                <Button
                    pad="0px"
                    icon={<FormUp />}
                    onClick={() => handleIncrement('minutes')}
                />
                <TextInput
                    value={minutes}
                    onChange={(event) => {
                        const value = Number(event.target.value);
                        if (value >= 0 && value < 60) {
                            setMinutes(event.target.value);
                        }
                    }}
                    style={{ width: '40px' }}
                />
                <Button
                    pad="0px"
                    icon={<FormDown />}
                    onClick={() => handleDecrement('minutes')}
                />
            </Box>
        </Box>
    );
}

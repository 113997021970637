import { createSlice } from "@reduxjs/toolkit";
import { UtilState } from "./util.types";

const initialState: UtilState = {
    toast: {
        visible: false,
    },
};

export const utilSlice: any = createSlice({
    name: "util",
    initialState: initialState,
    reducers: {
        setToast: (state, action) => {
            state.toast = {
                visible: true,
                toastId: action.payload.toastId,
                status: action.payload.status,
                message: action.payload.message,
            };
        },
        removeToast: (state) => {
            state.toast = {
                visible: false,
            };
        },
    },
});

export const {
    setToast,
    removeToast,
} = utilSlice.actions;

export default utilSlice.reducer;
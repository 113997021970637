import React, { FC, useEffect, useState } from 'react';
import { Box, Button, Card, Form, Text } from 'grommet';
import PrivatePageLayout from 'src/components/layouts/PrivatePageLayout/PrivatePageLayout';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from 'src/components/utils/CustomButton';
import { useParams } from 'react-router-dom';
import Loading from 'src/components/utils/Loading';
import NotFound from '../NotFound';
import { getPolicyDetails } from 'src/store/policy/policy.slice';
import { deletePolicyAction, getPolicyAction, updatePolicyAction } from 'src/store/policy/policy.action';
import FormTextInput from 'src/components/utils/FormTextInput';
import FormSelect from 'src/components/utils/FormSelect';
import { CancellationPolicy, CancellationType } from 'src/store/policy/policy.types';
import { validators } from 'src/helpers/validators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faSubtract } from '@fortawesome/free-solid-svg-icons';

const PolicyDetails: FC<any> = () => {
  const { id } = useParams<any>()
  const dispatch = useDispatch();

  const { policy, loading } = useSelector((state) => getPolicyDetails(state));
  const [isEditMode, setEditMode] = useState(false);

  const [updatePayload, setPayload] = useState<CancellationPolicy>(undefined);
  const [hours, setHours] = useState([]);

  useEffect(() => {
    if (policy) {
      setPayload({
        name: policy.name,
        description: policy.description,
        type: policy.type,
        hours: policy.hours,
      });
      setHours(Object.entries(policy.hours).map(([k, v]) => {
        return {
          hour: k,
          refundAmount: Number(v) * 100,
        };
      }));
    }
  }, [policy]);

  useEffect(() => {
    if (!policy || (policy.id != id)) {
      dispatch(getPolicyAction(id));
    }
  }, []);

  function onSubmit(event) {
    if (!isEditMode) return setEditMode(true);
    if (updatePayload.type === CancellationType.FULL_REFUNDABLE) updatePayload.hours = { "0": 1 }
    else if (updatePayload.type === CancellationType.NON_REFUNDABLE) updatePayload.hours = { "0": 0 }
    else {
      const dataHour = {};
      for (const e of hours) {
        dataHour[e.hour] = e.refundAmount / 100;
      }
      updatePayload.hours = dataHour;
    }
    dispatch(updatePolicyAction(id, updatePayload));
    setEditMode(false);
  }

  return (
    <PrivatePageLayout title='Policy Details'>
      {loading ?
        <Loading /> :
        !policy ?
          <NotFound /> :
          <Form
            onSubmit={onSubmit}
            validate="change"
          >
            <Box direction='row' justify='between' align='center'>
              <Text textAlign="center" size='large' weight={900} color="dark">Policy Details</Text>
              <Box direction='row'>
                <CustomButton
                  primary
                  type="submit"
                  label={isEditMode ? "Save" : "Edit"}
                />
                {isEditMode ?
                  <CustomButton
                    primary
                    type="submit"
                    label="Cancel"
                    onClick={() => {
                      setEditMode(false);
                    }}
                  /> :
                  <CustomButton
                    primary
                    backgroundColor="red"
                    type="button"
                    label="Delete"
                    onClick={() => dispatch(deletePolicyAction(policy.id))}
                  />}
              </Box>
            </Box>
            {isEditMode ?
              <Box pad="medium" width="100%">
                <FormTextInput
                  id="policyNameInput"
                  label="Policy Name"
                  name="name"
                  validate={[validators.required("Policy Name")]}
                  value={updatePayload.name}
                  onChange={(event) => setPayload({ ...updatePayload, name: event.target.value })}
                />
                <FormTextInput
                  id="descriptionInput"
                  label="Description"
                  name="description"
                  validate={[validators.required("Description")]}
                  value={updatePayload.description}
                  onChange={(event) => setPayload({ ...updatePayload, description: event.target.value })}
                />
                <FormSelect
                  id="typeSelect"
                  name="type"
                  label="Type"
                  options={[CancellationType.FULL_REFUNDABLE, CancellationType.PARTIAL_REFUNDABLE, CancellationType.NON_REFUNDABLE]}
                  validate={[validators.required("Type")]}
                  value={updatePayload.type}
                  onChange={({ option }) => setPayload({ ...updatePayload, type: option })}
                />
                {updatePayload.type === CancellationType.PARTIAL_REFUNDABLE &&
                  <>
                    <Box direction='row' margin={{ vertical: "20px" }}>
                      <Text margin={{ right: "10px" }} style={{ fontWeight: "bold" }}>Refund Options</Text>
                      <Button
                        onClick={(event) => {
                          const data = [...hours, { refundAmount: undefined, hour: undefined }];
                          setHours(data);
                        }}
                      >
                        <FontAwesomeIcon size="lg" icon={faAdd} />
                      </Button>
                    </Box>
                    {hours.map((e, i) =>
                      <Box
                        key={i}
                        border="all"
                        pad="10px"
                        margin="5px"
                        style={{ borderRadius: "20px" }}
                      >
                        <FormTextInput
                          id={`refundInput${i}`}
                          name={`refund${i}`}
                          label="Refund Percentage"
                          value={e.refundAmount}
                          type="number"
                          validate={[
                            validators.required("Refund Percentage"),
                            validators.smallerOrEqual(100),
                            validators.biggerOrEqual(0),
                          ]}
                          onChange={(event) => {
                            const value = event.target.value;
                            if (value > 100) return;
                            if (value < 0) return;

                            const data = [...hours];
                            data[i].refundAmount = value;
                            setHours(data);
                          }}
                        />
                        <FormTextInput
                          id={`hourInput${i}`}
                          name={`hour${i}`}
                          value={e.hour}
                          label="Hours Before"
                          type="number"
                          validate={[
                            validators.required("Hour"),
                            validators.biggerOrEqual(0),
                          ]}
                          onChange={(event) => {
                            const value = event.target.value;
                            if (value < 0) return;

                            const data = [...hours];
                            data[i].hour = value;
                            setHours(data);
                          }}
                        />
                        {hours.length > 1 &&
                          <Button
                            margin={{ top: "10px" }}
                            justify='center'
                            alignSelf='center'
                            onClick={(event) => {
                              setHours(hours.filter((e, hourIndex) => i !== hourIndex));
                            }}
                          >
                            <FontAwesomeIcon size="xl" icon={faSubtract} />
                          </Button>
                        }
                      </Box>
                    )}
                  </>
                }
              </Box> :
              <Box pad="small" direction='column'>
                <Card pad="large" margin="medium" justify='start' align='start'>
                  <Text weight="bold" size='large'>{policy.name}</Text>
                  <Text>{policy.description}</Text>
                  <Text>{policy.type}</Text>
                  {Object.entries(policy.hours).map(([k, v]) => <Text key={k}>{`${k} hours before : ${Number(v) * 100}% refund`}</Text>)}
                </Card>
              </Box>
            }
          </Form>
      }
    </PrivatePageLayout >
  )
};

export default PolicyDetails;
import { Grommet } from 'grommet';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './config/i18n/i18n-config';
import AppToast from './components/utils/AppToast';
import PrivateRoute from './components/utils/PrivateRoute';
import PublicRoute from './components/utils/PublicRoute';
import { theme } from './config/app-config';
import appHistory from './helpers/history';
import NotFound from './pages/Private/NotFound';
import { privateRoutes, publicRoutes } from './routes';
import { RootState } from './store/store';

const App: FC = () => {
  const {
    auth: {
      isAuthenticated,
    },
    util: {
      toast,
    },
  } = useSelector((state: RootState) => state);

  return (
    <Grommet theme={theme}>
      <I18nextProvider i18n={i18n}>
        <AppToast
          visible={toast.visible}
          status={toast.status}
          message={toast.message}
          id={toast.toastId}
        />
        <Router history={appHistory}>
          <Switch>
            {privateRoutes.map(({ id, path, component, exact = true }) => (
              <PrivateRoute
                key={id}
                exact={exact}
                path={path}
                component={component}
              />
            ))}

            {publicRoutes.map(({ id, path, component, exact = true }) => (
              <PublicRoute
                key={id}
                exact={exact}
                path={path}
                component={component}
              />
            ))}
            <Route exact path="*" component={NotFound} />
          </Switch>
        </Router>
      </I18nextProvider>
    </Grommet>
  );
};

export default App;

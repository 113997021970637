import React from "react";
import { Box, FormField, DateInput } from "grommet";
import moment from "moment";
import { useState } from "react";
import TimePicker from "src/pages/Private/Reservation/TimePicker";

export default function DateTimeInput(props) {
    const { label, name, initialDate, width, margin, onChange, validate } = props;

    const [date, setDate] = useState(initialDate ? moment(initialDate) : undefined);
    const [hour, setHour] = useState();
    const [minute, setMinute] = useState();

    return (
        <Box direction='row' justify='center' align='center' margin={margin} width={width || "300px"} >
            <Box margin={{ bottom: "20px" }}>
                <FormField
                    name={name}
                    label={label}
                    validate={validate}
                >
                    <DateInput
                        id="dateInput"
                        name="date"
                        format="dd/mm/yyyy"
                        value={initialDate ? date.toISOString() : undefined}
                        style={{ border: "none" }}
                        onChange={(event: any) => {
                            if (event.value) {
                                const newDate = moment(event.value);
                                newDate.set("hour", hour ?? 0);
                                newDate.set("minute", minute ?? 0);
                                setDate(newDate);
                                onChange(newDate.toDate());
                            }
                        }}
                    />
                </FormField>
            </Box>
            <Box width="30px" />
            <Box width="200px">
                <TimePicker onTimeChange={(time) => {
                    const [hour, minute] = time.split(':');
                    setHour(hour);
                    setMinute(minute);
                    if (date) {
                        date.set("hour", hour).set("minute", minute);
                        onChange(date.toDate());
                    }
                }} />
            </Box>
        </Box >
    )
}  
import React, { FC, useEffect, useState } from 'react';
import {
  Box, ColumnConfig, DataTable, Pagination, Text
} from 'grommet';
import PrivatePageLayout from 'src/components/layouts/PrivatePageLayout/PrivatePageLayout';
import CustomButton from 'src/components/utils/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { useHistory } from 'react-router-dom';
import Loading from 'src/components/utils/Loading';
import { UserRowType } from 'src/store/user/user.types';
import { getUsers, getUsersLoading } from 'src/store/user/user.slice';
import { searchUsersAction } from 'src/store/user/user.action';

const columns: ColumnConfig<UserRowType>[] = [
  {
    property: 'id',
    header: "Id",
    primary: true,
  },
  {
    property: 'phone',
    header: 'Phone',
  },
  {
    property: 'email',
    header: 'Email',
  },
  {
    property: 'firstName',
    header: "First Name"
  },
  {
    property: 'lastName',
    header: "Last Name"
  },
  {
    property: 'role',
    header: 'Role',
  },
  {
    property: 'isActive',
    header: 'Status',
  },
];

const Users: FC = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const users = useSelector((state: RootState) => getUsers(state));
  const loading = useSelector((state: RootState) => getUsersLoading(state));
  const [page, setPage] = useState(1);
  const [indices, setIndices] = useState([0, 10]);

  useEffect(() => {
    dispatch(searchUsersAction(page));
  }, [page]);

  useEffect(() => {
    setIndices([0, users.data.length]);
  }, [users]);

  const handleChange = ({ page, startIndex, endIndex }) => {
    setPage(page);
    setIndices([startIndex, Math.min(endIndex, users.meta.itemCount)]);
  };

  return (
    <PrivatePageLayout title='Users'>
      <Box direction='row' justify='between' align='center'>
        <Text textAlign="center" size='large' weight={900} color="dark">Users</Text>
        <Box>
          <CustomButton
            primary
            type="button"
            label="Add Users"
            onClick={() => history.push('/users/add')}
          />
        </Box>
      </Box>
      <Box margin={{ top: "medium" }}>
        {loading ?
          <Loading /> :
          <>
            <DataTable
              columns={columns}
              data={users.data}
              sortable={false}
              resizeable={true}
              step={users.meta.take}
              onClickRow={(event) => {
                // const transfer = transfers.data.find(e => e.id == event.datum.id);
                // dispatch(setTransferDetail(transfer));
                // history.push(`/transfers/${event.datum.id}`);
              }}
            />
            <Box direction='row' align="center" margin={{ top: "20px" }} justify="between" height="20px" width="100%">
              <Text>
                Showing {indices[0] + 1} - {indices[1]} of {users.meta.itemCount}
              </Text>
              <Pagination numberItems={users.meta.itemCount} page={page} onChange={handleChange} />
            </Box>
          </>
        }
      </Box>
    </PrivatePageLayout>
  )
};

export default Users;
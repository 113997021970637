import { Image } from 'grommet';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AuthLayout from '../../components/layouts/AuthLayout';
import Figure from '../../assets/verify-email-bg/figure-2.svg';
import Banner from '../../assets/verify-email-bg/banner.png';
import AuthFormButton from '../../components/auth/AuthFormButton';
import { useQuery } from 'src/hooks/useQuery';
import { useHistory } from 'react-router-dom';

const VerifyUserEmail: FC = () => {
  const history = useHistory();

  const error = useQuery().get("error");
  const { t } = useTranslation();

  return (
    <AuthLayout
      title={t('VerifyUserEmail.title')}
      formTitle={t('VerifyUserEmail.title')}
      formSubTitle={error ?? t('VerifyUserEmail.formSubTitleSuccess')}
      background={
        <>
          <Image
            width="60%"
            src={Banner}
            style={{
              position: 'absolute',
              pointerEvents: 'none',
              top: 0,
              left: 0,
              height: '100vh',
            }}
          />
          <Image
            height="90%"
            alignSelf="center"
            style={{ zIndex: 2 }}
            src={Figure}
          />
        </>
      }
    >
      <>
        <AuthFormButton
          primary
          margin={{ top: '10%' }}
          type="submit"
          onClick={() => history.push('/login')}
          label={t('VerifyUserEmailInfo.goToSignIn')}
        />
      </>
    </AuthLayout>
  );
};

export default VerifyUserEmail;

import { Box, Form, Text } from "grommet";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormSelect from "src/components/utils/FormSelect";
import FormTextInput from "src/components/utils/FormTextInput";
import { validators } from "src/helpers/validators";
import { Role } from "src/models/enums";
import { getUser } from "src/store/auth/auth.slice";
import { TransferLocation } from "src/store/location/location.types";
import { RootState } from "src/store/store";
import { addTransferDetailsTabUpdateField, getLocations, getTransferDetail, removeTransferDetailsTabUpdateField, setTransferUpdateDto } from "src/store/transfer/transfer.slice";
import { CurrencyType, TransferType, TransferStatus } from "src/store/transfer/transfer.types";

export default function TransfersDetailsTab() {
    const dispatch = useDispatch();
    const {
        isEditMode,
        transfer,
        transferUpdate,
    } = useSelector((state: RootState) => getTransferDetail(state));
    const locations = useSelector((state: RootState) => getLocations(state));
    const [origin, setOrigin] = useState<TransferLocation>();
    const [destination, setDestination] = useState<TransferLocation>();
    const user = useSelector((state: RootState) => getUser(state));

    useEffect(() => {
        if (locations) {
            const transferOrigin = locations.find(e => e.id === transfer.originId);
            setOrigin(transferOrigin);
            const transfeDestination = locations.find(e => e.id === transfer.destinationId);
            setDestination(transfeDestination);
        }
    }, [locations, transfer]);

    useEffect(() => {
        dispatch(setTransferUpdateDto({ ...transferUpdate.updateDto, price: isEditMode ? transfer.basePrice : transfer.price }));
    }, [isEditMode]);

    function handleUpdateCount(condition: boolean, field: string) {
        if (condition) dispatch(addTransferDetailsTabUpdateField(field));
        else dispatch(removeTransferDetailsTabUpdateField(field));
    }

    function nameOnChange(event) {
        dispatch(setTransferUpdateDto({
            ...transferUpdate.updateDto,
            name: event.target.value
        }));
        handleUpdateCount(event.target.value != transfer.name, "name");
    }

    function descriptionOnChange(event) {
        dispatch(setTransferUpdateDto({
            ...transferUpdate.updateDto,
            description: event.target.value
        }));
        handleUpdateCount(event.target.value != transfer.description, "description");
    }

    function statusOnChange({ option }) {
        dispatch(setTransferUpdateDto({
            ...transferUpdate.updateDto,
            status: option
        }));
        handleUpdateCount(option != transfer.status, "status");
    }

    function durationOnChange(event) {
        dispatch(setTransferUpdateDto({
            ...transferUpdate.updateDto,
            duration: event.target.value
        }));
        handleUpdateCount(event.target.value != transfer.duration, "duration");
    }

    function priceOnChange(event) {
        dispatch(setTransferUpdateDto({
            ...transferUpdate.updateDto,
            price: event.target.value
        }));
        handleUpdateCount(event.target.value != transfer.price, "price");
    }

    function currencyOnChange({ option }) {
        dispatch(setTransferUpdateDto({
            ...transferUpdate.updateDto,
            currency: option
        }));
        handleUpdateCount(option != transfer.currency, "currency");
    }

    function typeOnChange({ option }) {
        dispatch(setTransferUpdateDto({
            ...transferUpdate.updateDto,
            type: option
        }));
        handleUpdateCount(option != transfer.type, "type");
    }

    function waitTimeOnChange(event) {
        dispatch(setTransferUpdateDto({
            ...transferUpdate.updateDto,
            chauffeurMaxWaitTime: event.target.value
        }));
        handleUpdateCount(event.target.value != transfer.chauffeurMaxWaitTime, "chauffeurMaxWaitTime");
    }

    function originOnChange({ option }) {
        setOrigin(option)
        dispatch(setTransferUpdateDto({
            ...transferUpdate.updateDto,
            originId: option.id,
            destinationId: undefined
        }));
        handleUpdateCount(option.id != transfer.originId, "originId");
    }

    function destinationOnChange({ option }) {
        setDestination(option)
        dispatch(setTransferUpdateDto({
            ...transferUpdate.updateDto,
            destinationId: option.id,
        }));
        handleUpdateCount(option.id != transfer.destinationId, "destinationId");
    }

    return (
        <Box pad="medium" width="100%">{isEditMode ?
            <>
                <FormTextInput
                    id="nameInput"
                    label="Name"
                    name="name"
                    value={transferUpdate.updateDto.name}
                    validate={[validators.required("Name")]}
                    onChange={nameOnChange}
                />
                <FormTextInput
                    id="descriptionInput"
                    label="Description"
                    name="description"
                    value={transferUpdate.updateDto.description}
                    validate={[validators.required("Description")]}
                    onChange={descriptionOnChange}
                />
                <FormSelect
                    id="statusSelect"
                    label="Status"
                    name="status"
                    options={[TransferStatus.DRAFT, TransferStatus.ACTIVE, TransferStatus.PASSIVE]}
                    value={transferUpdate.updateDto.status}
                    validate={[validators.required("Status")]}
                    onChange={statusOnChange}
                />
                <FormTextInput
                    id="durationInput"
                    label="Duration"
                    name="duration"
                    type="number"
                    validate={[
                        validators.required("Duration"),
                        validators.bigger(0, "Duration must be bigger than 0"),
                    ]}
                    value={transferUpdate.updateDto.duration}
                    onChange={durationOnChange}
                />
                <FormTextInput
                    id="priceInput"
                    label="Price"
                    name="price"
                    type="number"
                    validate={[validators.required("Price")]}
                    value={transferUpdate.updateDto.price}
                    onChange={priceOnChange}
                />
                <FormSelect
                    id="currencySelect"
                    name="currency"
                    label="Currency"
                    options={[CurrencyType.TRY, CurrencyType.USD, CurrencyType.EUR]}
                    value={transferUpdate.updateDto.currency}
                    onChange={currencyOnChange}
                />
                <FormSelect
                    id="typeSelect"
                    name="type"
                    label="Type"
                    options={[TransferType.PRIVATE, TransferType.LUXURY, TransferType.SHARED]}
                    value={transferUpdate.updateDto.type}
                    onChange={typeOnChange}
                />
                <FormTextInput
                    id="waitTimeInput"
                    label="Chauffeur Max Wait Time"
                    name="chauffeurMaxWaitTime"
                    type="number"
                    value={transferUpdate.updateDto.chauffeurMaxWaitTime}
                    onChange={waitTimeOnChange}
                />
                <FormSelect
                    id="originSelect"
                    name="origin"
                    label="Origin"
                    options={locations}
                    value={origin}
                    labelKey="name"
                    valueKey="id"
                    onChange={originOnChange}
                />
                <FormSelect
                    id="destinationSelect"
                    name="destination"
                    label="Destination"
                    options={locations ? locations.filter(e => e.id !== origin?.id) : []}
                    value={destination}
                    labelKey="name"
                    valueKey="id"
                    onChange={destinationOnChange}
                />
            </> :
            <>
                <Text><b>Name:</b> {transfer.name}</Text>
                <Text><b>Description:</b> {transfer.description}</Text>
                <Text><b>Status:</b> {transfer.status}</Text>
                <Text><b>Duration:</b> {transfer.duration} min.</Text>
                <Text><b>Price:</b> {transfer.price}</Text>
                {user.role === Role.SUPER_ADMIN && < Text > <b>Base Price:</b> {transfer.basePrice}</Text>}
                <Text><b>Currency:</b> {transfer.currency}</Text>
                <Text><b>Type:</b> {transfer.type}</Text>
                <Text><b>Chauffeur Max Wait Time:</b> {transfer.chauffeurMaxWaitTime} min.</Text>
                <Text><b>Origin:</b> {transfer.origin.name}</Text>
                <Text><b>Destination:</b> {transfer.destination.name}</Text>
            </>
        }
        </Box >
    )
}
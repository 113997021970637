import React, { FC, useState } from 'react';
import {
  Box,
  Button,
  CheckBox,
  Form,
  Layer,
  Text
} from 'grommet';
import PrivatePageLayout from 'src/components/layouts/PrivatePageLayout/PrivatePageLayout';
import { useDispatch } from 'react-redux';
import CustomButton from 'src/components/utils/CustomButton';
import { Transfer } from 'src/store/transfer/transfer.types';
import SearchTransfer from './SearchTransfer';
import FormTextInput from 'src/components/utils/FormTextInput';
import { CreateReservationPayload, Passenger } from 'src/store/reservation/reservation.types';
import { CustomPhoneInput } from 'src/components/utils/CustomPhoneInput/CustomPhoneInput';
import { validators } from 'src/helpers/validators';
import { faAdd, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createReservationAction } from 'src/store/reservation/reservation.action';
import DateTimeInput from 'src/components/utils/DateTimeInput';

const AddReservation: FC = () => {
  const dispatch = useDispatch();

  const [transfer, setTransfer] = useState<Transfer>();
  const [showSearchTransfer, setShowSearchTransfer] = useState(false);
  const [pickupDate, setPickupDate] = useState(new Date());
  const [departureDate, setDepartureDate] = useState(undefined);
  const [arrivalDate, setArrivalDate] = useState(undefined);
  const [isOtherPersonChecked, setIsOtherPersonChecked] = useState(false);

  const [otherPerson, setOtherPerson] = useState<any>({
    name: "",
    countryPhoneCode: "90",
    phone: "",
    email: "",
    isPhoneValid: "",
  });

  const newPassengerObject: any = {
    firstName: "",
    lastName: "",
    countryPhoneCode: "90",
    phone: "",
    email: "",
    passportNumber: "",
    isPhoneValid: "",
  };
  const [passengers, setPassengers] = useState<any[]>([{ ...newPassengerObject }]);
  const [passengerCounts, setPassengerCounts] = useState({
    adultCount: 1,
    childCount: 0,
    infantCount: 0,
    babySeatCount: 0,
  });

  const openTransfers = () => setShowSearchTransfer(true);
  const closeTransfers = () => setShowSearchTransfer(false);

  function onSubmit(event) {
    if (!transfer) return;
    if (passengers.some(e => !e.isPhoneValid)) return;
    if (isOtherPersonChecked && !otherPerson.isPhoneValid) return;
    if (arrivalDate <= departureDate) return;

    const body: CreateReservationPayload = {
      transferId: transfer.id,
      adultCount: passengerCounts.adultCount,
      childCount: passengerCounts.childCount,
      infantCount: passengerCounts.infantCount,
      babySeatCount: passengerCounts.babySeatCount,
      pickupDate: pickupDate,
      fromAddress: event.value.fromAddress,
      toAddress: event.value.toAddress,
      notes: event.value.notes,
      otherPersonMadeBooking: isOtherPersonChecked ? otherPerson : undefined,
      passenger: { ...passengers[0], isPhoneValid: undefined },
      otherPax: passengers.slice(1).map(e => { return { ...e, isPhoneValid: undefined } }),
      flightDetails: {
        carrierCode: event.value.carrierCode,
        no: event.value.flightNo,
        originAirportCode: event.value.originAirportCode,
        destinationAirportCode: event.value.destinationAirportCode,
        departureLocalTime: departureDate,
        arrivalLocalTime: arrivalDate,
      },
    };
    dispatch(createReservationAction(body));
  }

  return (
    <PrivatePageLayout title='Add Reservation'>
      <Box>
        <Form
          onSubmit={onSubmit}
          validate="change"
        >
          {caption()}
          <Box pad="medium" width="100%">
            {ReservationDetailsSection()}
            {TransferSection()}
            {PassengerSection()}
            {OtherPersonSection()}
            {FlightSection()}
          </Box>
        </Form>
      </Box>
    </PrivatePageLayout >
  );

  function caption() {
    return (
      <Box direction='row' justify='between' align='center'>
        <Text textAlign="center" size='large' weight={900} color="dark">Add Reservation</Text>
        <Box>
          <CustomButton
            primary
            type="submit"
            label="Save"
          />
        </Box>
      </Box>
    );
  }

  function TransferSection() {
    return (
      <Box border="all" margin={{ vertical: "10px" }} pad="20px" style={{ borderRadius: "16px" }}>
        <Text size='medium' weight={900} color="dark">Transfer</Text>
        {transfer ? <Box margin={{ vertical: "20px" }}>
          <Text weight="bold"> {transfer.name}</Text>
          <Text weight="bold"> {transfer.description}</Text>
          <br />
          <Text><Text weight="bold">Origin:</Text> {transfer.origin.name}</Text>
          <Text><Text weight="bold">Destination:</Text> {transfer.destination.name}</Text>
          <Text> <Text weight="bold">Price:</Text> {`${transfer.price} ${transfer.currency}`}</Text>
          <Text><Text weight="bold">Capacity:</Text> {transfer.capacity} Person</Text>
          <Text><Text weight="bold">Type:</Text> {transfer.type}</Text>
          <Text><Text weight="bold">Vehicle:</Text> {transfer.vehicle}</Text>
          <br />
          <Text><Text weight="bold">Adult Count:</Text> {passengerCounts.adultCount}</Text>
          <Text><Text weight="bold">Child Count:</Text> {passengerCounts.childCount}</Text>
          <Text><Text weight="bold">Infant Count:</Text> {passengerCounts.infantCount}</Text>
          <Text><Text weight="bold">Baby Seat Count:</Text> {passengerCounts.babySeatCount}</Text>
        </Box> : <>
          <Text color="red" size='15px' margin={{ vertical: "20px" }}>Transfer is required</Text>
        </>}
        <CustomButton
          primary
          label="Select Transfer"
          type='button'
          maxWidth='175px'
          style={{ margin: "0px" }}
          onClick={openTransfers}
        />
        {
          showSearchTransfer &&
          <Layer
            position="center"
            onClickOutside={closeTransfers}
            onEsc={closeTransfers}
          >
            <SearchTransfer
              onTransferSelect={(selected, searchPayload) => {
                setTransfer(selected);
                setPassengerCounts({
                  adultCount: searchPayload.adultCount,
                  childCount: searchPayload.childCount,
                  infantCount: searchPayload.infantCount,
                  babySeatCount: searchPayload.babySeatCount,
                });
                closeTransfers();
              }}
            />
          </Layer>
        }
      </Box>
    );
  }

  function ReservationDetailsSection() {
    return (
      <Box border="all" pad="20px" margin={{ vertical: "10px" }} style={{ borderRadius: "16px" }}>
        <Text size='medium' weight={900} color="dark">Reservation Details</Text>
        <Box margin={{ vertical: "10px" }}>
          <DateTimeInput
            name="pickupTime"
            label="Pickup Time"
            width="300px"
            initialDate={pickupDate.toISOString()}
            onChange={(date) => {
              setPickupDate(date);
            }}
          />
          <FormTextInput
            id="notesInput"
            name="notes"
            label="Notes"
          />
          <FormTextInput
            id="fromAddressInput"
            name="fromAddress"
            label="From Address"
          />
          <FormTextInput
            id="toAddress"
            name="toAddress"
            label="To Address"
          />
        </Box>
      </Box>
    );
  }

  function PassengerSection() {
    return (
      <Box border="all" pad="20px" margin={{ vertical: "10px" }} style={{ borderRadius: "16px" }}>
        <Box direction='row' margin={{ top: "10px", bottom: "5px", left: "15px" }}>
          <Text size='medium' weight={900} color="dark">Passengers</Text>
          <Button
            margin={{ left: "10px" }}
            onClick={() => {
              setPassengers([...passengers, { ...newPassengerObject }]);
            }}>
            <FontAwesomeIcon icon={faAdd} />
          </Button>
        </Box>
        {passengers.map((e, index) => {
          return PassengerInput(e, index);
        })}
      </Box>
    );
  }

  function OtherPersonSection() {
    return (
      <Box border="all" pad="20px" margin={{ vertical: "10px" }} style={{ borderRadius: "16px" }}>
        <Box direction='row' margin={{ top: "10px", bottom: "5px", left: "15px" }}>
          <CheckBox
            checked={isOtherPersonChecked}
            onChange={(event) => setIsOtherPersonChecked(event.target.checked)}
          />
          <Text margin={{ horizontal: "10px" }} size='medium' weight={900} color="dark">Other Person Made Booking</Text>
        </Box>
        {isOtherPersonChecked &&
          <>
            <FormTextInput
              id={`otherPersonNameInput`}
              name={`otherPersonName`}
              label="Full Name"
              value={otherPerson.name}
              validate={[
                validators.required("Full Name")
              ]}
              onChange={(event) => {
                setOtherPerson({ ...otherPerson, name: event.target.value });
              }}
            />
            <CustomPhoneInput
              name={`otherPersonPhoneInput`}
              label='Phone'
              required={true}
              value={otherPerson.phone}
              countryCode={otherPerson.countryPhoneCode}
              onChange={function (countryCode: string, phone: string, valid: boolean): void {
                setOtherPerson({ ...otherPerson, phone: phone, countryPhoneCode: countryCode, isPhoneValid: valid });
              }} />
            <FormTextInput
              id={`otherPersonEmailInput`}
              name={`otherPersonEmail`}
              label="Email"
              validate={
                [
                  validators.required("Email"),
                  validators.email("Email format not valid"),
                ]
              }
              onChange={(event) => {
                setOtherPerson({ ...otherPerson, email: event.target.value });
              }}
            />
          </>}
      </Box>
    );
  }

  function PassengerInput(passenger: Passenger, index: number) {
    const list = [...passengers];
    return (
      <Box key={index} border="all" pad="10px" margin="10px" style={{ borderRadius: "16px" }}>
        <Box direction='row' margin={{ top: "10px", bottom: "5px", left: "15px" }}>
          <Text size='14px'>{`Passenger ${index + 1}`}</Text>
          {index !== 0 &&
            <Button
              margin={{ left: "10px" }}
              onClick={() => { setPassengers([...passengers.filter((e, i) => i !== index)]) }}>
              <FontAwesomeIcon icon={faRemove} />
            </Button>
          }
        </Box>
        <FormTextInput
          id={`firstNameInput${index}`}
          name={`firstName${index}`}
          label="First Name"
          value={passenger.firstName}
          validate={[
            validators.required("First Name")
          ]}
          onChange={(event) => {
            list[index].firstName = event.target.value;
            setPassengers(list);
          }}
        />
        <FormTextInput
          id={`lastNameInput${index}`}
          name={`lastName${index}`}
          label="Last Name"
          value={passenger.lastName}
          validate={[
            validators.required("Last Name")
          ]}
          onChange={(event) => {
            list[index].lastName = event.target.value;
            setPassengers(list);
          }}
        />
        <CustomPhoneInput
          name={`phone${index}`}
          label='Phone'
          required={index == 0 ? true : false}
          value={passenger.phone}
          countryCode={passenger.countryPhoneCode}
          onChange={function (countryCode: string, phone: string, valid: boolean): void {
            list[index].phone = phone;
            list[index].countryPhoneCode = countryCode;
            list[index].isPhoneValid = valid;
            setPassengers(list);
          }} />
        <FormTextInput
          id={`emailInput${index}`}
          name={`email${index}`}
          label="Email"
          validate={
            index === 0 ?
              [
                validators.required("Email"),
                validators.email("Email format not valid"),
              ] : [
                validators.email("Email format not valid"),
              ]
          }
          onChange={(event) => {
            list[index].email = event.target.value;
            setPassengers(list);
          }}
        />
        <FormTextInput
          id={`passportInput${index}`}
          name={`passport${index}`}
          label="Passport Number"
          value={passenger.passportNumber}
          validate={index != 0 ? undefined : [validators.required("Passport Number")]}
          onChange={(event) => {
            list[index].passportNumber = event.target.value;
            setPassengers(list);
          }}
        />
      </Box>
    )
  }

  function FlightSection() {
    return (
      <Box border="all" pad="20px" margin={{ vertical: "10px" }} style={{ borderRadius: "16px" }}>
        <Text size='medium' weight={900} color="dark">Flight</Text>
        <Box margin={{ vertical: "10px" }}>
          <FormTextInput
            id="carrierCodeInput"
            name="carrierCode"
            label="Carrier Code"
          />
          <FormTextInput
            id="flighNoInput"
            name="flightNo"
            label="Flight Number"
          />
          <FormTextInput
            id="originAirportInput"
            name="originAirportCode"
            label="Origin Airtport Code"
          />
          <FormTextInput
            id="destinationAirportInput"
            name="destinationAirportCode"
            label="Destination Airtport Code"
          />
          <DateTimeInput
            name="departureLocalTime"
            label="Departure Local Time"
            width="350px"
            margin={{ top: "10px" }}
            onChange={(date) => {
              setDepartureDate(date);
            }}
          />
          <DateTimeInput
            name="arrivalLocalTime"
            label="Arrival Local Time"
            width="350px"
            onChange={(date) => {
              setArrivalDate(date);
            }}
          />
          {
            arrivalDate <= departureDate
            && <Text color="red" size='14px' margin={{ vertical: "20px" }}>
              Arrival date must be greater than departure date
            </Text>}
        </Box>
      </Box>
    );
  }
}

export default AddReservation;
import React from 'react'
import { FormField, TextInput } from 'grommet'

export default function FormTextInput(props) {
    const { id, name, label, type, placeholder, value, onChange, validate, margin, plain } = props;
    return (
        <FormField
            label={label}
            name={name}
            htmlFor={id}
            validate={validate}
            margin={margin || "0px"}
        >
            <TextInput
                id={id}
                name={name}
                plain={plain}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                type={type}
            />
        </FormField >
    )
}
import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Text,
  Grid,
  Button,
} from 'grommet';
import PrivatePageLayout from 'src/components/layouts/PrivatePageLayout/PrivatePageLayout';
import GridImage from '../Transfer/TransferDetails/components/images/GridImage';
import { useDispatch, useSelector } from 'react-redux';
import { getImages, getImagesLoading } from 'src/store/default-images/images.slice';
import Loading from 'src/components/utils/Loading';
import { addDefaultImageAction, getDefaultImagesAction, removeDefaultImageAction } from 'src/store/default-images/images.action';
import ExtendedBox from 'src/components/utils/ExtendedBox';
import { Add } from 'grommet-icons';
import ImageSelect from './ImageSelect';

const DefaultImages: FC = (props) => {
  const dispatch = useDispatch();

  const images = useSelector((state) => getImages(state));
  const loading = useSelector((state) => getImagesLoading(state));

  useEffect(() => {
    dispatch(getDefaultImagesAction());
  }, []);

  const [showImageSelect, setShowImageSelect] = useState(false);

  function removeDefaultImage(id: string) {
    dispatch(removeDefaultImageAction(id));
  }
  return (
    <PrivatePageLayout title='Default Images'>
      <Box direction='row' pad={{vertical: "small"}} justify='between' align='center'>
        <Text textAlign="center" size='large' weight={900} color="dark">Default Images</Text>
      </Box>
      <Box width="100%" pad={{ vertical: "small" }}>
        {loading ?
          <Loading /> :
          <Grid width="100%"  gap="small" columns={{count:"fit", size:"small"}}>
            {
              images.map(e => {
                return <GridImage
                  key={e.id}
                  image={e}
                  isEditMode={true}
                  fit="cover"
                  onRemove={() => {
                    removeDefaultImage(e.id);
                  }}
                />
              })
            }
            <Box>
              <Button
                onClick={() => {
                  setShowImageSelect(true);
                }}
              >
                <ExtendedBox
                  justify="center"
                  align="center"
                  height="125px"
                  width="210px"
                  key="uploadImage"
                  border="all"
                  round="8px"
                >
                  <Add size='large' />
                </ExtendedBox>
              </Button>
            </Box>
            {showImageSelect && (
              <ImageSelect
                onSubmit={async (file: File, base64: string) => {
                  setShowImageSelect(false);
                  dispatch(addDefaultImageAction(file));
                }}
                onDismiss={() => {
                  setShowImageSelect(false);
                }}
              />
            )}
          </Grid>
        }
      </Box>
    </PrivatePageLayout>
  )
};

export default DefaultImages;
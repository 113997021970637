import { Box, Text } from "grommet";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "src/components/utils/CustomButton";
import SelectLayerDataTable from "src/components/utils/SelectLayerDataTable";
import { getTransferCompaniesAction } from "src/store/transfer/transfer.action";
import { getAddTransfer, getCompanies, setAddTransferDto } from "src/store/transfer/transfer.slice";

export default function CompanyTab() {
    const dispatch = useDispatch();

    const { payload } = useSelector((state) => getAddTransfer(state));
    const companies = useSelector((state) => getCompanies(state));
    const [page, setPage] = useState<number>();

    useEffect(() => {
        getCompaniesRequest();
    }, [page]);

    const getCompaniesRequest = () => {
        dispatch(getTransferCompaniesAction(page, companies.meta.take));
    };

    const handleChange = ({ page }) => {
        setPage(page);
    };

    const [open, setOpen] = useState(false);
    const onOpen = () => setOpen(true);
    const onClose = () => setOpen(undefined);
    return (
        <Box pad="medium">
            <Box>
                <CustomButton
                    label={payload.company ? "Change" : "Select"}
                    onClick={onOpen}
                    color="dark-3" />
                <SelectLayerDataTable
                    isOpen={open}
                    title="Select Company"
                    columns={[
                        {
                            property: 'id',
                            header: "Id",
                            primary: true,
                        },
                        {
                            property: 'companyName',
                            header: "Name"
                        },
                        {
                            property: 'taxNumber',
                            header: "Tax Number"
                        },
                        {
                            property: 'address',
                            header: 'Address',
                        }
                    ]}
                    listData={companies}
                    onSelect={(data) => {
                        const company = companies.data.find(e => e.id === data.id);
                        dispatch(setAddTransferDto({
                            ...payload,
                            company,
                            companyId: company.id,
                        }));
                    }}
                    onClose={onClose}
                    page={page}
                    onPageChanged={handleChange}
                />
            </Box>
            {payload.company ?
                <Box pad={{ top: "10px" }}>
                    <Text weight="bold" size='large'>{payload.company.companyName}</Text>
                    <Text>{payload.company.taxNumber}</Text>
                    <Text>{payload.company.address}</Text>
                    <Text margin={{ top: "10px" }} weight="bold">Contact Info</Text>
                    <Text>{payload.company.contactName}</Text>
                    <Text>{payload.company.contactEmail}</Text>
                    <Text>{`+${payload.company.contactCountryPhoneCode+payload.company.contactPhone}`}</Text>
                    <Text margin={{ top: "10px" }} weight="bold">Phone Numbers</Text>
                    {payload.company.phoneNumbers.map((e,i) => <Text key={i} >{`+${e.countryPhoneCode+e.phone}`}</Text>)}
                </Box> : <Text margin={{ left: "10px" }} color="red">Company is required</Text>
            }
        </Box >
    )
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { CompanyState } from "./company.types";

const CompanySliceInitialState: CompanyState = {
    companies: {
        data: [],
        meta: {
            hasNextPage: false,
            hasPreviousPage: false,
            itemCount: 0,
            page: 1,
            pageCount: 0,
            take: 10,
        },
    },
    loading: false,
    details: {
        company: undefined,
        loading: false,
    }
};

export const companySlice: any = createSlice({
    name: "company",
    initialState: CompanySliceInitialState,
    reducers: {
        setCompaniesLoading: (state, action: PayloadAction<any>) => {
            state.loading = action.payload;
        },
        setCompanies: (state, action: PayloadAction<any>) => {
            state.companies = action.payload;
        },
        setCompaniesDetailsLoading: (state, action: PayloadAction<any>) => {
            state.details.loading = action.payload;
        },
        setCompaniesDetail: (state, action: PayloadAction<any>) => {
            state.details.company = action.payload;
        },
    },
});

export const {
    setCompaniesLoading,
    setCompanies,
    setCompaniesDetailsLoading,
    setCompaniesDetail,
} = companySlice.actions;

export const getCompanies = (state: RootState) => state.company.companies;
export const getCompaniesLoading = (state: RootState) => state.company.loading;
export const getCompanyDetails = (state: RootState) => state.company.details.company;
export const getCompanyDetailsLoading = (state: RootState) => state.company.details.loading;

export default companySlice.reducer;

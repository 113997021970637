import { Card, Box, Text } from "grommet";
import { Duplicate, Edit, Save } from "grommet-icons";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "src/components/utils/CustomButton";
import { RootState } from "src/store/store";
import { getTransferDetail, getTransferUpdateErrorCount, resetTransferUpdateDto, setTransferDetailEditMode } from "src/store/transfer/transfer.slice";
import GridImage from "./images/GridImage";
import { duplicateTransferAction, updateTransferAction } from "src/store/transfer/transfer.action";
import { TransferStatus } from "src/store/transfer/transfer.types";

export default function TransferHeaderCard() {
    const dispatch = useDispatch();
    const { isEditMode, transfer } = useSelector((state: RootState) => getTransferDetail(state));
    const errorCount = useSelector((state: RootState) => getTransferUpdateErrorCount(state));

    function onCancel() {
        dispatch(setTransferDetailEditMode(false));
        dispatch(resetTransferUpdateDto());
    }

    function updateStatus(event) {
        const status = transfer.status === TransferStatus.DRAFT || transfer.status === TransferStatus.PASSIVE ? TransferStatus.ACTIVE : TransferStatus.PASSIVE;
        dispatch(updateTransferAction(transfer.id, { status }, [], [], []));
    }

    return (
        <Card margin={{ vertical: "35px" }} pad="medium" width="100%" background="light-2" gap="medium">
            <Box direction='row' align='center' justify='between'>
                <Box direction='row' align='center'>
                    <GridImage image={transfer.images[0]} fit="cover" />
                    <Box pad={{ left: "15px" }}>
                        <Text weight="bold" >{transfer.name}</Text>
                        {(transfer.brand || transfer.model) && <Text size='small'>{`${transfer.brand} ${transfer.model}`}</Text>}
                        <Text size='small'>{`${transfer.price} ${transfer.currency}`}</Text>
                        <Text size='small'>{transfer.type}</Text>
                        <Text size='small'>{transfer.vehicle}</Text>
                        <Text size='small'>{`${transfer.capacity} Person`}</Text>
                    </Box>
                </Box>
                <Box direction='row'>
                    <CustomButton
                        primary
                        maxWidth="100px"
                        type="button"
                        onClick={updateStatus}
                    >
                        <Box direction='row' justify='center' >
                            <Text margin={{ horizontal: "10px" }}>{
                                transfer.status === TransferStatus.DRAFT || transfer.status === TransferStatus.PASSIVE ?
                                    "Enable" : "Disable"}</Text>
                        </Box>
                    </CustomButton>
                    {isEditMode && <CustomButton
                        primary
                        maxWidth="100px"
                        onClick={onCancel}
                    >
                        <Box direction='row' justify='center' >
                            <>
                                <Edit color='white' size='20px' />
                                <Text margin={{ horizontal: "10px" }}>Cancel</Text>
                            </>
                        </Box>
                    </CustomButton>
                    }
                    <CustomButton
                        primary
                        maxWidth="100px"
                        type="submit"
                        disabled={isEditMode && errorCount > 0}
                    >
                        <Box direction='row' justify='center' >
                            {isEditMode ?
                                <>
                                    <Save color='white' size='20px' />
                                    <Text margin={{ horizontal: "10px" }}>Save</Text>
                                </> :
                                <>
                                    <Edit color='white' size='20px' />
                                    <Text margin={{ horizontal: "10px" }}>Edit</Text>
                                </>}
                        </Box>
                    </CustomButton>
                    {!isEditMode && <CustomButton
                        primary
                        maxWidth="125px"
                        type="button"
                        onClick={() => {
                            dispatch(duplicateTransferAction(transfer.id));
                        }}
                    >
                        <Box direction='row' justify='center' align="center" >
                            <Duplicate color='white' size='20px' />
                            <Text margin={{ horizontal: "5px" }}>Duplicate</Text>
                        </Box>
                    </CustomButton>}
                </Box>
            </Box>
        </Card >
    )
}
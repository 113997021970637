import React from 'react'
import { FormField, Select } from 'grommet'

export default function FormSelect(props) {
    const { id, label, name, multiple, validate, options, value, onChange, labelKey, valueKey, onFormFieldChange } = props;
    return (
        <FormField
            label={label}
            htmlFor={id}
            name={name}
            validate={validate}
            onChange={onFormFieldChange}
        >
            <Select
                id={id}
                placeholder="Select"
                multiple={multiple}
                name={name}
                labelKey={labelKey}
                valueKey={valueKey}
                options={options}
                value={value}
                onChange={onChange}
            />
        </FormField>
    )
}

import { Box } from "grommet";
import { useDispatch, useSelector } from "react-redux";
import FormSelect from "src/components/utils/FormSelect";
import FormTextInput from "src/components/utils/FormTextInput";
import { validators } from "src/helpers/validators";
import { RootState } from "src/store/store";
import { getAddTransfer, setAddTransferDto } from "src/store/transfer/transfer.slice";
import { VehicleType } from "src/store/transfer/transfer.types";

export default function VehicleDetailsTab() {
    const dispatch = useDispatch();
    const { payload } = useSelector((state: RootState) => getAddTransfer(state));

    function brandOnChange(event) {
        dispatch(setAddTransferDto({ ...payload, brand: event.target.value }));
    }

    function modelOnChange(event) {
        dispatch(setAddTransferDto({ ...payload, model: event.target.value }));
    }

    function capacityOnChange(event) {
        dispatch(setAddTransferDto({ ...payload, capacity: event.target.value }));
    }

    function vehicleOnChange({ option }) {
        dispatch(setAddTransferDto({ ...payload, vehicle: option }));
    }

    function luggageCarryOnOnChange(event) {
        dispatch(setAddTransferDto({
            ...payload,
            luggageAllowance: {
                ...payload.luggageAllowance,
                carryOn: event.target.value,
            }
        }));
    }

    function luggageCheckInOnChange(event) {
        dispatch(setAddTransferDto({
            ...payload,
            luggageAllowance: {
                ...payload.luggageAllowance,
                checkIn: event.target.value,
            }
        }));
    }

    return (
        <Box pad="medium">
            <FormTextInput
                id="brandInput"
                label="Brand"
                name="brand"
                value={payload.brand}
                onChange={brandOnChange}
            />
            <FormTextInput
                id="modelInput"
                label="Model"
                name="model"
                value={payload.model}
                onChange={modelOnChange}
            />
            <FormSelect
                id="vehicleSelect"
                label="Vehicle"
                name="vehicle"
                options={[VehicleType.CAR, VehicleType.VAN, VehicleType.BUS]}
                value={payload.vehicle}
                validate={[validators.required("Vehicle")]}
                onChange={vehicleOnChange}
            />
            <FormTextInput
                id="capacityInput"
                label="Capacity"
                name="capacity"
                type="number"
                validate={[
                    validators.required("Capacity"),
                    validators.bigger(0, "Capacity must be bigger than 0"),
                ]}
                value={payload.capacity}
                onChange={capacityOnChange}
            />
            <FormTextInput
                id="luggageCarryOnInput"
                label="Luggage Carry On"
                name="luggageCarryOn"
                type="number"
                value={payload.luggageAllowance.carryOn}
                onChange={luggageCarryOnOnChange}
            />
            <FormTextInput
                id="luggageCheckInInput"
                label="Luggage Check In"
                name="luggageCheckIn"
                type="number"
                value={payload.luggageAllowance.checkIn}
                onChange={luggageCheckInOnChange}
            />
        </Box>
    )
}
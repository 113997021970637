import React from 'react';
import { useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
  useLocation,
} from 'react-router-dom';
import { getToken, removeRedirectPath, removeToken, setRedirectPath } from 'src/helpers/localdb';
import { RootState } from 'src/store/store';

interface Props extends RouteProps {
  component:
  | React.ComponentType<RouteComponentProps<any>>
  | React.ComponentType<any>;
}

const PrivateRoute: React.FC<Props> = ({ component: Page, ...rest }) => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const location = useLocation();
  return (
    <Route
      {...rest}
      render={(props) => {
        removeRedirectPath();
        if (isAuthenticated)
          return (<Page {...props} />);
        // Check and remove token for logout redirection prevent
        if (!getToken()) {
          setRedirectPath(props.location.pathname);
        }
        removeToken();
        return (
          <Redirect to={
            {
              pathname: '/login',
              search: `${location.search}`,
            }
          } />
        )
      }
      }
    />
  );
};

export default PrivateRoute;

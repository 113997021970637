import { Box } from "grommet";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormSelect from "src/components/utils/FormSelect";
import FormTextInput from "src/components/utils/FormTextInput";
import { validators } from "src/helpers/validators";
import { TransferLocation } from "src/store/location/location.types";
import { RootState } from "src/store/store";
import { getTransferLocationsAction } from "src/store/transfer/transfer.action";
import { getAddTransfer, getLocations, setAddTransferDto } from "src/store/transfer/transfer.slice";
import { CurrencyType, TransferType } from "src/store/transfer/transfer.types";

export default function TransfersDetailsTab() {
    const dispatch = useDispatch();
    const [origin, setOrigin] = useState<TransferLocation>();
    const [destination, setDestination] = useState<TransferLocation>();

    const { payload } = useSelector((state: RootState) => getAddTransfer(state));
    const locations = useSelector((state: RootState) => getLocations(state));

    useEffect(() => {
        if (!locations) {
            dispatch(getTransferLocationsAction());
        }
    }, []);

    useEffect(() => {
        if (locations && locations.length > 0) {
            setOrigin(locations[0]);
            if (locations.length > 1) {
                setDestination(locations[1]);
            }
            dispatch(setAddTransferDto({
                ...payload,
                originId: locations[0].id,
                destinationId: locations.length > 1 ? locations[1].id : undefined,
            }));
        }
    }, [locations]);

    function nameOnChange(event) {
        dispatch(setAddTransferDto({
            ...payload,
            name: event.target.value
        }));
    }

    function descriptionOnChange(event) {
        dispatch(setAddTransferDto({
            ...payload,
            description: event.target.value
        }));
    }

    function durationOnChange(event) {
        dispatch(setAddTransferDto({
            ...payload,
            duration: event.target.value
        }));
    }

    function priceOnChange(event) {
        dispatch(setAddTransferDto({
            ...payload,
            price: event.target.value
        }));
    }

    function currencyOnChange({ option }) {
        dispatch(setAddTransferDto({
            ...payload,
            currency: option
        }));
    }

    function typeOnChange({ option }) {
        dispatch(setAddTransferDto({
            ...payload,
            type: option
        }));
    }

    function waitTimeOnChange(event) {
        dispatch(setAddTransferDto({
            ...payload,
            chauffeurMaxWaitTime: event.target.value
        }));
    }

    function originOnChange({ option }) {
        setOrigin(option)
        dispatch(setAddTransferDto({
            ...payload,
            originId: option.id,
            destinationId: undefined
        }));
    }

    function destinationOnChange({ option }) {
        setDestination(option)
        dispatch(setAddTransferDto({
            ...payload,
            destinationId: option.id,
        }));
    }

    return (
        <Box pad="medium" width="100%">
            <FormTextInput
                id="nameInput"
                label="Name"
                name="name"
                value={payload.name}
                validate={[validators.required("Name")]}
                onChange={nameOnChange}
            />
            <FormTextInput
                id="descriptionInput"
                label="Description"
                name="description"
                value={payload.description}
                validate={[validators.required("Description")]}
                onChange={descriptionOnChange}
            />
            <FormTextInput
                id="durationInput"
                label="Duration"
                name="duration"
                type="number"
                validate={[
                    validators.required("Duration"),
                    validators.bigger(0, "Duration must be bigger than 0"),
                ]}
                value={payload.duration}
                onChange={durationOnChange}
            />
            <FormTextInput
                id="priceInput"
                label="Price"
                name="price"
                type="number"
                validate={[validators.required("Price")]}
                value={payload.price}
                onChange={priceOnChange}
            />
            <FormSelect
                id="currencySelect"
                name="currency"
                label="Currency"
                options={[CurrencyType.TRY, CurrencyType.USD, CurrencyType.EUR]}
                validate={[validators.required("Currency")]}
                value={payload.currency}
                onChange={currencyOnChange}
            />
            <FormSelect
                id="typeSelect"
                name="type"
                label="Type"
                options={[TransferType.PRIVATE, TransferType.LUXURY, TransferType.SHARED]}
                validate={[validators.required("Type")]}
                value={payload.type}
                onChange={typeOnChange}
            />
            <FormTextInput
                id="waitTimeInput"
                label="Chauffeur Max Wait Time"
                name="chauffeurMaxWaitTime"
                type="number"
                value={payload.chauffeurMaxWaitTime}
                onChange={waitTimeOnChange}
            />
            <FormSelect
                id="originSelect"
                name="origin"
                label="Origin"
                options={locations ?? []}
                value={origin}
                labelKey="name"
                valueKey="id"
                onChange={originOnChange}
            />
            <FormSelect
                id="destinationSelect"
                name="destination"
                label="Destination"
                options={locations ? locations.filter(e => e.id !== origin?.id) : []}
                value={destination}
                labelKey="name"
                valueKey="id"
                onChange={destinationOnChange}
            />
        </Box>
    )
}
import * as ImageService from "src/store/default-images/images.service";
import { addImage, setImageRemoved, setImages, setImagesLoading } from "./images.slice";
export const getDefaultImagesAction = () => {
  return async (dispatch) => {
    try {
      dispatch(setImagesLoading(true));
      const payload = await ImageService.getDefaultImages();
      dispatch(setImages(payload));
    } catch (err: any) {
      dispatch(setImagesLoading(false));
    }
  };
};

export const addDefaultImageAction = (image: File) => {
  return async (dispatch) => {
    try {
      dispatch(setImagesLoading(true));
      const payload = await ImageService.addDefaultImage(image);
      dispatch(addImage(payload));
    } catch (err: any) {
    }
  };
};

export const removeDefaultImageAction = (id: string) => {
  return async (dispatch) => {
    try {
      dispatch(setImagesLoading(true));
      await ImageService.removeDefaultImage(id);
      dispatch(setImageRemoved(id));
    } catch (err: any) {
      dispatch(setImagesLoading(false));
    }
  };
};
import { http } from '../../config/http';
import { User } from '../user/user.types';
import {
  LoginPayload,
  RegisterInvitationPayload,
  RegisterPayload,
  ResetPasswordPayload,
  SignInResponse,
  UpdatePasswordPayload,
} from './auth.types';

export const login = async (user: LoginPayload): Promise<SignInResponse> =>
  http
    .post('/auth/login', user, {
      showErrorToast: (err) =>
        err?.response?.status !== 403,
    })
    .then((res) => res.data);

export const logOut = (): Promise<any> =>
  http.post('/auth/logout').then((res) => res.data);

export const register = async (user: RegisterPayload): Promise<User> =>
  http.post('/auth/register', user).then((res) => res.data);

export const registerInvitation = async (user: RegisterInvitationPayload): Promise<SignInResponse> =>
  http.post('/auth/register/invitation', user).then((res) => res.data);

export const resendMailVerificationToken = async (
  email: string
): Promise<any> => {
  return http
    .post(`/auth/resend-verification-email?email=${email}`)
    .then((res) => res.data);
};

export const resetPasswordRequest = (email: string): Promise<void> =>
  http.post('/auth/request-reset-password', {
    email,
  });

export const resetPassword = (body: ResetPasswordPayload): Promise<void> =>
  http.put('/auth/reset-password', {
    token: body.token,
    password: body.password,
  });

export const updatePassword = (body: UpdatePasswordPayload): Promise<void> =>
  http.put('/auth/password', body);

export const authenticateWithThirdPartyCode = async (
  name: string,
  code: string | null,
  email: string | null
): Promise<SignInResponse> =>
  http
    .post(`/auth/third-party/${name}`, code ? { code } : { email })
    .then((res) => res.data);
import React from 'react';
import { Button, Box, Nav, Sidebar } from 'grommet';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faBriefcase, faGear, faImage, faLocationDot, faUsers, faVanShuttle } from '@fortawesome/free-solid-svg-icons';
import { Role } from 'src/models/enums';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { getUser } from 'src/store/auth/auth.slice';

const sideMenuList = [
    {
        label: "Transfers",
        href: "/transfers",
        icon: faVanShuttle,
        roles: [Role.SUPER_ADMIN, Role.COMPANY, Role.ADMIN]
    },
    {
        label: "Companies",
        href: "/companies",
        icon: faBriefcase,
        roles: [Role.SUPER_ADMIN, Role.ADMIN]
    },
    {
        label: "Locations",
        href: "/locations",
        icon: faLocationDot,
        roles: [Role.SUPER_ADMIN, Role.ADMIN]
    },
    {
        label: "Reservations",
        href: "/reservations",
        icon: faBook,
        roles: [Role.SUPER_ADMIN, Role.ADMIN, Role.COMPANY, Role.USER, Role.API_USER]
    },
    {
        label: "Users",
        href: "/users",
        icon: faUsers,
        roles: [Role.SUPER_ADMIN, Role.ADMIN]
    },
    {
        label: "Policies",
        href: "/policies",
        icon: faGear,
        roles: [Role.SUPER_ADMIN, Role.ADMIN]
    },
    {
        label: "Default Images",
        href: "/default-images",
        icon: faImage,
        roles: [Role.SUPER_ADMIN, Role.ADMIN]
    },
];

function SidebarButton({ icon, label, href, ...rest }) {
    const history = useHistory()
    return (< Box pad="small" >
        <Button
            gap="medium"
            alignSelf="start"
            plain
            icon={icon}
            label={label}
            onClick={() => {
                history.push(href);
            }}
            {...rest}
        />
    </Box >);
}

const MainNavigation = () => {
    const user = useSelector((state: RootState) => getUser(state));
    return (
        <Nav aria-label="main navigation" gap="small" responsive={true}>
            {sideMenuList.filter(e => e.roles.includes(user.role)).map(e =>
            (<SidebarButton
                key={e.href}
                icon={<FontAwesomeIcon icon={e.icon} size='xl' />}
                label={e.label}
                href={e.href}
            />)
            )}
        </Nav>
    );
}

export default function CustomSideBar() {
    return (
        <Box width="300px" border={{ color: "light-4", side: "right" }} style={{ height: "calc(100vh - 75px)" }}>
            <Sidebar
                responsive={true}
                background="light-2"
                pad="medium">
                <MainNavigation />
            </Sidebar>
        </Box>);
}
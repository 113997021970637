import * as CompanyService from "src/store/company/company.service";
import {
  setCompanies,
  setCompaniesDetail,
  setCompaniesDetailsLoading,
  setCompaniesLoading,
} from "./company.slice";
import appHistory from "src/helpers/history";

export const searchCompaniesAction = (page?: number, take?: number) => {
  return async (dispatch) => {
    try {
      dispatch(setCompaniesLoading(true));
      const payload = await CompanyService.getTransferCompanies(page, take);
      dispatch(setCompanies(payload));
      dispatch(setCompaniesLoading(false));
    } catch (err: any) {
      dispatch(setCompaniesLoading(false));
    }
  };
};

export const getCompanyAction = (id: number) => {
  return async (dispatch) => {
    try {
      dispatch(setCompaniesDetailsLoading(true));
      const payload = await CompanyService.getTransferCompany(id);
      dispatch(setCompaniesDetail(payload));
      dispatch(setCompaniesDetailsLoading(false));
    } catch (err: any) {
      dispatch(setCompaniesDetailsLoading(false));
    }
  };
};


export const addCompanyAction = (payload: any) => {
  return async (dispatch) => {
    try {
      dispatch(setCompaniesLoading(true));
      const result = await CompanyService.addTransferCompany(payload);
      dispatch(setCompaniesDetail(result));
      appHistory.push(`/companies/${result.id}`);
      dispatch(setCompaniesLoading(false));
    } catch (err: any) {
      dispatch(setCompaniesLoading(false));
    }
  };
};

export const deleteCompanyAction = (id: number) => {
  return async (dispatch) => {
    try {
      dispatch(setCompaniesLoading(true));
      await CompanyService.deleteTransferCompany(id);
      dispatch(setCompaniesLoading(false));
      appHistory.push("/companies");
    } catch (err: any) {
      dispatch(setCompaniesLoading(false));
    }
  };
};

export const updateCompanyAction = (id: number, payload: any) => {
  return async (dispatch) => {
    try {
      dispatch(setCompaniesDetailsLoading(true));
      const result = await CompanyService.updateTransferCompany(id, payload);
      dispatch(setCompaniesDetail(result));
      dispatch(setCompaniesDetailsLoading(false));
    } catch (err: any) {
      dispatch(setCompaniesDetailsLoading(false));
    }
  };
};

export const setCompanyAdminAction = (id: number, email: string) => {
  return async (dispatch) => {
    try {
      dispatch(setCompaniesDetailsLoading(true));
      const result = await CompanyService.setTransferCompanyAdmin(id, email);
      dispatch(setCompaniesDetail(result));
      dispatch(setCompaniesDetailsLoading(false));
    } catch (err: any) {
      dispatch(setCompaniesDetailsLoading(false));
    }
  };
};
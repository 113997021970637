import React, { FC, useState } from 'react';
import { Box, Button, Form, Text } from 'grommet';
import PrivatePageLayout from 'src/components/layouts/PrivatePageLayout/PrivatePageLayout';
import { useDispatch } from 'react-redux';
import CustomButton from 'src/components/utils/CustomButton';
import FormTextInput from 'src/components/utils/FormTextInput';
import { validators } from 'src/helpers/validators';
import { CancellationPolicy, CancellationType } from 'src/store/policy/policy.types';
import FormSelect from 'src/components/utils/FormSelect';
import { addPolicyAction } from 'src/store/policy/policy.action';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faSubtract } from '@fortawesome/free-solid-svg-icons';

const AddPolicy: FC = () => {
  const dispatch = useDispatch();
  const [payload, setPayload] = useState<CancellationPolicy>({
    name: "",
    description: "",
    type: undefined,
    hours: {},
  });

  const [hours, setHours] = useState([{ refundAmount: undefined, hour: undefined }]);

  function onSubmit(event) {
    if (payload.type === CancellationType.FULL_REFUNDABLE) payload.hours = { "0": 1 }
    else if (payload.type === CancellationType.NON_REFUNDABLE) payload.hours = { "0": 0 }
    else {
      const dataHour = {};
      for (const e of hours) {
        dataHour[e.hour] = e.refundAmount / 100;
      }
      payload.hours = dataHour;
    }
    dispatch(addPolicyAction(payload));
  }

  return (
    <PrivatePageLayout title='Add Policy'>
      <Box>
        <Form
          onSubmit={onSubmit}
          validate="change"
        >
          <Box direction='row' justify='between' align='center'>
            <Text textAlign="center" size='large' weight={900} color="dark">Add Policy</Text>
            <Box>
              <CustomButton
                primary
                type="submit"
                label="Save"
              />
            </Box>
          </Box>
          <Box pad="medium" width="100%">
            <FormTextInput
              id="policyNameInput"
              label="Policy Name"
              name="name"
              validate={[validators.required("Policy Name")]}
              value={payload.name}
              onChange={(event) => setPayload({ ...payload, name: event.target.value })}
            />
            <FormTextInput
              id="descriptionInput"
              label="Description"
              name="description"
              validate={[validators.required("Description")]}
              value={payload.description}
              onChange={(event) => setPayload({ ...payload, description: event.target.value })}
            />
            <FormSelect
              id="typeSelect"
              name="type"
              label="Type"
              options={[CancellationType.FULL_REFUNDABLE, CancellationType.PARTIAL_REFUNDABLE, CancellationType.NON_REFUNDABLE]}
              validate={[validators.required("Type")]}
              value={payload.type}
              onChange={({ option }) => setPayload({ ...payload, type: option })}
            />
            {payload.type === CancellationType.PARTIAL_REFUNDABLE &&
              <>
                <Box direction='row' margin={{ vertical: "20px" }}>
                  <Text margin={{ right: "10px" }} style={{ fontWeight: "bold" }}>Refund Options</Text>
                  <Button
                    onClick={(event) => {
                      const data = [...hours, { refundAmount: undefined, hour: undefined }];
                      setHours(data);
                    }}
                  >
                    <FontAwesomeIcon size="lg" icon={faAdd} />
                  </Button>
                </Box>
                {hours.map((e, i) =>
                  <Box
                    key={i}
                    border="all"
                    pad="10px"
                    margin="5px"
                    style={{ borderRadius: "20px" }}
                  >
                    <FormTextInput
                      id={`refundInput${i}`}
                      label="Refund Percentage"
                      value={e.refundAmount}
                      type="number"
                      name={`refund${i}`}
                      validate={[
                        validators.required("Refund Percentage"),
                        validators.smallerOrEqual(100),
                        validators.biggerOrEqual(0),
                      ]}
                      onChange={(event) => {
                        const value = event.target.value;
                        if (value > 100) return;
                        if (value < 0) return;

                        const data = [...hours];
                        data[i].refundAmount = value;
                        setHours(data);
                      }}
                    />
                    <FormTextInput
                      id={`hourInput${i}`}
                      name={`hour${i}`}
                      value={e.hour}
                      label="Hours Before"
                      type="number"
                      validate={[
                        validators.required("Hour"),
                        validators.biggerOrEqual(0),
                      ]}
                      onChange={(event) => {
                        const value = event.target.value;
                        if (value < 0) return;

                        const data = [...hours];
                        data[i].hour = value;
                        setHours(data);
                      }}
                    />
                    {hours.length > 1 &&
                      <Button
                        margin={{ top: "10px" }}
                        justify='center'
                        alignSelf='center'
                        onClick={(event) => {
                          setHours(hours.filter((e, hourIndex) => i !== hourIndex));
                        }}
                      >
                        <FontAwesomeIcon size="xl" icon={faSubtract} />
                      </Button>
                    }
                  </Box>
                )}
              </>
            }
          </Box>
        </Form>
      </Box >
    </PrivatePageLayout >
  )
};

export default AddPolicy;
import React from 'react'
import { FormField, TextArea } from 'grommet'

export default function FormTextArea(props) {
    const { id, name, label, rows, placeholder, value, onChange, validate } = props;
    return (
        <FormField
            label={label}
            name={name}
            htmlFor={id}
            validate={validate}
        >
            <TextArea
                id={id}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                rows={rows}
            />
        </FormField>
    )
}
import { http } from '../../config/http';

export const getDefaultImages = async (): Promise<any> => {
  return http
    .get(`/transfers/default-images`)
    .then((res) => res.data);
}

export const removeDefaultImage = async (id: string): Promise<any> => {
  return http
    .delete(`/transfers/default-images/${id}`)
    .then((res) => res.data);
}

export const addDefaultImage = async (file: File): Promise<any> => {
  const formData = new FormData();
  formData.append("file", file);
  return http
    .post(`/transfers/default-images`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res.data);
}
import { Box, Text } from "grommet";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "src/components/utils/CustomButton";
import SelectLayerDataTable from "src/components/utils/SelectLayerDataTable";
import { RootState } from "src/store/store";
import { getCancellationPoliciesAction } from "src/store/transfer/transfer.action";
import { getAddTransfer, getPolicies, setAddTransferDto } from "src/store/transfer/transfer.slice";

export default function CancelPolicyTab() {
    const dispatch = useDispatch();
    const { payload } = useSelector((state: RootState) => getAddTransfer(state));
    const policies = useSelector((state: RootState) => getPolicies(state));
    const [page, setPage] = useState<number>();

    useEffect(() => {
        getPoliciesRequest();
    }, [page]);

    const getPoliciesRequest = () => {
        dispatch(getCancellationPoliciesAction(page, policies.meta.take));
    };

    const handleChange = ({ page }) => {
        setPage(page);
    };

    const [open, setOpen] = useState(false);
    const onOpen = () => setOpen(true);
    const onClose = () => setOpen(undefined);

    return (
        <Box pad="medium">
            <Box>
                <CustomButton label={payload.cancellationPolicy ? "Change" : "Select"} onClick={onOpen} color="dark-3" />
                <SelectLayerDataTable
                    isOpen={open}
                    title="Select Policy"
                    columns={[
                        {
                            property: 'id',
                            header: "Id",
                            primary: true,
                        },
                        {
                            property: 'name',
                            header: "Name"
                        },
                        {
                            property: 'type',
                            header: "Type"
                        },
                        {
                            property: 'description',
                            header: 'Description',
                        }
                    ]}
                    listData={policies}
                    onSelect={(data) => {
                        const policy = policies.data.find(e => e.id === data.id);
                        dispatch(setAddTransferDto({
                            ...payload,
                            cancellationPolicy: policy,
                            cancellationPolicyId: policy.id,
                        }));
                    }}
                    onClose={onClose}
                    page={page}
                    onPageChanged={handleChange}
                />
            </Box>
            {payload.cancellationPolicy ?
                <Box pad={{ top: "10px" }}>
                    <Text weight="bold" size='large'>{payload.cancellationPolicy.name}</Text>
                    <Text>{payload.cancellationPolicy.description}</Text>
                    <Text>{payload.cancellationPolicy.type}</Text>
                    {Object.entries(payload.cancellationPolicy.hours).map(([k, v]) => <Text key={k}>{`${k} hours before : ${Number(v) * 100}% refund`}</Text>)}
                </Box> : <Text margin={{ left: "10px" }} color="red">Policy is required</Text>
            }
        </Box>
    )
}
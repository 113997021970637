import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { ReservationState } from "./reservation.types";

const ReservationSliceInitialState: ReservationState = {
    reservations: {
        data: [],
        meta: {
            hasNextPage: false,
            hasPreviousPage: false,
            itemCount: 0,
            page: 1,
            pageCount: 0,
            take: 10,
        },
    },
    loading: false,
    details: {
        reservation: undefined,
        loading: false,
    }
};

export const reservationSlice: any = createSlice({
    name: "reservation",
    initialState: ReservationSliceInitialState,
    reducers: {
        setReservationsLoading: (state, action: PayloadAction<any>) => {
            state.loading = action.payload;
        },
        setReservations: (state, action: PayloadAction<any>) => {
            state.reservations = action.payload;
        },
        setReservationDetailsLoading: (state, action: PayloadAction<any>) => {
            state.details.loading = action.payload;
        },
        setReservationDetails: (state, action: PayloadAction<any>) => {
            state.details.reservation = action.payload;
        },
    },
});

export const {
    setReservationsLoading,
    setReservations,
    setReservationDetailsLoading,
    setReservationDetails,
} = reservationSlice.actions;

export const getReservations = (state: RootState) => state.reservation.reservations;
export const getReservationsLoading = (state: RootState) => state.reservation.loading;
export const getReservationDetailsLoading = (state: RootState) => state.reservation.details.loading;
export const getReservationDetails = (state: RootState) => state.reservation.details.reservation;

export default reservationSlice.reducer;

import { ResponsiveContext } from 'grommet';
import React, { FC, useContext } from 'react';
import { useTitle } from '../../../hooks/useTitle';
import Desktop from './Desktop';
import Mobile from './Mobile';

interface Props {
  title: string;
  changeTitle?: boolean;
}

const PrivatePageLayout: FC<Props> = ({
  children,
  title,
  changeTitle,
}) => {
  useTitle(`${title} - Westleyblue`, changeTitle);
  const size = useContext(ResponsiveContext);

  return size === 'small' ? (
    <Mobile>
      {children}
    </Mobile>
  ) : (
    <Desktop>
      {children}
    </Desktop>
  );
};

export default PrivatePageLayout;

import React from 'react';

import { Anchor, Avatar, Box, Button, Header, Menu, Nav, Text } from 'grommet';
import logo from "../../../assets/logo/wb_logo.webp";
import { User } from 'grommet-icons';
import { logoutAction } from 'src/store/auth/auth.action';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getUser } from 'src/store/auth/auth.slice';
import { RootState } from 'src/store/store';
import appHistory from 'src/helpers/history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';


export default function CustomHeader(props) {
    const {
        showLeftMenuButton,
        leftMenuButtonOnClick
    } = props;
    const history = useHistory();
    return (
        <Header background="light-3" height="75px" pad="small" border={{ color: "light-4", side: "all" }}>
            {showLeftMenuButton && <Button onClick={leftMenuButtonOnClick}><FontAwesomeIcon icon={faBars} /></Button>}
            <Logo />
            <Nav direction="row" align='center'>
                {/* <Anchor label="Home" onClick={() => {
                    history.push("/home");
                }} />
                <Anchor label="Profile" href="#" /> */}
                <UserAvatar />
            </Nav>
        </Header>
    )
}

function UserAvatar() {
    const user = useSelector((state: RootState) => getUser(state));
    const dispatch = useDispatch();
    return (
        <Menu
            icon={
                <Box direction='row' >
                    <Avatar size='40px' background="brand" margin={{ right: "5px" }}>
                        <User color="text-strong" />
                    </Avatar>
                    <Box direction='column' justify='center'>
                        <Text size='14px'>{`${user.firstName} ${user.lastName}`}</Text>
                        <Text size='12px' color="gray">{user.role}</Text>
                    </Box>
                </Box>
            }
            items={[
                {
                    label: 'Profile',
                    onClick: () => {
                        appHistory.push("/profile");
                    }
                },
                {
                    label: 'Logout',
                    onClick: () => {
                        dispatch(logoutAction());
                    }
                },
            ]}
        />
    )
}


function Logo() {
    const history = useHistory();
    return (
        <Button
            onClick={() => {
                history.push("/home");
            }}
        >
            <Box fill="horizontal" direction='row' align='center' justify="start">
                <Avatar
                    alignSelf="center"
                    size="medium"
                    round="0"
                    margin={{ right: "10px" }}
                    src={logo}
                />
                <Text size='24px'><b>Westleyblue</b></Text>
            </Box>

        </Button>
    )
}
import React, { FC, useState } from 'react';
import { Box, Form, FormField, Image, TextInput } from 'grommet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthLayout from '../../components/layouts/AuthLayout';
import { FormSubmitEvent } from '../../models/form-submit-event';
import { validators } from '../../helpers/validators';
import Figure from '../../assets/register-bg/figure.svg';
import Banner from '../../assets/register-bg/banner.svg';
import AuthFormButton from '../../components/auth/AuthFormButton';
import { RootState } from 'src/store/store';
import { RegisterPayload } from 'src/store/auth/auth.types';
import 'react-phone-input-2/lib/style.css'
import { registerAction } from 'src/store/auth/auth.action';
import { CustomPhoneInput } from 'src/components/utils/CustomPhoneInput/CustomPhoneInput';
import FormTextInput from 'src/components/utils/FormTextInput';
import FormPasswordInput from 'src/components/utils/FormPasswordInput';

const Register: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const {
    register: { loading },
  } = useSelector((state: RootState) => state.auth);

  // const isPhoneValid = (value, country) =>
  //   value !== country.dialCode
  //   && value.length > country.dialCode.length
  //   && value.slice(country.dialCode.length).length > 0
  //   && country.format
  //     .replaceAll("+", "")
  //     .replaceAll(" ", "")
  //     .replaceAll("(", "")
  //     .replaceAll(")", "")
  //     .replaceAll("-", "")
  //     .length === value.length;

  const handleSubmit: FormSubmitEvent<RegisterPayload> = ({ value }) => {
    if (!isPhoneValid) return;
    const body: RegisterPayload = {
      email: value.email,
      phone: phoneNumber.length > 0 ? phoneNumber : undefined,
      countryPhoneCode: phoneNumber.length > 0 ? countryCode : undefined,
      password: value.password,
      firstName: value.firstName,
      lastName: value.lastName,
    };
    dispatch(registerAction(body));
  };

  const [countryCode, setCountryCode] = useState<string>("90");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const handlePhoneNumberChange = (countryCode: string, phone: string, valid: boolean) => {
    setPhoneNumber(phone);
    setCountryCode(countryCode);
    setIsPhoneValid(valid);
  };

  return (
    <AuthLayout
      title={t('Register.title')}
      formTitle={t('Register.formTitle')}
      formSubTitle={t('Register.formSubTitle')}
      callToAction={{
        title: t('Register.alreadyHaveAccount'),
        body: t('common.signIn'),
        onClick: () => history.push('/login'),
      }}
      background={
        <>
          <Image
            width="60%"
            src={Banner}
            style={{
              position: 'absolute',
              pointerEvents: 'none',
              top: 0,
              left: 250,
            }}
          />
          <Image
            width="60%"
            alignSelf="center"
            style={{ zIndex: 1 }}
            src={Figure}
          />
        </>
      }
    >
      <>
        <Form onSubmit={handleSubmit}>
          <FormTextInput
            id="fiestNameInput"
            label={t('common.firstName')}
            name="firstName"
            htmlFor="firstNameInput"
            validate={validators.required(t('common.firstName'))}
          />
          <FormTextInput
            id="lastNameInput"
            label={t('common.lastName')}
            name="lastName"
            htmlFor="lastNameInput"
            validate={validators.required(t('common.lastName'))}
          />
          <FormTextInput
            id="emailInput"
            name="email"
            htmlFor="emailInput"
            label={t('common.email')}
            validate={[
              validators.required(t('common.email')),
              validators.email(),
            ]}
          />
          <CustomPhoneInput
            label={t('common.phone')}
            required={false}
            value={phoneNumber}
            countryCode={countryCode}
            onChange={handlePhoneNumberChange}
          />
          <FormPasswordInput
            id="passwordInput"
            label={t('common.password')}
            name="password"
            validate={[
              validators.required(t('common.password')),
              validators.minLength(t('common.password'), 6),
            ]}
          />
          <FormPasswordInput
            id="password1Input"
            label={t('common.confirmPassword')}
            name="password1"
            validate={[
              validators.required(t('common.confirmPassword')),
              validators.equalsField('password', t('common.passwords')),
            ]}
          />
          <AuthFormButton
            primary
            margin={{ top: 'medium' }}
            disabled={loading}
            type="submit"
            label={t('Register.signUp')}
          />
        </Form>
      </>
    </AuthLayout>
  );
};

export default Register;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { TransferState, UpdateImageAction, UpdateImageDto } from "./transfer.types";

const TransferSliceInitialState: TransferState = {
    transfers: {
        data: [],
        meta: {
            hasNextPage: false,
            hasPreviousPage: false,
            itemCount: 0,
            page: 1,
            pageCount: 0,
            take: 10,
        },
    },
    locations: undefined,
    policies: {
        data: [],
        meta: {
            hasNextPage: false,
            hasPreviousPage: false,
            itemCount: 0,
            page: 1,
            pageCount: 0,
            take: 10,
        },
    },
    companies: {
        data: [],
        meta: {
            hasNextPage: false,
            hasPreviousPage: false,
            itemCount: 0,
            page: 1,
            pageCount: 0,
            take: 10,
        },
    },
    loading: false,
    addTransfer: {
        payload: {
            companyId: undefined,
            company: undefined,
            name: undefined,
            description: undefined,
            status: undefined,
            brand: undefined,
            model: undefined,
            type: undefined,
            vehicle: undefined,
            capacity: undefined,
            price: undefined,
            currency: undefined,
            chauffeurMaxWaitTime: undefined,
            originId: undefined,
            origin: undefined,
            destinationId: undefined,
            destination: undefined,
            duration: undefined,
            cancellationPolicyId: undefined,
            cancellationPolicy: undefined,
            amenities: {
                wifi: false,
                chargeUnit: false,
                waterBottle: false,
                tv: false,
                fridge: false,
                coldDrinks: false,
                snacks: false,
            },
            luggageAllowance: {
                checkIn: 0,
                carryOn: 0,
            },
            images: [],
            instructions: undefined,
            taxAndVatInfo: undefined,
            pickupVideoTutLink: undefined,
            disclaimerMessages: [],
        },
        loading: false,
        images: [],
        activeTabIndex: 0,
        formStatuses: {
            transferDetailsTab: {
                updateFields: [],
                errorCount: 0,
            },
            vehicleDetailsTab: {
                updateFields: [],
                errorCount: 0,
            },
            imagesTab: {
                updateCount: 0,
                errorCount: 0,
            },
            amenitiesTab: {
                updateCount: 0,
                errorCount: 0,
            },
            detailsTab: {
                updateFields: [],
                errorCount: 0,
            },
            policyTab: {
                updateFields: [],
                errorCount: 0,
            },
            companyTab: {
                updateFields: [],
                errorCount: 0,
            }
        },
    },
    transferDetail: {
        transfer: undefined,
        loading: false,
        company: undefined,
        isEditMode: false,
        transferUpdate: {
            updateDto: {
                companyId: undefined,
                name: undefined,
                description: undefined,
                status: undefined,
                brand: undefined,
                model: undefined,
                type: undefined,
                vehicle: undefined,
                capacity: undefined,
                price: undefined,
                currency: undefined,
                chauffeurMaxWaitTime: undefined,
                originId: undefined,
                destinationId: undefined,
                duration: undefined,
                cancellationPolicyId: undefined,
                amenities: {
                    wifi: false,
                    chargeUnit: false,
                    waterBottle: false,
                    tv: false,
                    fridge: false,
                    coldDrinks: false,
                    snacks: false,
                },
                luggageAllowance: {
                    checkIn: 0,
                    carryOn: 0,
                },
                instructions: undefined,
                taxAndVatInfo: undefined,
                pickupVideoTutLink: undefined,
                disclaimerMessages: [],
            },
            selectedCompany: undefined,
            selectedPolicy: undefined,
            updateImages: [],
            activeTabIndex: 0,
            formStatuses: {
                transferDetailsTab: {
                    updateFields: [],
                    errorCount: 0,
                },
                vehicleDetailsTab: {
                    updateFields: [],
                    errorCount: 0,
                },
                imagesTab: {
                    updateCount: 0,
                    errorCount: 0,
                },
                amenitiesTab: {
                    updateCount: 0,
                    errorCount: 0,
                },
                detailsTab: {
                    updateFields: [],
                    errorCount: 0,
                },
                policyTab: {
                    updateFields: [],
                    errorCount: 0,
                },
                companyTab: {
                    updateFields: [],
                    errorCount: 0,
                }
            },
        },
    },
};

export const transferSlice: any = createSlice({
    name: "transfer",
    initialState: TransferSliceInitialState,
    reducers: {
        setTransfers: (state, action: PayloadAction<any>) => {
            state.transfers = action.payload;
            state.loading = false;
        },
        setTransfersLoading: (state, action: PayloadAction<any>) => {
            state.loading = action.payload;
        },
        setTransferDetail: (state, action: PayloadAction<any>) => {
            state.transferDetail.transfer = action.payload;
            state.transferDetail.loading = false;
            const index = state.transfers.data.findIndex(e => e.id === action.payload.id);
            if (index != -1) {
                state.transfers.data[index] = action.payload;
            }
        },
        setTransferDetailLoading: (state, action: PayloadAction<any>) => {
            state.transferDetail.loading = action.payload;
        },
        setTransferDetailCompany: (state, action: PayloadAction<any>) => {
            state.transferDetail.company = action.payload;
        },
        setTransferDetailEditMode: (state, action: PayloadAction<any>) => {
            state.transferDetail.isEditMode = action.payload;
        },
        setTransferDetailLocations: (state, action: PayloadAction<any>) => {
            state.locations = action.payload;
        },
        setTransferDetailPolicies: (state, action: PayloadAction<any>) => {
            state.policies = action.payload;
        },
        setTransferDetailCompanies: (state, action: PayloadAction<any>) => {
            state.companies = action.payload;
        },
        setTransferUpdateDto: (state, action: PayloadAction<any>) => {
            state.transferDetail.transferUpdate.updateDto = action.payload;
        },
        setTransferUpdateCompany: (state, action: PayloadAction<any>) => {
            state.transferDetail.transferUpdate.selectedCompany = action.payload;
        },
        setTransferUpdatePolicy: (state, action: PayloadAction<any>) => {
            state.transferDetail.transferUpdate.selectedPolicy = action.payload;
        },
        resetTransferUpdateDto: (state, action: PayloadAction<any>) => {
            state.transferDetail.transferUpdate.updateDto = {
                companyId: state.transferDetail.transfer.companyId,
                name: state.transferDetail.transfer.name ?? "",
                description: state.transferDetail.transfer.description ?? "",
                status: state.transferDetail.transfer.status,
                brand: state.transferDetail.transfer.brand ?? "",
                model: state.transferDetail.transfer.model ?? "",
                type: state.transferDetail.transfer.type,
                vehicle: state.transferDetail.transfer.vehicle,
                capacity: state.transferDetail.transfer.capacity,
                price: state.transferDetail.transfer.price,
                currency: state.transferDetail.transfer.currency,
                chauffeurMaxWaitTime: state.transferDetail.transfer.chauffeurMaxWaitTime ?? 0,
                originId: state.transferDetail.transfer.originId,
                destinationId: state.transferDetail.transfer.destinationId,
                duration: state.transferDetail.transfer.duration ?? 0,
                cancellationPolicyId: state.transferDetail.transfer.cancellationPolicyId,
                amenities: state.transferDetail.transfer.amenities,
                luggageAllowance: state.transferDetail.transfer.luggageAllowance,
                instructions: state.transferDetail.transfer.instructions ?? "",
                taxAndVatInfo: state.transferDetail.transfer.taxAndVatInfo ?? "",
                pickupVideoTutLink: state.transferDetail.transfer.pickupVideoTutLink ?? "",
                disclaimerMessages: state.transferDetail.transfer.disclaimerMessages ?? [],
            };

            state.transferDetail.transferUpdate.formStatuses = {
                transferDetailsTab: {
                    updateFields: [],
                    errorCount: 0,
                },
                vehicleDetailsTab: {
                    updateFields: [],
                    errorCount: 0,
                },
                imagesTab: {
                    updateCount: 0,
                    errorCount: 0,
                },
                amenitiesTab: {
                    updateCount: 0,
                    errorCount: 0,
                },
                detailsTab: {
                    updateFields: [],
                    errorCount: 0,
                },
                policyTab: {
                    updateFields: [],
                    errorCount: 0,
                },
                companyTab: {
                    updateFields: [],
                    errorCount: 0,
                }
            };

            state.transferDetail.transferUpdate.updateImages = state.transferDetail.transfer.images.map(e => {
                const updateImage: UpdateImageDto = {
                    ...e,
                    action: UpdateImageAction.NOTHING,
                };
                return updateImage;
            });
            state.transferDetail.transferUpdate.selectedCompany = undefined;
            state.transferDetail.transferUpdate.selectedPolicy = undefined;
        },
        addTransferDetailsTabUpdateField: (state, action: PayloadAction<any>) => {
            const updateFields = state.transferDetail.transferUpdate.formStatuses.transferDetailsTab.updateFields;
            if (!updateFields.includes(action.payload)) {
                updateFields.push(action.payload);
                state.transferDetail.transferUpdate.formStatuses.transferDetailsTab.updateFields = updateFields;
            }
        },
        removeTransferDetailsTabUpdateField: (state, action: PayloadAction<any>) => {
            const updateFields = state.transferDetail.transferUpdate.formStatuses.transferDetailsTab.updateFields;
            if (updateFields.includes(action.payload)) {
                const newFields = updateFields.filter(e => e !== action.payload);
                state.transferDetail.transferUpdate.formStatuses.transferDetailsTab.updateFields = newFields;
            }
        },
        addVehicleDetailsTabUpdateField: (state, action: PayloadAction<any>) => {
            const updateFields = state.transferDetail.transferUpdate.formStatuses.vehicleDetailsTab.updateFields;
            if (!updateFields.includes(action.payload)) {
                updateFields.push(action.payload);
                state.transferDetail.transferUpdate.formStatuses.vehicleDetailsTab.updateFields = updateFields;
            }
        },
        removeVehicleDetailsTabUpdateField: (state, action: PayloadAction<any>) => {
            const updateFields = state.transferDetail.transferUpdate.formStatuses.vehicleDetailsTab.updateFields;
            if (updateFields.includes(action.payload)) {
                const newFields = updateFields.filter(e => e !== action.payload);
                state.transferDetail.transferUpdate.formStatuses.vehicleDetailsTab.updateFields = newFields;
            }
        },
        addDetailsTabUpdateField: (state, action: PayloadAction<any>) => {
            const updateFields = state.transferDetail.transferUpdate.formStatuses.detailsTab.updateFields;
            if (!updateFields.includes(action.payload)) {
                updateFields.push(action.payload);
                state.transferDetail.transferUpdate.formStatuses.detailsTab.updateFields = updateFields;
            }
        },
        removeDetailsTabUpdateField: (state, action: PayloadAction<any>) => {
            const updateFields = state.transferDetail.transferUpdate.formStatuses.detailsTab.updateFields;
            if (updateFields.includes(action.payload)) {
                const newFields = updateFields.filter(e => e !== action.payload);
                state.transferDetail.transferUpdate.formStatuses.detailsTab.updateFields = newFields;
            }
        },
        setTransferDetailsFormErrorCount: (state, action: PayloadAction<any>) => {
            if (state.transferDetail.transferUpdate.activeTabIndex === 0) {
                const transferTabFields = ["name", "description", "status", "duration", "price", "currency", "type", "chauffeurMaxWaitTime", "origin", "destination"];
                let transferTabUpdateCount = 0;
                for (const key of action.payload) {
                    if (transferTabFields.includes(key)) transferTabUpdateCount++;
                }
                state.transferDetail.transferUpdate.formStatuses.transferDetailsTab.errorCount = transferTabUpdateCount;
            } else if (state.transferDetail.transferUpdate.activeTabIndex === 1) {
                const vehicleTabFields = ["brand", "model", "vehicle", "capacity", "luggageCarryOn", "luggageCheckIn"];
                let vehicleTabUpdateCount = 0;
                for (const key of action.payload) {
                    if (vehicleTabFields.includes(key)) vehicleTabUpdateCount++;
                }
                state.transferDetail.transferUpdate.formStatuses.vehicleDetailsTab.errorCount = vehicleTabUpdateCount;
            } else if (state.transferDetail.transferUpdate.activeTabIndex === 6) {
                const detailsTabFields = ["instructions", "taxAndVat", "videoTutLink", "disclaimerMessages"];
                let detailsTabUpdateCount = 0;
                for (const key of action.payload) {
                    if (detailsTabFields.includes(key)) detailsTabUpdateCount++;
                }
                state.transferDetail.transferUpdate.formStatuses.detailsTab.errorCount = detailsTabUpdateCount;
            }
        },
        setAddTransferFormErrorCount: (state, action: PayloadAction<any>) => {
            if (state.addTransfer.activeTabIndex === 0) {
                const transferTabFields = ["name", "description", "duration", "price", "currency", "type", "chauffeurMaxWaitTime", "origin", "destination"];
                let transferTabUpdateCount = 0;
                for (const key of action.payload) {
                    if (transferTabFields.includes(key)) transferTabUpdateCount++;
                }
                state.addTransfer.formStatuses.transferDetailsTab.errorCount = transferTabUpdateCount;
            } else if (state.addTransfer.activeTabIndex === 1) {
                const vehicleTabFields = ["brand", "model", "vehicle", "capacity", "luggageCarryOn", "luggageCheckIn"];
                let vehicleTabUpdateCount = 0;
                for (const key of action.payload) {
                    if (vehicleTabFields.includes(key)) vehicleTabUpdateCount++;
                }
                state.addTransfer.formStatuses.vehicleDetailsTab.errorCount = vehicleTabUpdateCount;
            } else if (state.addTransfer.activeTabIndex === 6) {
                const detailsTabFields = ["instructions", "taxAndVat", "videoTutLink", "disclaimerMessages"];
                let detailsTabUpdateCount = 0;
                for (const key of action.payload) {
                    if (detailsTabFields.includes(key)) detailsTabUpdateCount++;
                }
                state.addTransfer.formStatuses.detailsTab.errorCount = detailsTabUpdateCount;
            }
            state.addTransfer.formStatuses.imagesTab.errorCount = state.addTransfer.images.length > 0 ? 0 : 1;
        },
        setTransferDetailAmenitiesFormUpdateCount: (state, action: PayloadAction<any>) => {
            let updateCount = 0;
            Object.keys(state.transferDetail.transferUpdate.updateDto.amenities).forEach((key) => {
                if (state.transferDetail.transferUpdate.updateDto.amenities[key] !== state.transferDetail.transfer.amenities[key])
                    updateCount++;
            });
            state.transferDetail.transferUpdate.formStatuses.amenitiesTab.updateCount = updateCount;
        },
        setActiveTabIndex: (state, action: PayloadAction<any>) => {
            state.transferDetail.transferUpdate.activeTabIndex = action.payload;
        },
        setUploadImageList: (state, action: PayloadAction<any>) => {
            state.transferDetail.transferUpdate.updateImages = action.payload;

            const updateCount = state.transferDetail.transferUpdate.updateImages.filter(e => e.action !== UpdateImageAction.NOTHING).length;
            state.transferDetail.transferUpdate.formStatuses.imagesTab.updateCount = updateCount;

            const existCount = state.transferDetail.transferUpdate.updateImages.filter(e => e.action === UpdateImageAction.NOTHING || e.action === UpdateImageAction.REMOVE).length;
            const removeCount = state.transferDetail.transferUpdate.updateImages.filter(e => e.action === UpdateImageAction.REMOVE).length;
            const addDefaultCount = state.transferDetail.transferUpdate.updateImages.filter(e => e.action === UpdateImageAction.ADD_DEFAULT).length;
            const addFileCount = state.transferDetail.transferUpdate.updateImages.filter(e => e.action === UpdateImageAction.ADD_FILE).length;
            const count = existCount - removeCount + addDefaultCount + addFileCount;
            state.transferDetail.transferUpdate.formStatuses.imagesTab.errorCount = count < 1 ? 1 : 0;

        },
        setAddTransferTabIndex: (state, action: PayloadAction<any>) => {
            state.addTransfer.activeTabIndex = action.payload;
        },
        setAddTransferDto: (state, action: PayloadAction<any>) => {
            state.addTransfer.payload = action.payload;
        },
        setAddTransferLoading: (state, action: PayloadAction<any>) => {
            state.addTransfer.loading = action.payload;
        },
        setAddTransferImageList: (state, action: PayloadAction<any>) => {
            state.addTransfer.images = action.payload;
        },
        resetAddTransferDto: (state, action: PayloadAction<any>) => {
            state.addTransfer.payload = {
                companyId: undefined,
                company: undefined,
                name: undefined,
                description: undefined,
                status: undefined,
                brand: undefined,
                model: undefined,
                type: undefined,
                vehicle: undefined,
                capacity: undefined,
                price: undefined,
                currency: undefined,
                chauffeurMaxWaitTime: undefined,
                originId: undefined,
                origin: undefined,
                destinationId: undefined,
                destination: undefined,
                duration: undefined,
                cancellationPolicyId: undefined,
                cancellationPolicy: undefined,
                amenities: {
                    wifi: false,
                    chargeUnit: false,
                    waterBottle: false,
                    tv: false,
                    fridge: false,
                    coldDrinks: false,
                    snacks: false,
                },
                luggageAllowance: {
                    checkIn: 0,
                    carryOn: 0,
                },
                images: [],
                instructions: undefined,
                taxAndVatInfo: undefined,
                pickupVideoTutLink: undefined,
                disclaimerMessages: [],
            };

            state.addTransfer.formStatuses = {
                transferDetailsTab: {
                    updateFields: [],
                    errorCount: 0,
                },
                vehicleDetailsTab: {
                    updateFields: [],
                    errorCount: 0,
                },
                imagesTab: {
                    updateCount: 0,
                    errorCount: 0,
                },
                amenitiesTab: {
                    updateCount: 0,
                    errorCount: 0,
                },
                detailsTab: {
                    updateFields: [],
                    errorCount: 0,
                },
                policyTab: {
                    updateFields: [],
                    errorCount: 0,
                },
                companyTab: {
                    updateFields: [],
                    errorCount: 0,
                }
            };

            state.addTransfer.images = [];
        },
    },
});

export const {
    setTransfers,
    setTransfersLoading,
    setTransferDetail,
    setTransferDetailLoading,
    setTransferDetailCompany,
    setTransferDetailEditMode,
    setTransferDetailLocations,
    setTransferDetailPolicies,
    setTransferDetailCompanies,
    setTransferUpdateDto,
    setTransferUpdateCompany,
    setTransferUpdatePolicy,
    resetTransferUpdateDto,
    addTransferDetailsTabUpdateField,
    removeTransferDetailsTabUpdateField,
    setTransferDetailsFormErrorCount,
    addVehicleDetailsTabUpdateField,
    removeVehicleDetailsTabUpdateField,
    addDetailsTabUpdateField,
    removeDetailsTabUpdateField,
    setActiveTabIndex,
    setUploadImageList,
    setTransferDetailAmenitiesFormUpdateCount,
    setAddTransferDto,
    setAddTransferTabIndex,
    setAddTransferImageList,
    setAddTransferFormErrorCount,
    setAddTransferLoading,
    resetAddTransferDto,
} = transferSlice.actions;

export const getTransfers = (state: RootState) => state.transfer.transfers;
export const getTransfersLoading = (state: RootState) => state.transfer.loading;
export const getTransferDetail = (state: RootState) => state.transfer.transferDetail;
export const getTransferDetailFormStatuses = (state: RootState) => state.transfer.transferDetail.transferUpdate.formStatuses;
export const getAddTransfer = (state: RootState) => state.transfer.addTransfer;
export const getLocations = (state: RootState) => state.transfer.locations;
export const getCompanies = (state: RootState) => state.transfer.companies;
export const getPolicies = (state: RootState) => state.transfer.policies;
export const getTransferUpdateErrorCount = (state: RootState) =>
    state.transfer.transferDetail.transferUpdate.formStatuses.transferDetailsTab.errorCount +
    state.transfer.transferDetail.transferUpdate.formStatuses.vehicleDetailsTab.errorCount +
    state.transfer.transferDetail.transferUpdate.formStatuses.detailsTab.errorCount +
    state.transfer.transferDetail.transferUpdate.formStatuses.imagesTab.errorCount;
export const getAddTransferErrorCount = (state: RootState) =>
    state.transfer.addTransfer.formStatuses.transferDetailsTab.errorCount +
    state.transfer.addTransfer.formStatuses.vehicleDetailsTab.errorCount +
    state.transfer.addTransfer.formStatuses.detailsTab.errorCount +
    state.transfer.addTransfer.formStatuses.imagesTab.errorCount;

export default transferSlice.reducer;

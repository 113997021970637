import { PageMetaDto } from "src/models/page-meta-data";
import { CurrencyType, Transfer } from "../transfer/transfer.types";
import { User } from "../user/user.types";


export interface ReservationState {
  reservations: {
    data: Array<Reservation>,
    meta: PageMetaDto,
  },
  loading: boolean,
  details: {
    reservation: Reservation,
    loading: boolean,
  }
}

export interface ReservationRowType {
  code: number;
  transferId: number;
  price: number;
  currency: CurrencyType;
  date: Date;
  fromAddress: string;
  toAddress: string;
  status: ReservationStatus;
}


export enum ReservationStatus {
  NEW = 'NEW',
  BOOKED = 'BOOKED',
  DONE = 'DONE',
}

export interface FlightDetails {
  carrierCode: string;
  no: string;
  originAirportCode: string;
  destinationAirportCode: string;
  arrivalLocalTime: Date;
  departureLocalTime: Date;
}

export interface Reservation {
  id: number;
  code: string;
  transferId: number;
  adultCount: number;
  childCount: number;
  infantCount: number;
  babySeatCount: number;
  basePrice: number;
  price: number;
  currency: CurrencyType;
  pickupDate: Date;
  fromAddress: string;
  toAddress: string;
  notes: string;
  otherPersonMadeBooking: OtherPersonMadeBooking;
  passenger: Passenger;
  otherPax: Passenger[];
  flightDetails: FlightDetails;
  status: ReservationStatus;
  userId: number;
  createdAt: Date;
  deletedAt?: Date;

  transfer?: Transfer;
  user?: User;
  history?: TransferReservationHistory[];
  paxUsers?: ReservationUser[];
}

export interface OtherPersonMadeBooking {
  name: string;
  countryPhoneCode: string;
  phone: string;
  email: string;
}

export class Passenger {
  firstName: string;
  lastName: string;
  countryPhoneCode: string;
  phone: string;
  email: string;
  passportNumber: string;
}

export interface TransferReservationHistory {
  id: number;
  reservationId: number;
  reservation?: Reservation;
  userId: number;
  user?: User;
  action: ReservationUpdateAction;
  data: any;
  createdAt: Date;
}

export enum ReservationUpdateAction {
  STATUS = 'STATUS',
  COMPANY = 'COMPANY',
}

export interface ReservationUser {
  id: number;
  userId: number;
  user?: User;
  reservationId: number;
  reservation?: Reservation;
}

export interface CreateReservationPayload {
  transferId: number;
  adultCount: number;
  childCount: number;
  infantCount: number;
  babySeatCount: number;
  pickupDate: Date;
  fromAddress: string;
  toAddress: string;
  notes: string;
  otherPersonMadeBooking?: OtherPersonMadeBooking;
  passenger: Passenger;
  otherPax: Passenger[];
  flightDetails: FlightDetails;
}
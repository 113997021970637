import * as UserService from "src/store/user/user.service";
import {
  setUsers,
  setUsersLoading,
} from "./user.slice";
import { UpdateProfilePayload } from "./user.types";
import { setUser } from "../auth/auth.slice";

export const searchUsersAction = (page?: number, take?: number) => {
  return async (dispatch) => {
    try {
      dispatch(setUsersLoading(true));
      const payload = await UserService.getUsers(page, take);
      dispatch(setUsers(payload));
      dispatch(setUsersLoading(false));
    } catch (err: any) {
      dispatch(setUsersLoading(false));
    }
  };
};

export const updateProfileInfoAction = (body: UpdateProfilePayload) => {
  return async (dispatch) => {
    try {
      // dispatch(setUsersLoading(true));
      const payload = await UserService.updateProfileInfo(body);
      dispatch(setUser(payload));
      // dispatch(setUsersLoading(false));
    } catch (err: any) {
      // dispatch(setUsersLoading(false));
    }
  };
};
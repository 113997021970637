import React, { FC, useEffect } from 'react';
import {
  Box,
  Grid,
  Layer,
  ThemeContext,
  Text,
  FileInput,
} from 'grommet';
import { theme } from 'src/config/app-config';
import { useDispatch, useSelector } from 'react-redux';
import { getImages, getImagesLoading } from 'src/store/default-images/images.slice';
import { getDefaultImagesAction } from 'src/store/default-images/images.action';
import Loading from 'src/components/utils/Loading';
import GridImage from './GridImage';
import { Close } from 'grommet-icons';
import { fileToBase64String } from 'src/helpers/utils';

const SelectDefaultImage: FC<any> = (props) => {
  const { onImageSelect, onClose, onFileSelect } = props;

  const dispatch = useDispatch();
  const images = useSelector((state) => getImages(state));
  const loading = useSelector((state) => getImagesLoading(state));

  useEffect(() => {
    if (images.length === 0) {
      dispatch(getDefaultImagesAction());
    }
  }, []);

  return (
    <ThemeContext.Extend
      value={{
        ...theme,
      }}
    >
      <Layer responsive={true}>
        <Box elevation="peach" round="20px">
          <Box pad="small" justify="between" direction="row">
            <Text size="small">Select Image</Text>
            <Close color="brand" size="18px" onClick={onClose} />
          </Box>
          <Box
            margin="small"
            width="1000px"
            height="500px"
            border="all"
            round="8px"
          >
            {loading ?
              <Loading /> :
              <Grid
                columns={{ count: "fill", size: "small" }}
                gap="4px"
                margin="10px"
              >
                {
                  images.map(e => {
                    return <GridImage
                      key={e.id}
                      image={e}
                      isEditMode={false}
                      fit="cover"
                      onClick={() => onImageSelect(e)}
                    />
                  })
                }
              </Grid>
            }
          </Box>
          <Box justify="center" align="center">
            <Text size="xlarge" weight="bold">OR</Text>
          </Box>
          <Box pad="small">
            <FileInput
              messages={{ browse: "Browse" }}
              name="image"
              id="image"
              multiple={false}
              type="file"
              accept="image/jpg,
                        image/jpeg,
                        image/png,
                        image/bmp,
                        image/svg+xml"
              onChange={async (file) => {
                if (file.target.files && file.target.files.length > 0) {
                  const base64String = await fileToBase64String(file.target.files[0]);
                  onFileSelect(file.target.files[0], base64String);
                }
              }}
            />
          </Box>
        </Box>
      </Layer>
    </ThemeContext.Extend>
  );
};

export default SelectDefaultImage;

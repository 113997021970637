import React, { FC } from 'react'
import { faAdd, faSubtract } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Text } from 'grommet';
import FormTextArea from 'src/components/utils/FormTextArea';

interface DisclaimerMessagesProps {
    messages: string[];
    messagesOnAdd: (event) => void;
    messagesOnRemove: (i: number, event) => void;
    messagesOnChange: (i: number, event) => void;
}

export const DisclaimerMessages: FC<DisclaimerMessagesProps> = (props) => {
    const {
        messages,
        messagesOnAdd,
        messagesOnRemove,
        messagesOnChange,
    } = props;

    return (
        <>
            <Box direction='row' margin={{ vertical: "20px" }}>
                <Text margin={{ right: "10px" }} style={{ fontWeight: "bold" }}>Disclaimer Messages</Text>
                <Button
                    onClick={messagesOnAdd}
                >
                    <FontAwesomeIcon size="lg" icon={faAdd} />
                </Button>
            </Box>
            {messages.map((e, i) =>
                <Box
                    key={i}
                    border="all"
                    pad="10px"
                    margin="5px"
                    style={{ borderRadius: "20px" }}
                >
                    <FormTextArea
                        id={`disclaimerMessagesInput`}
                        label={`Disclaimer Messages ${i + 1}`}
                        value={e}
                        name={`disclaimerMessages`}
                        row={3}
                        onChange={(event) => {
                            messagesOnChange(i, event);
                        }}
                    />
                    {messages.length > 1 &&
                        <Button
                            margin={{ top: "10px" }}
                            justify='center'
                            alignSelf='center'
                            onClick={(event) => {
                                messagesOnRemove(i, event);
                            }}
                        >
                            <FontAwesomeIcon size="xl" icon={faSubtract} />
                        </Button>
                    }
                </Box>
            )}
        </>
    );
}

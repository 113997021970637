import { Box, Heading, DataTable, Pagination, Layer } from "grommet";
import CustomButton from "./CustomButton";

export default function SelectLayerDataTable(props) {
    const { isOpen, title, listData, columns, onSelect, onClose, page, onPageChanged } = props;
    if (isOpen) {
        return <Layer
            position="center"
            onClickOutside={onClose}
            onEsc={onClose}
        >
            <Box pad="medium" gap="small" width="xlarge">
                <Box direction="row" width="xlarge" justify="between">
                    <Heading level={3} margin="none">
                        {title}
                    </Heading>
                    <CustomButton primary maxWidth="100px" onClick={onClose}>Close</CustomButton>
                </Box>
                <DataTable
                    columns={columns}
                    data={listData.data}
                    resizeable={true}
                    step={listData.meta.take}
                    onClickRow={(event) => {
                        onSelect(event.datum);
                        onClose();
                    }}
                />
            </Box>
            <Box direction='row' align="center" margin="20px" justify="end">
                <Pagination numberItems={listData.meta.itemCount} page={page} onChange={onPageChanged} />
            </Box>
        </Layer>
    }
    return null;
}
import { AnchorProps, FormField } from 'grommet';
import React, { useState } from 'react';
import { LinkProps } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import "./CustomPhoneInput.scss"

interface CustomPhoneInputProps {
  name?: string;
  label?: string;
  required: boolean;
  value: string;
  countryCode: string;
  width?: string;
  disabled?: boolean;
  onChange: (countryCode: string, phone: string, valid: boolean) => void;
}

export const CustomPhoneInput: React.FC<CustomPhoneInputProps> = (props) => {
  const [valid, setValid] = useState<boolean>(props.value.length > 0 ? true : props.required ? false : true);
  function checkValid(val: string, country: any) {
    if (val.length === country.dialCode.length) {
      if (props.required) return false;
      else return true;
    } else {
      const countryPhoneBaseFormat = country.format
        .replaceAll("+", "")
        .replaceAll(" ", "")
        .replaceAll("(", "")
        .replaceAll(")", "")
        .replaceAll("-", "");
      return countryPhoneBaseFormat.length === val.length;
    }
  }
  return (
    <FormField
      name={props.name}
      className={!valid ? "form-field-error" : ""}
      label={props.label}
      width={props.width}
    >
      <PhoneInput
        disabled={props.disabled}
        isValid={valid}
        country={'tr'}
        value={props.countryCode + props.value}
        onChange={(val: string, country: any) => {
          const isValid = checkValid(val, country);
          const phone = val.slice(country.dialCode.length);
          setValid(isValid);
          props.onChange(country.dialCode, phone, isValid);
        }}
        countryCodeEditable={false}
        enableSearch
        disableSearchIcon
      />
    </FormField>

  );
};

export type AnchorLinkProps = LinkProps &
  AnchorProps &
  Omit<JSX.IntrinsicElements['a'], 'color'>;

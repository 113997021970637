import { Card, Tabs, Tab, Box, Text } from "grommet";
import TransfersDetailsTab from "./TransferDetailsTab";
import VehicleDetailsTab from "./VehicleDetailsTab";
import CompanyTab from "./CompanyTab";
import ImagesTab from "./ImagesTab";
import AmenitiesTab from "./AmenitiesTab";
import DetailsTab from "./DetailsTab";
import CancelPolicyTab from "./CancelPolicyTab";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store/store";
import { getAddTransfer, setAddTransferTabIndex } from "src/store/transfer/transfer.slice";

export default function AddTransfersTabs() {
    const dispatch = useDispatch();

    const { payload, formStatuses } = useSelector((state: RootState) => getAddTransfer(state));

    const {
        transferDetailsTab,
        vehicleDetailsTab,
        detailsTab,
        imagesTab,
    } = formStatuses;

    function changeActiveTabIndex(index: number) {
        dispatch(setAddTransferTabIndex(index));
    }

    return (
        <Card
            align='start'
            justify='start'
            width="100%"
            pad="small"
            gap="medium"
            background="light-2"
        >
            <Tabs justify='start' style={{ width: "100%" }}>
                <Tab
                    title={
                        transferDetailsTab.errorCount ?
                            <Box direction="row">
                                {
                                    transferDetailsTab.errorCount > 0 &&
                                    <Text
                                        size="11px"
                                        color="red"
                                        margin={{ right: "5px" }}
                                    >
                                        {`(${transferDetailsTab.errorCount})`}
                                    </Text>}
                            </Box>
                            : null
                    }
                    icon={
                        <Text>Transfer</Text>
                    }
                    onClick={(event) => {
                        changeActiveTabIndex(0);
                    }}
                >
                    <TransfersDetailsTab />
                </Tab>
                <Tab
                    title={vehicleDetailsTab.errorCount ?
                        <Box direction="row">
                            {
                                vehicleDetailsTab.errorCount > 0 &&
                                <Text
                                    size="11px"
                                    color="red"
                                    margin={{ right: "5px" }}
                                >
                                    {`(${vehicleDetailsTab.errorCount})`}
                                </Text>}
                        </Box>
                        : null
                    }
                    icon={
                        <Text>Vehicle</Text>
                    }
                    onClick={(event) => {
                        changeActiveTabIndex(1);
                    }}
                >
                    <VehicleDetailsTab />
                </Tab>
                <Tab
                    title={
                        imagesTab.errorCount ?
                            <Box direction="row">
                                {
                                    imagesTab.errorCount > 0 &&
                                    <Text
                                        size="11px"
                                        color="red">
                                        {`(${imagesTab.errorCount})`}
                                    </Text>}
                            </Box>
                            : null
                    }
                    icon={
                        <Text>Images</Text>
                    }
                    onClick={(event) => {
                        changeActiveTabIndex(2);
                    }}
                >
                    <ImagesTab />
                </Tab>
                <Tab
                    title="Amenities"
                    onClick={(event) => {
                        changeActiveTabIndex(3);
                    }}
                >
                    <AmenitiesTab />
                </Tab>
                <Tab
                    title={
                        !payload?.cancellationPolicy ?
                            <Box direction="row">
                                <Text
                                    size="11px"
                                    color="red">{"(1)"}</Text>
                            </Box>
                            : null
                    }
                    icon={
                        <Text>Policy</Text>
                    }
                    onClick={(event) => {
                        changeActiveTabIndex(4);
                    }}
                >
                    <CancelPolicyTab />
                </Tab>
                <Tab
                    title={
                        !payload?.company ?
                            <Box direction="row">
                                <Text
                                    size="11px"
                                    color="red">{"(1)"}</Text>
                            </Box>
                            : null
                    }
                    icon={
                        <Text>Company</Text>
                    }
                    onClick={(event) => {
                        changeActiveTabIndex(5);
                    }}
                >
                    <CompanyTab />
                </Tab>
                <Tab
                    title={detailsTab.errorCount ?
                        <Box direction="row">
                            {
                                detailsTab.errorCount > 0 &&
                                <Text
                                    size="11px"
                                    color="red"
                                    margin={{ right: "5px" }}
                                >
                                    {`(${detailsTab.errorCount})`}
                                </Text>}
                        </Box>
                        : null
                    }
                    icon={
                        <Text>Details</Text>
                    }
                    onClick={(event) => {
                        changeActiveTabIndex(6);
                    }}
                >
                    <DetailsTab />
                </Tab>
            </Tabs>
        </Card >
    )
}

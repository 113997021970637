import { combineReducers, configureStore, Reducer } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import AuthSlice from "./auth/auth.slice";
import { AuthState } from "./auth/auth.types";
import TransferSlice from "./transfer/transfer.slice";
import CompanySlice from "./company/company.slice";
import LocationSlice from "./location/location.slice";
import utilSlice from "./util/util.slice";
import { UtilState } from "./util/util.types";
import logger from "redux-logger";
import ImageSlice from "./default-images/images.slice";
import { DefaultImagesState } from "./default-images/images.types";
import { TransferState } from "./transfer/transfer.types";
import { CompanyState } from "./company/company.types";
import { LocationState } from "./location/location.types";
import PolicySlice from "./policy/policy.slice";
import { PolicyState } from "./policy/policy.types";
import UserSlice from "./user/user.slice";
import { UserState } from "./user/user.types";
import ReservationSlice from "./reservation/reservation.slice";
import { ReservationState } from "./reservation/reservation.types";

const rootReducer = combineReducers({
    auth: AuthSlice as Reducer<AuthState>,
    util: utilSlice as Reducer<UtilState>,
    transfer: TransferSlice as Reducer<TransferState>,
    company: CompanySlice as Reducer<CompanyState>,
    location: LocationSlice as Reducer<LocationState>,
    policy: PolicySlice as Reducer<PolicyState>,
    user: UserSlice as Reducer<UserState>,
    reservation: ReservationSlice as Reducer<ReservationState>,
    defaultImages: ImageSlice as Reducer<DefaultImagesState>,
});

const persistConfig = {
    key: "root",
    version: 1,
    storage,
    whitelist: ['auth'], //Things u want to persist
    blacklist: ["util"], //Things u dont
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: (state, action) => persistedReducer(state, action),
    middleware: (getDefaultMiddleware) => {
        const defaultMiddleware = getDefaultMiddleware({
            serializableCheck: false,
        });
        return defaultMiddleware.concat(logger);
    },
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;

import { Button, Box, Image, Layer } from "grommet";
import { Close } from "grommet-icons";
import { useState } from "react";
import ExtendedBox from "src/components/utils/ExtendedBox";

export default function GridImage(props) {
    const [imagePreview, setImagePreview] = useState<boolean>(false);
    const {
        image,
        fit,
        isEditMode,
        onRemove,
        onClick,
    } = props;
    return (
        <Box
            height="125px"
            width="210px"
            style={{ position: "relative" }}>
            <Box
                key={image.id}
                justify="center"
                align="center"
                height="125px"
                width="210px"
                border="all"
                round="8px"
                onClick={() => {
                    if (onClick) onClick();
                    else {
                        console.log("On Image Clicked");
                        setImagePreview(true);
                    }
                }}>

                <Image
                    width="100%"
                    height="100%"
                    style={{ borderRadius: "8px" }}
                    fit={fit ?? "contain"}
                    src={image.url}
                />
                {imagePreview &&
                    <Layer
                        position="center"
                        onClickOutside={() => {
                            setImagePreview(false);
                        }}
                        onEsc={() => {
                            setImagePreview(false);
                        }}
                    >
                        <Box gap="small" width="xlarge">
                            <Image width="xlarge" fit='contain' src={image.url} />
                        </Box>
                    </Layer>
                }
            </Box>
            {
                isEditMode &&
                <ExtendedBox
                    position="absolute"
                    top="6px"
                    right='20px'
                >
                    <Button margin="0px" pad="0px" onClick={onRemove}>
                        <Close />
                    </Button>
                </ExtendedBox>
            }
        </Box>
    )
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { LocationState } from "./location.types";

const LocationSliceInitialState: LocationState = {
    locations: [],
    loading: false,
    details: {
        location: undefined,
        loading: false,
    }
};

export const locationSlice: any = createSlice({
    name: "location",
    initialState: LocationSliceInitialState,
    reducers: {
        setLocationsLoading: (state, action: PayloadAction<any>) => {
            state.loading = action.payload;
        },
        setLocations: (state, action: PayloadAction<any>) => {
            state.locations = action.payload;
        },
        setLocationDetailsLoading: (state, action: PayloadAction<any>) => {
            state.details.loading = action.payload;
        },
        setLocationDetail: (state, action: PayloadAction<any>) => {
            state.details.location = action.payload;
        },
    },
});

export const {
    setLocationsLoading,
    setLocations,
    setLocationDetailsLoading,
    setLocationDetail,
} = locationSlice.actions;

export const getLocations = (state: RootState) => state.location.locations;
export const getLocationsLoading = (state: RootState) => state.location.loading;
export const getLocationDetails = (state: RootState) => state.location.details;

export default locationSlice.reducer;

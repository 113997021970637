import { Card, Tabs, Tab, Box, Text } from "grommet";
import TransfersDetailsTab from "./TransferDetailsTab";
import VehicleDetailsTab from "./VehicleDetailsTab";
import CompanyTab from "./CompanyTab";
import ImagesTab from "./images/ImagesTab";
import AmenitiesTab from "./amenities/AmenitiesTab";
import DetailsTab from "./DetailsTab";
import CancelPolicyTab from "./CancelPolicyTab";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store/store";
import { getTransferDetail, getTransferDetailFormStatuses, setActiveTabIndex } from "src/store/transfer/transfer.slice";

export default function TransfersTabs() {
    const dispatch = useDispatch();
    const {
        isEditMode,
        transferUpdate,
    } = useSelector((state: RootState) => getTransferDetail(state));

    const {
        transferDetailsTab,
        vehicleDetailsTab,
        detailsTab,
        imagesTab,
        amenitiesTab,
        policyTab,
        companyTab,
    } = useSelector((state: RootState) => getTransferDetailFormStatuses(state));

    function changeActiveTabIndex(index: number) {
        dispatch(setActiveTabIndex(index));
    }

    const transferDetailsUpdateCount = transferDetailsTab.updateFields.length;
    const vehicleDetailsUpdateCount = vehicleDetailsTab.updateFields.length;
    const instructionsUpdateCount = detailsTab.updateFields.length;
    return (
        <Card
            align='start'
            justify='start'
            width="100%"
            pad="small"
            gap="medium"
            background="light-2"
        >
            <Tabs justify='start' style={{ width: "100%" }}>
                <Tab
                    title={
                        isEditMode && (transferDetailsTab.errorCount || transferDetailsUpdateCount) ?
                            <Box direction="row">
                                {
                                    transferDetailsTab.errorCount > 0 &&
                                    <Text
                                        size="11px"
                                        color="red"
                                        margin={{ right: "5px" }}
                                    >
                                        {`(${transferDetailsTab.errorCount})`}
                                    </Text>}
                                {
                                    transferDetailsUpdateCount > 0 &&
                                    <Text
                                        size="11px"
                                        color="blue">
                                        {`(${transferDetailsUpdateCount})`}
                                    </Text>}
                            </Box>
                            : null
                    }
                    icon={
                        <Text>Transfer</Text>
                    }
                    onClick={(event) => {
                        changeActiveTabIndex(0);
                    }}
                >
                    <TransfersDetailsTab />
                </Tab>
                <Tab
                    title={isEditMode && (vehicleDetailsTab.errorCount || vehicleDetailsUpdateCount) ?
                        <Box direction="row">
                            {
                                vehicleDetailsTab.errorCount > 0 &&
                                <Text
                                    size="11px"
                                    color="red"
                                    margin={{ right: "5px" }}
                                >
                                    {`(${vehicleDetailsTab.errorCount})`}
                                </Text>}
                            {
                                vehicleDetailsUpdateCount > 0 &&
                                <Text
                                    size="11px"
                                    color="blue">
                                    {`(${vehicleDetailsUpdateCount})`}
                                </Text>}
                        </Box>
                        : null
                    }
                    icon={
                        <Text>Vehicle</Text>
                    }
                    onClick={(event) => {
                        changeActiveTabIndex(1);
                    }}
                >
                    <VehicleDetailsTab />
                </Tab>
                <Tab
                    title={
                        isEditMode && imagesTab.updateCount ?
                            <Box direction="row">
                                {
                                    imagesTab.errorCount > 0 &&
                                    <Text
                                        size="11px"
                                        color="red"
                                        margin={{ right: "5px" }}
                                    >
                                        {`(${imagesTab.errorCount})`}
                                    </Text>}
                                {
                                    imagesTab.updateCount > 0 &&
                                    <Text
                                        size="11px"
                                        color="blue">
                                        {`(${imagesTab.updateCount})`}
                                    </Text>}
                            </Box>
                            : null
                    }
                    icon={
                        <Text>Images</Text>
                    }
                    onClick={(event) => {
                        changeActiveTabIndex(2);
                    }}
                >
                    <ImagesTab />
                </Tab>
                <Tab
                    title={
                        isEditMode && amenitiesTab.updateCount ?
                            <Box direction="row">
                                {
                                    amenitiesTab.updateCount > 0 &&
                                    <Text
                                        size="11px"
                                        color="blue">
                                        {`(${amenitiesTab.updateCount})`}
                                    </Text>}
                            </Box>
                            : null
                    }
                    icon={
                        <Text>Amenities</Text>
                    }
                    onClick={(event) => {
                        changeActiveTabIndex(3);
                    }}
                >
                    <AmenitiesTab />
                </Tab>
                <Tab
                    title={
                        isEditMode && transferUpdate.selectedPolicy ?
                            <Box direction="row">
                                <Text
                                    size="11px"
                                    color="blue">{"(1)"}</Text>
                            </Box>
                            : null
                    }
                    icon={
                        <Text>Policy</Text>
                    }
                    onClick={(event) => {
                        changeActiveTabIndex(4);
                    }}
                >
                    <CancelPolicyTab />
                </Tab>
                <Tab
                    title={
                        isEditMode && transferUpdate.selectedCompany ?
                            <Box direction="row">
                                <Text
                                    size="11px"
                                    color="blue">{"(1)"}</Text>
                            </Box>
                            : null
                    }
                    icon={
                        <Text>Company</Text>
                    }
                    onClick={(event) => {
                        changeActiveTabIndex(5);
                    }}
                >
                    <CompanyTab />
                </Tab>
                <Tab
                    title={isEditMode && (detailsTab.errorCount || instructionsUpdateCount) ?
                        <Box direction="row">
                            {
                                detailsTab.errorCount > 0 &&
                                <Text
                                    size="11px"
                                    color="red"
                                    margin={{ right: "5px" }}
                                >
                                    {`(${detailsTab.errorCount})`}
                                </Text>}
                            {
                                instructionsUpdateCount > 0 &&
                                <Text
                                    size="11px"
                                    color="blue">
                                    {`(${instructionsUpdateCount})`}
                                </Text>}
                        </Box>
                        : null
                    }
                    icon={
                        <Text>Details</Text>
                    }
                    onClick={(event) => {
                        changeActiveTabIndex(6);
                    }}
                >
                    <DetailsTab />
                </Tab>
            </Tabs>
        </Card >
    )
}
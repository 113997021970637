import { http } from '../../config/http';
import { stringify as stringifyQuery } from 'querystring';
import { CancellationPolicy } from './policy.types';

export const getCancellationPolicy = async (id: number): Promise<any> => {
  return http
    .get(`/transfers/cancel-policies/${id}`)
    .then((res) => res.data);
}

export const getCancellationPolicies = async (page = 1, take = 10, keyword?: string): Promise<any> => {
  const stringifiedQuery = stringifyQuery({ page: page, take: take, keyword: keyword });
  return http
    .get(`/transfers/cancel-policies?${stringifiedQuery}`)
    .then((res) => res.data);
}

export const addCancellationPolicy = async (body: CancellationPolicy): Promise<any> => {
  return http
    .post(`/transfers/cancel-policies`, body)
    .then((res) => res.data);
}

export const deleteCancellationPolicy = async (id: number): Promise<any> => {
  return http
    .delete(`/transfers/cancel-policies/${id}`)
    .then((res) => res.data);
}

export const updateCancellationPolicy = async (id: number, body: CancellationPolicy): Promise<any> => {
  return http
    .put(`/transfers/cancel-policies/${id}`, body)
    .then((res) => res.data);
}
import * as TransferService from "src/store/transfer/transfer.service";
import * as LocationService from "src/store/location/location.service";
import * as CompanyService from "src/store/company/company.service";
import * as PolicyService from "src/store/policy/policy.service";
import {
  setAddTransferLoading,
  setTransferDetail,
  setTransferDetailCompanies,
  setTransferDetailCompany,
  setTransferDetailEditMode,
  setTransferDetailLoading,
  setTransferDetailLocations,
  setTransferDetailPolicies,
  setTransfers,
  setTransfersLoading,
} from "./transfer.slice";
import { SearchTransferPayload, Transfer, UpdateTransferPayload } from "./transfer.types";
import appHistory from "src/helpers/history";

export const adminSearchTransfersAction = (body: SearchTransferPayload) => {
  return async (dispatch) => {
    try {
      dispatch(setTransfersLoading(true));
      const payload = await TransferService.adminSearchTransfers(body);
      dispatch(setTransfers(payload));
    } catch (err: any) {
      dispatch(setTransfersLoading(false));
    }
  };
};

export const searchTransfersAction = (body: SearchTransferPayload) => {
  return async (dispatch) => {
    try {
      dispatch(setTransfersLoading(true));
      const payload = await TransferService.searchTransfers(body);
      dispatch(setTransfers(payload));
    } catch (err: any) {
      dispatch(setTransfersLoading(false));
    }
  };
};

export const getTransferByIdAction = (id: number) => {
  return async (dispatch) => {
    try {
      dispatch(setTransferDetailLoading(true));
      const payload = await TransferService.getTransferById(id);
      dispatch(setTransferDetail(payload));
    } catch (err: any) {
      dispatch(setTransferDetailLoading(false));
    }
  };
};

export const getTransferCompanyAction = (id: number) => {
  return async (dispatch) => {
    try {
      const payload = await TransferService.getTransferCompany(id);
      dispatch(setTransferDetailCompany(payload));
    } catch (err: any) {
      console.log(err);
    }
  };
};

export const getTransferLocationsAction = () => {
  return async (dispatch) => {
    try {
      const payload = await LocationService.getTransferLocations();
      dispatch(setTransferDetailLocations(payload));
    } catch (err: any) {
      console.log(err);
    }
  };
};

export const getCancellationPoliciesAction = (page?: number, take?: number, keyword?: string) => {
  return async (dispatch) => {
    try {
      const payload = await PolicyService.getCancellationPolicies(page, take, keyword);
      dispatch(setTransferDetailPolicies(payload));
    } catch (err: any) {
      console.log(err);
    }
  };
};

export const getTransferCompaniesAction = (page?: number, take?: number, keyword?: string) => {
  return async (dispatch) => {
    try {
      const payload = await CompanyService.getTransferCompanies(page, take, keyword);
      dispatch(setTransferDetailCompanies(payload));
    } catch (err: any) {
      console.log(err);
    }
  };
};

export const updateTransferAction = (
  id: number,
  updatePayload: UpdateTransferPayload,
  fileImages: string[],
  defaultImages: string[],
  removeImages: string[],
) => {
  return async (dispatch) => {
    try {
      dispatch(setTransferDetailLoading(true));
      const payload = await TransferService.updateTransfer(
        id,
        updatePayload,
        fileImages,
        defaultImages,
        removeImages,
      );
      dispatch(setTransferDetail(payload));
      dispatch(setTransferDetailEditMode(false));
    } catch (err: any) {
      console.log(err);
      dispatch(setTransferDetailLoading(false));
    }
  };
};

export const addTransferAction = (
  data: Transfer,
  fileImages: string[],
  defaultImages: string[],
) => {
  return async (dispatch) => {
    try {
      dispatch(setAddTransferLoading(true));
      const payload = await TransferService.addTransfer(
        data,
        fileImages,
        defaultImages,
      );
      dispatch(setTransferDetail(payload));
      appHistory.push(`/transfers/${payload.id}`)
      dispatch(setAddTransferLoading(false));
    } catch (err: any) {
      console.log(err);
    }
  };
};

export const duplicateTransferAction = (id: number) => {
  return async (dispatch) => {
    try {
      dispatch(setTransferDetailLoading(true));
      const payload = await TransferService.duplicateTransfer(id);
      dispatch(setTransferDetail(payload));
      dispatch(setTransferDetailEditMode(false));
      appHistory.push(`/transfers/${payload.id}`);
    } catch (err: any) {
      console.log(err);
      dispatch(setTransferDetailLoading(false));
    }
  };
};
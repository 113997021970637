import React, { FC, useEffect } from 'react';
import { Box, Card, Form, Text } from 'grommet';
import PrivatePageLayout from 'src/components/layouts/PrivatePageLayout/PrivatePageLayout';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loading from 'src/components/utils/Loading';
import NotFound from '../NotFound';
import { getReservationDetails, getReservationDetailsLoading } from 'src/store/reservation/reservation.slice';
import { getReservationByCodeAction, updateReservationStatusAction } from 'src/store/reservation/reservation.action';
import { Passenger, Reservation, ReservationStatus } from 'src/store/reservation/reservation.types';
import moment from 'moment';
import CustomButton from 'src/components/utils/CustomButton';

const ReservationDetails: FC<any> = (props) => {
  const { code } = useParams<any>()
  const dispatch = useDispatch();

  const reservation: Reservation = useSelector((state) => getReservationDetails(state));
  const loading = useSelector((state) => getReservationDetailsLoading(state));

  useEffect(() => {
    if (!reservation || (reservation.code != code)) {
      dispatch(getReservationByCodeAction(code));
    }
  }, []);
  const updateStatus = reservation?.status === ReservationStatus.NEW ? ReservationStatus.BOOKED : ReservationStatus.DONE;

  function updateReservationStatus(event) {
    dispatch(updateReservationStatusAction(reservation.id, updateStatus));
  }

  return (
    <PrivatePageLayout title='Reservation Details'>
      {loading ?
        <Loading /> :
        !reservation ?
          <NotFound /> :
          <Form>
            <Box pad="medium" width="100%">
              <Box direction='row' justify='between' align='center'>
                <Text textAlign="center" size='large' weight={900} color="dark">Reservation Details</Text>
                {
                  reservation.status !== ReservationStatus.DONE &&
                  <CustomButton
                    primary
                    maxWidth="100px"
                    type="button"
                    onClick={updateReservationStatus}
                  >
                    <Box direction='row' justify='center' >
                      <Text margin={{ horizontal: "10px" }}>{updateStatus}</Text>
                    </Box>
                  </CustomButton>
                }
              </Box>
              {ReservationDetailsSection()}
              {TransferSection()}
              {PassengerSection()}
              {OtherPersonSection()}
              {FlightSection()}
            </Box>
          </Form>
      }
    </PrivatePageLayout >
  );

  function ReservationDetailsSection() {
    return (
      <Box border="all" pad="20px" margin={{ vertical: "10px" }} style={{ borderRadius: "16px" }}>
        <Text size='medium' weight={900} color="dark">Reservation</Text>
        <Box margin={{ vertical: "10px" }}>
          <Text><Text weight="bold">Pickup Time:</Text> {moment(reservation.pickupDate).format("HH:mm dddd, MMMM DD")}</Text>
          <Text><Text weight="bold">Status:</Text> {reservation.status}</Text>
          {reservation.notes && <Text><Text weight="bold">Notes:</Text> {reservation.notes}</Text>}
          {reservation.fromAddress && <Text><Text weight="bold">From Address:</Text> {reservation.fromAddress}</Text>}
          {reservation.toAddress && <Text><Text weight="bold">To Address:</Text> {reservation.toAddress}</Text>}
        </Box>
      </Box>
    );
  }

  function TransferSection() {
    return (
      <Box border="all" margin={{ vertical: "10px" }} pad="20px" style={{ borderRadius: "16px" }}>
        <Text size='medium' weight={900} color="dark">Transfer</Text>
        <Box margin={{ vertical: "20px" }}>
          <Text weight="bold"> {reservation.transfer.name}</Text>
          <Text weight="bold"> {reservation.transfer.description}</Text>
          <br />
          <Text><Text weight="bold">Origin:</Text> {reservation.transfer.origin.name}</Text>
          <Text><Text weight="bold">Destination:</Text> {reservation.transfer.destination.name}</Text>
          <Text> <Text weight="bold">Price:</Text> {`${reservation.price} ${reservation.currency}`}</Text>
          <Text><Text weight="bold">Capacity:</Text> {reservation.transfer.capacity} Person</Text>
          <Text><Text weight="bold">Type:</Text> {reservation.transfer.type}</Text>
          <Text><Text weight="bold">Vehicle:</Text> {reservation.transfer.vehicle}</Text>
          <br />
          <Text><Text weight="bold">Adult Count:</Text> {reservation.adultCount}</Text>
          <Text><Text weight="bold">Child Count:</Text> {reservation.childCount}</Text>
          <Text><Text weight="bold">Infant Count:</Text> {reservation.infantCount}</Text>
          <Text><Text weight="bold">Baby Seat Count:</Text> {reservation.babySeatCount}</Text>
        </Box>
      </Box>
    );
  }

  function PassengerSection() {
    return (
      <Box border="all" pad="20px" margin={{ vertical: "10px" }} style={{ borderRadius: "16px" }}>
        <Box direction='row' margin={{ top: "10px", bottom: "5px" }}>
          <Text size='medium' weight={900} color="dark">Passengers</Text>
        </Box>
        {[reservation.passenger, ...reservation.otherPax].map((e, index) => {
          return PassengerDetails(e, index);
        })}
      </Box>
    );
  }

  function PassengerDetails(passenger: Passenger, index: number) {
    return (
      <Box key={index} style={{ borderRadius: "16px" }}>
        <Box direction='row' margin={{ top: "10px", bottom: "10px" }}>
          <Text weight="bold">{`Passenger ${index + 1}`}</Text>
        </Box>
        <Text><Text weight="bold">Name:</Text> {`${passenger.firstName} ${passenger.lastName}`}</Text>
        {passenger.phone && <Text><Text weight="bold">Phone:</Text> {passenger.countryPhoneCode + passenger.phone}</Text>}
        {passenger.email && <Text><Text weight="bold">Email:</Text> {passenger.email}</Text>}
        <Text><Text weight="bold">Passport Number:</Text> {passenger.passportNumber}</Text>
      </Box>
    )
  }

  function OtherPersonSection() {
    if (!reservation.otherPersonMadeBooking) return;

    return (
      <Box border="all" pad="20px" margin={{ vertical: "10px" }} style={{ borderRadius: "16px" }}>
        <Box direction='row' margin={{ top: "10px", bottom: "5px" }}>
          <Text size='medium' weight={900} color="dark">Other Person Made Booking</Text>
        </Box>
        {reservation.otherPersonMadeBooking.name && <Text><Text weight="bold">Name:</Text> {reservation.otherPersonMadeBooking.name}</Text>}
        {reservation.otherPersonMadeBooking.phone && <Text><Text weight="bold">Phone:</Text> {reservation.otherPersonMadeBooking.countryPhoneCode + reservation.otherPersonMadeBooking.phone}</Text>}
        {reservation.otherPersonMadeBooking.email && <Text><Text weight="bold">Email:</Text> {reservation.otherPersonMadeBooking.email}</Text>}
      </Box>
    );
  }

  function FlightSection() {
    if (!reservation.flightDetails) return;
    if (
      !reservation.flightDetails.carrierCode
      && !reservation.flightDetails.no
      && !reservation.flightDetails.originAirportCode
      && !reservation.flightDetails.destinationAirportCode
      && !reservation.flightDetails.departureLocalTime
      && !reservation.flightDetails.arrivalLocalTime
    ) return;
    return (
      <Box border="all" pad="20px" margin={{ vertical: "10px" }} style={{ borderRadius: "16px" }}>
        <Text size='medium' weight={900} color="dark">Flight</Text>
        <Box margin={{ vertical: "10px" }}>
          {reservation.flightDetails.carrierCode && <Text><Text weight="bold">Carrier Code:</Text> {reservation.flightDetails.carrierCode}</Text>}
          {reservation.flightDetails.no && <Text><Text weight="bold">Flight Number:</Text> {reservation.flightDetails.no}</Text>}
          {reservation.flightDetails.originAirportCode && <Text><Text weight="bold">Origin Airport:</Text> {reservation.flightDetails.originAirportCode}</Text>}
          {reservation.flightDetails.destinationAirportCode && <Text><Text weight="bold">Destination Airport:</Text> {reservation.flightDetails.destinationAirportCode}</Text>}
          {reservation.flightDetails.departureLocalTime && <Text><Text weight="bold">Departure Local Time:</Text> {moment(reservation.flightDetails.departureLocalTime).format("HH:mm dddd, MMMM DD")}</Text>}
          {reservation.flightDetails.arrivalLocalTime && <Text><Text weight="bold">Arrival Local Time:</Text> {moment(reservation.flightDetails.arrivalLocalTime).format("HH:mm dddd, MMMM DD")}</Text>}
        </Box>
      </Box>
    );
  }
};

export default ReservationDetails;
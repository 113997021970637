import { faTv, faWifi, faChargingStation, faBottleWater, faSnowflake, faCookieBite, faWhiskeyGlass } from "@fortawesome/free-solid-svg-icons";
import { Box, } from "grommet";
import { useDispatch, useSelector } from "react-redux";
import { getTransferDetail, setTransferDetailAmenitiesFormUpdateCount, setTransferUpdateDto } from "src/store/transfer/transfer.slice";
import AmenitiyStatus from "./AmenityStatus";
import CustomCheckBox from "src/components/utils/CustomCheckBox";

export default function AmenitiesTab() {
    const dispatch = useDispatch();
    const { transfer, isEditMode, transferUpdate } = useSelector((state) => getTransferDetail(state));

    return (
        <Box pad="medium">
            {isEditMode ?
                <>
                    <CustomCheckBox
                        label="TV"
                        value={transferUpdate.updateDto.amenities.tv}
                        onChange={(event) => {
                            dispatch(setTransferUpdateDto({
                                ...transferUpdate.updateDto,
                                ...{
                                    amenities: {
                                        ...transferUpdate.updateDto.amenities,
                                        tv: event.target.checked
                                    }
                                }
                            }));
                            dispatch(setTransferDetailAmenitiesFormUpdateCount());
                        }}
                    />
                    <CustomCheckBox
                        label="Wifi"
                        value={transferUpdate.updateDto.amenities.wifi}
                        onChange={(event) => {
                            dispatch(setTransferUpdateDto({
                                ...transferUpdate.updateDto,
                                ...{
                                    amenities: {
                                        ...transferUpdate.updateDto.amenities,
                                        wifi: event.target.checked
                                    }
                                }
                            }));
                            dispatch(setTransferDetailAmenitiesFormUpdateCount());
                        }}
                    />
                    <CustomCheckBox
                        label="Charge Unit"
                        value={transferUpdate.updateDto.amenities.chargeUnit}
                        onChange={(event) => {
                            dispatch(setTransferUpdateDto({
                                ...transferUpdate.updateDto,
                                ...{
                                    amenities: {
                                        ...transferUpdate.updateDto.amenities,
                                        chargeUnit: event.target.checked
                                    }
                                }
                            }));
                            dispatch(setTransferDetailAmenitiesFormUpdateCount());
                        }}
                    />
                    <CustomCheckBox
                        label="Water Bottle"
                        value={transferUpdate.updateDto.amenities.waterBottle}
                        onChange={(event) => {
                            dispatch(setTransferUpdateDto({
                                ...transferUpdate.updateDto,
                                ...{
                                    amenities: {
                                        ...transferUpdate.updateDto.amenities,
                                        waterBottle: event.target.checked
                                    }
                                }
                            }));
                            dispatch(setTransferDetailAmenitiesFormUpdateCount());
                        }}
                    />
                    <CustomCheckBox
                        label="Cold Drinks"
                        value={transferUpdate.updateDto.amenities.coldDrinks}
                        onChange={(event) => {
                            dispatch(setTransferUpdateDto({
                                ...transferUpdate.updateDto,
                                ...{
                                    amenities: {
                                        ...transferUpdate.updateDto.amenities,
                                        coldDrinks: event.target.checked
                                    }
                                }
                            }));
                            dispatch(setTransferDetailAmenitiesFormUpdateCount());
                        }}
                    />
                    <CustomCheckBox
                        name="fridge"
                        label="Fridge"
                        value={transferUpdate.updateDto.amenities.fridge}
                        onChange={(event) => {
                            dispatch(setTransferUpdateDto({
                                ...transferUpdate.updateDto, ...{
                                    amenities: {
                                        ...transferUpdate.updateDto.amenities,
                                        fridge: event.target.checked
                                    }
                                }
                            }));
                            dispatch(setTransferDetailAmenitiesFormUpdateCount());
                        }}
                    />
                    <CustomCheckBox
                        name="snacks"
                        label="Snacks"
                        value={transferUpdate.updateDto.amenities.snacks}
                        onChange={(event) => {
                            dispatch(setTransferUpdateDto({
                                ...transferUpdate.updateDto, ...{
                                    amenities: {
                                        ...transferUpdate.updateDto.amenities,
                                        snacks: event.target.checked
                                    }
                                }
                            }));
                            dispatch(setTransferDetailAmenitiesFormUpdateCount());
                        }}
                    />
                </> :
                <>
                    <AmenitiyStatus title={"TV"} value={transfer.amenities.tv} icon={faTv} />
                    <AmenitiyStatus title={"Wifi"} value={transfer.amenities.wifi} icon={faWifi} />
                    <AmenitiyStatus title={"Charge Unit"} value={transfer.amenities.chargeUnit} icon={faChargingStation} />
                    <AmenitiyStatus title={"Water Bottle"} value={transfer.amenities.waterBottle} icon={faBottleWater} />
                    <AmenitiyStatus title={"Cold Drinks"} value={transfer.amenities.coldDrinks} icon={faWhiskeyGlass} />
                    <AmenitiyStatus title={"Fridge"} value={transfer.amenities.fridge} icon={faSnowflake} />
                    <AmenitiyStatus title={"Snacks"} value={transfer.amenities.snacks} icon={faCookieBite} />
                </>
            }
        </Box>
    )
}

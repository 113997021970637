import React, { FC, useEffect, useState } from 'react';
import { Box, Card, Form, Text } from 'grommet';
import PrivatePageLayout from 'src/components/layouts/PrivatePageLayout/PrivatePageLayout';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from 'src/components/utils/CustomButton';
import { useHistory, useParams } from 'react-router-dom';
import Loading from 'src/components/utils/Loading';
import NotFound from '../NotFound';
import FormTextInput from 'src/components/utils/FormTextInput';
import { validators } from 'src/helpers/validators';
import { getLocationDetails } from 'src/store/location/location.slice';
import { deleteLocationAction, getLocationAction, searchLocationsAction, updateLocationAction } from 'src/store/location/location.action';
import { getLocations } from 'src/store/location/location.slice';
import { RootState } from 'src/store/store';

const LocationDetails: FC<any> = (props) => {
  const { id } = useParams<any>()
  const dispatch = useDispatch();

  const { location, loading } = useSelector((state) => getLocationDetails(state));
  const [isEditMode, setEditMode] = useState(false);
  const locations = useSelector((state: RootState) => getLocations(state));
  const [locationUpdateDto, setLocationUpdateDto] = useState(location);

  useEffect(() => {
    if (locations.length === 0)
      dispatch(searchLocationsAction());
  }, []);

  useEffect(() => {
    if (!location || (location.id != id)) {
      dispatch(getLocationAction(id));
    }
  }, []);

  useEffect(() => {
    if (location) setLocationUpdateDto(location);
  }, [location]);

  return (
    <PrivatePageLayout title='Location Details'>
      {loading ?
        <Loading /> :
        !location ?
          <NotFound /> :
          <Form
            onSubmit={(event) => {
              if (!isEditMode)
                return setEditMode(true);
              if (Object.keys(event.touched).length > 0) {
                delete locationUpdateDto.id;
                delete locationUpdateDto.createdAt;
                delete locationUpdateDto.deletedAt;
                dispatch(updateLocationAction(id, locationUpdateDto));
              }
              setEditMode(false);
            }}
            validate="change"
          >
            <Box direction='row' justify='between' align='center'>
              <Text textAlign="center" size='large' weight={900} color="dark">Location Details</Text>
              <Box direction='row'>
                <CustomButton
                  primary
                  type="submit"
                  label={isEditMode ? "Save" : "Edit"}
                />
                {isEditMode ?
                  <CustomButton
                    primary
                    type="submit"
                    label="Cancel"
                    onClick={() => {
                      setEditMode(false);
                    }}
                  /> :
                  <CustomButton
                    primary
                    backgroundColor="red"
                    type="button"
                    label="Delete"
                    onClick={() => dispatch(deleteLocationAction(location.id))}
                  />}
              </Box>
            </Box>
            {isEditMode ?
              <Box pad="medium" width="100%">
                <FormTextInput
                  id="nameInput"
                  label="Name"
                  name="name"
                  value={locationUpdateDto.name}
                  validate={[validators.required("Name")]}
                  onChange={(event) => setLocationUpdateDto({ ...locationUpdateDto, name: event.target.value })}
                />
              </Box> :
              <Box pad="small" direction='column'>
                <Card pad="large" margin="medium" justify='start' align='start'>
                  <Box direction='row'>
                    <Text margin={{ right: "5px" }} weight="bold">Name:</Text>
                    <Text>{location.name}</Text>
                  </Box>
                </Card>
              </Box>
            }
          </Form>
      }
    </PrivatePageLayout >
  )
};

export default LocationDetails;
import { http } from '../../config/http';
import { stringify as stringifyQuery } from 'querystring';
import { UpdateProfilePayload } from './user.types';

export const getUsers = async (page = 1, take = 10, keyword?: string): Promise<any> => {
  const stringifiedQuery = stringifyQuery({ page: page, take: take, keyword: keyword });
  return http
    .get(`/users?${stringifiedQuery}`)
    .then((res) => res.data);
}

export const updateProfileInfo = async (body: UpdateProfilePayload): Promise<any> => {
  return http
    .put(`/users`, body)
    .then((res) => res.data);
}

export const changePassword = async (body: UpdateProfilePayload): Promise<any> => {
  return http
    .put(`/users`, body)
    .then((res) => res.data);
}
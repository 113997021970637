import React, { FC, useState } from 'react';
import {
  Box,
  Button,
  FileInput,
  Form,
  FormField,
  Image,
  Layer,
  Text,
  ThemeContext,
} from 'grommet';
import { Close } from 'grommet-icons';
import { theme } from '../../../config/app-config';
import { fileToBase64String } from 'src/helpers/utils';

interface ImageUploadProps {
  onSubmit: any | ((arg0: File) => void);
  onDismiss: () => void;
}

const ImageSelect: FC<ImageUploadProps> = ({
  onSubmit,
  onDismiss,
}) => {
  const [imgSrc, setImgSrc] = useState<string>();

  const renderAvatar = () => {
    return (
      <Box flex="grow" justify="center" align="center">
        IMAGE
      </Box>
    );
  };

  return (
    <ThemeContext.Extend
      value={{
        ...theme,
        formField: {
          border: {
            color: 'status-disabled',
          },
        },

        fileInput: {
          border: {
            size: '1px',
            style: 'solid',
          },
          message: {
            size: 'small',
          },
        },
        anchor: {
          extend: {
            fontSize: '14px',
          },
        },
      }}
    >
      <Layer responsive={false}>
        <Box elevation="peach" round="20px">
          <Box pad="small" justify="between" direction="row">
            <Text size="small">Select Image</Text>
            <Close color="brand" size="18px" onClick={onDismiss} />
          </Box>

          <Box
            margin={{ horizontal: 'small', bottom: 'small' }}
            width={{ min: '276px', max: '276px' }}
          >
            <Form
              onSubmit={({ value }: any) => {
                onSubmit(value.image[0], imgSrc);
              }}
            >
              <Box
                width={{ min: '276px', max: '276px' }}
                height={{ min: '276px', max: '276px' }}
                alignSelf="center"
                margin={{ bottom: 'small' }}
                round="16px"
                overflow="hidden"
              >
                {imgSrc ? <Image fit="cover" src={imgSrc} /> : renderAvatar()}
              </Box>

              <FormField name="image" htmlFor="file-input">
                <FileInput
                  messages={{ browse: "Browse" }}
                  name="image"
                  id="image"
                  multiple={false}
                  type="file"
                  accept="image/jpg,
                        image/jpeg,
                        image/png,
                        image/bmp,
                        image/svg+xml"
                  onChange={async (file) => {
                    if (file.target.files && file.target.files.length > 0) {
                      const base64String = await fileToBase64String(file.target.files[0])
                      setImgSrc(base64String);
                    }
                  }}
                />
              </FormField>

              <Box justify="center" direction="row" gap="small">
                <Button
                  label={<Text size="small">Save</Text>}
                  type="submit"
                  primary
                  size="large"
                  fill="horizontal"
                  disabled={!imgSrc}
                />
              </Box>
            </Form>
          </Box>
        </Box>
      </Layer>
    </ThemeContext.Extend>
  );
};

export default ImageSelect;

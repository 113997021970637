import { ColumnConfig, Box, DataTable, Pagination, Text } from "grommet";
import React, { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PrivatePageLayout from "src/components/layouts/PrivatePageLayout/PrivatePageLayout";
import CustomButton from "src/components/utils/CustomButton";
import Loading from "src/components/utils/Loading";
import { searchLocationsAction } from "src/store/location/location.action";
import { getLocationsLoading, setLocationDetail } from "src/store/location/location.slice";
import { LocationRowType } from "src/store/location/location.types";
import { RootState } from "src/store/store";
import { getLocations } from "src/store/location/location.slice";

const columns: ColumnConfig<LocationRowType>[] = [
  {
    property: 'id',
    header: "Id",
    primary: true,
  },
  {
    property: 'name',
    header: "Name"
  },
];

const Locations: FC = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const locations = useSelector((state: RootState) => getLocations(state));
  const loading = useSelector((state: RootState) => getLocationsLoading(state));

  useEffect(() => {
    dispatch(searchLocationsAction());
  }, []);


  return (
    <PrivatePageLayout title='Locations'>
      <Box direction='row' justify='between' align='center'>
        <Text textAlign="center" size='large' weight={900} color="dark">Locations</Text>
        <Box>
          <CustomButton
            primary
            type="button"
            label="Add Location"
            onClick={() => history.push('/locations/add')}
          />
        </Box>
      </Box>
      <Box margin={{ top: "medium" }}>
        {loading ?
          <Loading /> :
          <>
            <DataTable
              columns={columns}
              data={locations}
              sortable={false}
              resizeable={true}
              step={10}
              onClickRow={(event) => {
                const location = locations.find(e => e.id == event.datum.id);
                dispatch(setLocationDetail(location));
                history.push(`/locations/${event.datum.id}`);
              }}
            />
          </>
        }
      </Box>
    </PrivatePageLayout>
  )
};

export default Locations;
import { nanoid } from 'nanoid';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { ResponseError } from '../models/response-error';

export const errorResponseMessage = (error?: ResponseError): string => {
  if (!error) return '';
  if (Array.isArray(error.message)) return error.message.join(', ');
  return error.message;

  // return i18n.t(`ErrorTypes.${error.message}`);
};

export const camelToSentence = (str: string): string => {
  return str
    .replace(/([A-Z])/g, (m) => ` ${m}`)
    .replace(/^./, (m) => m.toUpperCase())
    .trim();
};

export const thirtyIds = Array(30)
  .fill(null)
  .map(() => ({
    id: nanoid(),
  }));

export const ceilTime = (time: Date | string | number, minutes = 30): Date => {
  const timeToReturn = new Date(time);

  timeToReturn.setMilliseconds(
    Math.ceil(timeToReturn.getMilliseconds() / 1000) * 1000
  );
  timeToReturn.setSeconds(Math.ceil(timeToReturn.getSeconds() / 60) * 60);
  timeToReturn.setMinutes(
    Math.ceil((timeToReturn.getMinutes() + 1) / minutes) * minutes
  );
  return timeToReturn;
};

export const fetchOrProduceNull = async <T>(
  request: () => Promise<T>
): Promise<T | null> => {
  try {
    const result = await request();
    return result;
  } catch (err: any) {
    return null;
  }
};

export function getCookie(name: string): string | null {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
  return null;
}

export const matchDescriptionTags = /(#\w+)/gi;

export const getChatRoomName = (
  id: string,
  medium: 'post' | 'direct' | 'channel' = 'direct'
): string => `${medium}_${id}`;

export const getFileKey = (file: File): string =>
  `${file.name}_${file.size}_${file.type}`;

export const getTimezoneTimeFromUTC = (date: string | Date): Dayjs => {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return dayjs.tz(date, currentTimezone);
};

export const fileToBase64String = async (file: File) => {
  const buffer = Buffer.from(await file.arrayBuffer());
  const base64String = buffer.toString('base64');
  return `data:image/jpeg;base64,${base64String}`;
}

export interface RGB {
  r: number;
  g: number;
  b: number;
  width: number;
  height: number;
}

import React, { FC, useState } from 'react';
import { Form, FormField, Image, TextInput } from 'grommet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import AuthLayout from '../../components/layouts/AuthLayout';
import { FormSubmitEvent } from '../../models/form-submit-event';
import { validators } from '../../helpers/validators';
import Figure from '../../assets/register-bg/figure.svg';
import Banner from '../../assets/register-bg/banner.svg';
import AuthFormButton from '../../components/auth/AuthFormButton';
import { RootState } from 'src/store/store';
import { RegisterInvitationPayload } from 'src/store/auth/auth.types';
import { registerWithInviteAction } from 'src/store/auth/auth.action';
import { useQuery } from 'src/hooks/useQuery';
import { CustomPhoneInput } from 'src/components/utils/CustomPhoneInput/CustomPhoneInput';

const RegisterInvitation: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const query = useQuery();
  const firstName = query.get("firstName");
  const lastName = query.get("lastName");
  const email = query.get("email");
  const phone = query.get("phone");
  const countryPhoneCode = query.get("countryPhoneCOde");
  const token = query.get("token");
  const reservationCode = query.get("reservationCode");
  const message = query.get("message");

  const {
    register: { loading },
  } = useSelector((state: RootState) => state.auth);

  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [countryCode, setCountryCode] = useState<string>("90");
  const [isValid, setIsValid] = useState(true);

  const handlePhoneNumberChange = (countryCode: string, phone: string, valid: boolean) => {
    setPhoneNumber(phone);
    setCountryCode(countryCode);
    setIsValid(valid);
  };

  const handleSubmit: FormSubmitEvent<RegisterInvitationPayload> = ({ value }) => {
    if (!isValid) return;
    let body: RegisterInvitationPayload = undefined;
    if (phone) {
      body = {
        token,
        password: value.password,
        countryPhoneCode: countryPhoneCode,
        phone: phone,
      };
    } else {
      body = {
        token,
        password: value.password,
        phone: isValid ? phoneNumber : undefined,
        countryPhoneCode: isValid ? countryCode : undefined,
      };
    }
    dispatch(registerWithInviteAction(body, reservationCode));
  };

  return (
    <AuthLayout
      title={t('Register.title')}
      formTitle={t('Register.formTitle')}
      formSubTitle={message ?? t('Register.formSubTitle')}
      callToAction={{
        title: t('Register.alreadyHaveAccount'),
        body: t('common.signIn'),
        onClick: () => history.push('/login'),
      }}
      background={
        <>
          <Image
            width="60%"
            src={Banner}
            style={{
              position: 'absolute',
              pointerEvents: 'none',
              top: 0,
              left: 250,
            }}
          />
          <Image
            width="60%"
            alignSelf="center"
            style={{ zIndex: 1 }}
            src={Figure}
          />
        </>
      }
    >
      <>
        <Form onSubmit={handleSubmit}>
          <FormField
            label={t('common.firstName')}
            name="firstName"
            htmlFor="firstNameInput"
            disabled
            validate={validators.required(t('common.firstName'))}
          >
            <TextInput id="fiestNameInput" value={firstName} name="firstName" />
          </FormField>

          <FormField
            label={t('common.lastName')}
            name="lastName"
            disabled
            htmlFor="lastNameInput"
            validate={validators.required(t('common.lastName'))}
          >
            <TextInput id="lastNameInput" value={lastName} name="lastName" />
          </FormField>

          <FormField
            name="email"
            htmlFor="emailInput"
            label={t('common.email')}
            disabled
            value={email}
            validate={[
              validators.required(t('common.email')),
              validators.email(),
            ]}
          >
            <TextInput id="emailInput" value={email} name="email" />
          </FormField>

          <CustomPhoneInput
            label={t('common.phone')}
            disabled={phone ? true : false}
            required={false}
            value={phoneNumber}
            countryCode=''
            onChange={handlePhoneNumberChange}
          />

          <FormField
            name="password"
            htmlFor="passwordInput"
            label={t('common.password')}
            validate={[
              validators.required(t('common.password')),
              validators.minLength(t('common.password'), 6),
            ]}
          >
            <TextInput id="passwordInput" name="password" type="password" />
          </FormField>
          <FormField
            name="password1"
            htmlFor="password1Input"
            label={t('common.confirmPassword')}
            validate={[
              validators.required(t('common.confirmPassword')),
              validators.equalsField('password', t('common.passwords')),
            ]}
          >
            <TextInput id="password1Input" name="password1" type="password" />
          </FormField>
          <AuthFormButton
            primary
            margin={{ top: 'medium' }}
            disabled={loading}
            type="submit"
            label={t('Register.signUp')}
          />
        </Form>
      </>
    </AuthLayout>
  );
};

export default RegisterInvitation;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { PolicyState } from "./policy.types";

const PolicySliceInitialState: PolicyState = {
    policies: {
        data: [],
        meta: {
            hasNextPage: false,
            hasPreviousPage: false,
            itemCount: 0,
            page: 1,
            pageCount: 0,
            take: 10,
        },
    },
    loading: false,
    details: {
        policy: undefined,
        loading: false,
    }
};

export const policySlice: any = createSlice({
    name: "policy",
    initialState: PolicySliceInitialState,
    reducers: {
        setPoliciesLoading: (state, action: PayloadAction<any>) => {
            state.loading = action.payload;
        },
        setPolicies: (state, action: PayloadAction<any>) => {
            state.policies = action.payload;
        },
        setPolicyDetailLoading: (state, action: PayloadAction<any>) => {
            state.details.loading = action.payload;
        },
        setPolicyDetail: (state, action: PayloadAction<any>) => {
            state.details.policy = action.payload;
        },
    },
});

export const {
    setPoliciesLoading,
    setPolicies,
    setPolicyDetailLoading,
    setPolicyDetail,
} = policySlice.actions;

export const getPolicies = (state: RootState) => state.policy.policies;
export const getPoliciesLoading = (state: RootState) => state.policy.loading;
export const getPolicyDetails = (state: RootState) => state.policy.details;

export default policySlice.reducer;

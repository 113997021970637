import { nanoid } from 'nanoid';
import { RouteComponentProps } from 'react-router-dom';
import Login from './pages/Public/Login';
import Register from './pages/Public/Register';
import JoinPage from './pages/Public/JoinPage';
import Home from './pages/Private/Home';
import VerifyUserEmailInfo from './pages/Public/VerifyUserEmailInfo';
import VerifyUserEmail from './pages/Public/VerifyUserEmail';
import ForgotPassword from './pages/Public/ForgotPassword';
import ResetPassword from './pages/Public/ResetPassword';
import ThirdPartyAuth from './pages/Public/ThirdPartyAuth';
import Companies from './pages/Private/Company/Companies';
import Locations from './pages/Private/Location/Locations';
import Policies from './pages/Private/Policy/Policies';
import Reservations from './pages/Private/Reservation/Reservations';
import Transfers from './pages/Private/Transfer/Transfers';
import Users from './pages/Private/Users';
import TransferDetails from './pages/Private/Transfer/TransferDetails/TransfersDetails';
import DefaultImages from './pages/Private/DefaultImages/DefaultImages';
import AddTransfer from './pages/Private/Transfer/AddTransfer/AddTransfer';
import AddCompany from './pages/Private/Company/AddCompany';
import CompanyDetails from './pages/Private/Company/CompanyDetails';
import LocationDetails from './pages/Private/Location/LocationDetails';
import AddLocation from './pages/Private/Location/AddLocation';
import ReservationDetails from './pages/Private/Reservation/ReservationDetails';
import RegisterInvitation from './pages/Public/RegisterInvitation';
import Profile from './pages/Private/Profile';
import AddPolicy from './pages/Private/Policy/AddPolicy';
import PolicyDetails from './pages/Private/Policy/PolicyDetails';
import AddReservation from './pages/Private/Reservation/AddReservation';

interface AppRoute {
  id: string;
  path: string;
  component:
  | React.ComponentType<RouteComponentProps<any>>
  | React.ComponentType<any>;
  exact?: boolean;
  description: string;
}

export const publicRoutes: Array<AppRoute> = [
  {
    id: nanoid(),
    path: '/',
    component: JoinPage,
    description: 'Welcomes user into the app',
  },
  {
    id: nanoid(),
    path: '/login',
    component: Login,
    description: 'User Logs into the app',
  },
  {
    id: nanoid(),
    path: '/register',
    component: Register,
    description: 'User register the app',
  },
  {
    id: nanoid(),
    path: '/register/invite',
    component: RegisterInvitation,
    description: 'User register with intvite',
  },
  {
    id: nanoid(),
    path: '/verify-email-info',
    component: VerifyUserEmailInfo,
    description: 'User is asked to verify email',
  },
  {
    id: nanoid(),
    path: '/verify-email/result',
    component: VerifyUserEmail,
    description: 'User is asked to verify email',
  },
  {
    id: nanoid(),
    path: '/forgot-password',
    component: ForgotPassword,
    description: 'User requests for a password change',
  },
  {
    id: nanoid(),
    path: '/reset-password/:token',
    component: ResetPassword,
    description: 'User resets after redirected from email',
  },
  {
    id: nanoid(),
    path: '/auth/:name',
    component: ThirdPartyAuth,
    description: 'User Authenticates with third party',
  },
];

export const privateRoutes: Array<AppRoute> = [
  {
    id: nanoid(),
    path: '/home',
    component: Home,
    description: 'Home',
  },
  {
    id: nanoid(),
    path: '/companies',
    component: Companies,
    description: 'Companies',
  },
  {
    id: nanoid(),
    path: '/companies/add',
    component: AddCompany,
    description: 'Add Company',
  },
  {
    id: nanoid(),
    path: '/companies/:id',
    component: CompanyDetails,
    description: 'Company Details',
  },
  {
    id: nanoid(),
    path: '/locations',
    component: Locations,
    description: 'Locations',
  },
  {
    id: nanoid(),
    path: '/locations/add',
    component: AddLocation,
    description: 'Add Location',
  },
  {
    id: nanoid(),
    path: '/locations/:id',
    component: LocationDetails,
    description: 'Location Details',
  },
  {
    id: nanoid(),
    path: '/policies',
    component: Policies,
    description: 'Policies',
  },
  {
    id: nanoid(),
    path: '/policies/add',
    component: AddPolicy,
    description: 'Add Policy',
  },
  {
    id: nanoid(),
    path: '/policies/:id',
    component: PolicyDetails,
    description: 'Policy Details',
  },
  {
    id: nanoid(),
    path: '/reservations',
    component: Reservations,
    description: 'Reservations',
  },
  {
    id: nanoid(),
    path: '/reservations/add',
    component: AddReservation,
    description: 'Add Reservation',
  },
  {
    id: nanoid(),
    path: '/reservations/:code',
    component: ReservationDetails,
    description: 'Reservation Details',
  },
  {
    id: nanoid(),
    path: '/transfers',
    component: Transfers,
    description: 'Transfers',
  },
  {
    id: nanoid(),
    path: '/transfers/add',
    component: AddTransfer,
    description: 'Add Transfer',
  },
  {
    id: nanoid(),
    path: '/transfers/:id',
    component: TransferDetails,
    description: 'Transfer Detail',
  },
  {
    id: nanoid(),
    path: '/users',
    component: Users,
    description: 'Users',
  },
  {
    id: nanoid(),
    path: '/default-images',
    component: DefaultImages,
    description: 'Default Images',
  },
  {
    id: nanoid(),
    path: '/profile',
    component: Profile,
    description: 'User Profile',
  },
];

import { Box, Grid, Button } from "grommet";
import { Add } from "grommet-icons";
import { useDispatch, useSelector } from "react-redux";
import ExtendedBox from "src/components/utils/ExtendedBox";
import { getAddTransfer, setAddTransferImageList } from "src/store/transfer/transfer.slice";
import { useState } from "react";
import { MediaType, UpdateImageAction, UpdateImageDto } from "src/store/transfer/transfer.types";
import { v4 as uuidv4 } from 'uuid';
import SelectDefaultImage from "../../TransferDetails/components/images/SelectImageUpload";
import UpdateGridImage from "../../TransferDetails/components/images/UpdateGridImage";

export default function ImagesTab() {
    const dispatch = useDispatch();
    const { images } = useSelector((state) => getAddTransfer(state));
    const [showImageUpload, setShowImageUpload] = useState(false);

    return (
        <Box pad="medium">
            <Grid
                columns={{ count: "fill", size: "small" }}
                gap="small">
                {
                    images.map(e => {
                        return <UpdateGridImage
                            key={e.id}
                            image={e}
                            fit="cover"
                            onRemove={() => {
                                const updatedList = images.filter(img => img.id !== e.id);
                                dispatch(setAddTransferImageList(updatedList));
                            }}
                        />
                    })}
                <Box>
                    <Button
                        onClick={() => {
                            setShowImageUpload(true);
                        }}
                    >
                        <ExtendedBox
                            justify="center"
                            align="center"
                            height="125px"
                            width="210px"
                            key="uploadImage"
                            border="all"
                            round="8px"
                        >
                            <Add size='large' />
                        </ExtendedBox>
                    </Button>
                </Box>
                {showImageUpload && (
                    <SelectDefaultImage
                        onImageSelect={(img) => {
                            const newImage = { ...img, action: UpdateImageAction.ADD_DEFAULT };
                            const updatedList = [...images, newImage];
                            dispatch(setAddTransferImageList(updatedList));
                            setShowImageUpload(false);
                        }}
                        onFileSelect={(file: File, base64: string) => {
                            const newImage: UpdateImageDto = {
                                id: uuidv4(),
                                type: MediaType.IMAGE,
                                file,
                                base64,
                                action: UpdateImageAction.ADD_FILE,
                            };
                            const updatedList = [...images, newImage];
                            dispatch(setAddTransferImageList(updatedList));
                            setShowImageUpload(false);
                        }}
                        onClose={() => {
                            setShowImageUpload(false);
                        }}
                    />
                )}
            </Grid>
        </Box>
    )
}
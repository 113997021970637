import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Text } from "grommet";
import { StatusGood, StatusCritical } from "grommet-icons";

export default function AmenitiyStatus(props) {
    const { icon, title, value } = props;
    return (
        <Box direction='row' margin="10px" justify='start' align='center'>
            <FontAwesomeIcon icon={icon} />
            <Text margin={{ horizontal: "10px" }}>{title} :</Text>
            {value ? <StatusGood color='green' /> : <StatusCritical color='red' size='medium' />}
        </Box>
    )
}
import { Box, Text } from "grommet";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "src/components/utils/CustomButton";
import Loading from "src/components/utils/Loading";
import SelectLayerDataTable from "src/components/utils/SelectLayerDataTable";
import { getTransferCompaniesAction } from "src/store/transfer/transfer.action";
import { getCompanies, getTransferDetail, setTransferUpdateCompany, setTransferUpdateDto } from "src/store/transfer/transfer.slice";

export default function CompanyTab() {
    const dispatch = useDispatch();

    const { company, isEditMode, transferUpdate } = useSelector((state) => getTransferDetail(state));
    const companies = useSelector((state) => getCompanies(state));
    const [page, setPage] = useState<number>();

    useEffect(() => {
        if (isEditMode) {
            setPage(1);
        }
    }, [isEditMode]);

    useEffect(() => {
        if (isEditMode)
            getCompaniesRequest();
    }, [page]);

    const getCompaniesRequest = () => {
        dispatch(getTransferCompaniesAction(page, companies.meta.take));
    };

    const handleChange = ({ page }) => {
        setPage(page);
    };

    const [open, setOpen] = useState(false);
    const onOpen = () => setOpen(true);
    const onClose = () => setOpen(undefined);
    return (
        <Box pad="medium">
            {!company ?
                <Loading /> :
                <>
                    {isEditMode &&
                        <Box>
                            <CustomButton
                                label={transferUpdate.selectedCompany ? "Change" : "Select"}
                                onClick={onOpen}
                                color="dark-3" />
                            <SelectLayerDataTable
                                isOpen={open}
                                title="Select Company"
                                columns={[
                                    {
                                        property: 'id',
                                        header: "Id",
                                        primary: true,
                                    },
                                    {
                                        property: 'companyName',
                                        header: "Name"
                                    },
                                    {
                                        property: 'taxNumber',
                                        header: "Tax Number"
                                    },
                                    {
                                        property: 'address',
                                        header: 'Address',
                                    }
                                ]}
                                listData={companies}
                                onSelect={(data) => {
                                    const company = companies.data.find(e => e.id === data.id);
                                    dispatch(setTransferUpdateCompany(company));
                                    dispatch(setTransferUpdateDto({ ...transferUpdate.updateDto, companyId: company.id }));
                                }}
                                onClose={onClose}
                                page={page}
                                onPageChanged={handleChange}
                            />
                        </Box>
                    }
                    {transferUpdate.selectedCompany && isEditMode ?
                        <Box pad={{ top: "10px" }}>
                            <Text weight="bold" size='large'>{transferUpdate.selectedCompany.companyName}</Text>
                            <Text>{transferUpdate.selectedCompany.taxNumber}</Text>
                            <Text>{transferUpdate.selectedCompany.address}</Text>
                            <Text margin={{ top: "10px" }} weight="bold">Contact Info</Text>
                            <Text>{transferUpdate.selectedCompany.contactName}</Text>
                            <Text>{transferUpdate.selectedCompany.contactEmail}</Text>
                            <Text>{`+${transferUpdate.selectedCompany.contactCountryPhoneCode}${transferUpdate.selectedCompany.contactPhone}`}</Text>
                            <Text margin={{ top: "10px" }} weight="bold">Phone Numbers</Text>
                            {transferUpdate.selectedCompany.phoneNumbers.map(e => <Text key={e} >{`+${e.countryPhoneCode}${e.phone}`}</Text>)}
                        </Box> :
                        <Box pad={{ top: "10px" }}>
                            <Text weight="bold" size='large'>{company.companyName}</Text>
                            <Text>{company.taxNumber}</Text>
                            <Text>{company.address}</Text>
                            <Text margin={{ top: "10px" }} weight="bold">Contact Info</Text>
                            <Text>{company.contactName}</Text>
                            <Text>{company.contactEmail}</Text>
                            <Text>{`+${company.contactCountryPhoneCode}${company.contactPhone}`}</Text>
                            <Text margin={{ top: "10px" }} weight="bold">Phone Numbers</Text>
                            {company.phoneNumbers.map(e => <Text key={e} >{`+${e.countryPhoneCode}${e.phone}`}</Text>)}
                        </Box>}
                </>
            }
        </Box>
    )
}

import React, { FC, useEffect } from 'react';
import { Box, Form, Text } from 'grommet';
import PrivatePageLayout from 'src/components/layouts/PrivatePageLayout/PrivatePageLayout';
import { useDispatch, useSelector } from 'react-redux';
import AddTransfersTabs from './components/AddTransferTabs';
import CustomButton from 'src/components/utils/CustomButton';
import { addTransferAction } from 'src/store/transfer/transfer.action';
import { UpdateImageAction } from 'src/store/transfer/transfer.types';
import { getAddTransfer, getAddTransferErrorCount, resetAddTransferDto, setAddTransferDto, setAddTransferFormErrorCount } from 'src/store/transfer/transfer.slice';
import { removeToastAction, setToastAction } from 'src/store/util/util.action';
import { nanoid } from 'nanoid';
import Loading from 'src/components/utils/Loading';

const AddTransfer: FC = () => {
  const dispatch = useDispatch();
  const { payload, images, loading } = useSelector((state) => getAddTransfer(state));
  const formErrorCount = useSelector((state) => getAddTransferErrorCount(state));

  function onSubmit(event) {
    if (formErrorCount > 0) {
      return dispatch(setToastAction('error', "Form not validated"));
    }
    const defaultImages = images.filter(e => e.action === UpdateImageAction.ADD_DEFAULT).map(e => e.id);
    const fileImages = images.filter(e => e.action === UpdateImageAction.ADD_FILE).map(e => e.file);
    dispatch(addTransferAction(
      {
        ...payload,
        company: undefined,
        cancellationPolicy: undefined,
      },
      fileImages,
      defaultImages,
    ));
  }

  function onValidate(formEvent) {
    if (formEvent.errors) {
      const keys = Object.keys(formEvent.errors);
      dispatch(setAddTransferFormErrorCount(keys));
    }
  }

  useEffect(() => {
    dispatch(resetAddTransferDto());
  }, []);

  return (
    <PrivatePageLayout title='Transfers'>
      <Box>
        {loading ?
          <Loading /> :
          <Form
            value={payload}
            onSubmit={onSubmit}
            onValidate={onValidate}
            validate="change"
          >
            <Box direction='row' justify='between' align='center'>
              <Text textAlign="center" size='large' weight={900} color="dark">Add Transfer</Text>
              <Box>
                <CustomButton
                  primary
                  type="submit"
                  label="Save"
                />
              </Box>
            </Box>
            <AddTransfersTabs />
          </Form>
        }
      </Box>
    </PrivatePageLayout >
  )
};

export default AddTransfer;
import { Box, Grid, Button } from "grommet";
import { Add } from "grommet-icons";
import { useDispatch, useSelector } from "react-redux";
import ExtendedBox from "src/components/utils/ExtendedBox";
import { getTransferDetail, setUploadImageList } from "src/store/transfer/transfer.slice";
import GridImage from "./GridImage";
import { useState } from "react";
import UpdateGridImage from "./UpdateGridImage";
import { MediaType, UpdateImageAction, UpdateImageDto } from "src/store/transfer/transfer.types";
import { v4 as uuidv4 } from 'uuid';
import SelectDefaultImage from "./SelectImageUpload";

export default function ImagesTab() {
    const dispatch = useDispatch();
    const { transfer, isEditMode, transferUpdate } = useSelector((state) => getTransferDetail(state));
    const [showImageUpload, setShowImageUpload] = useState(false);

    return (
        <Box pad="medium">
            <Grid
                columns={{ count: "fill", size: "small" }}
                gap="small">
                {
                    isEditMode ?
                        <>
                            {
                                transferUpdate.updateImages.map(e => {
                                    return <UpdateGridImage
                                        key={e.id}
                                        image={e}
                                        fit="cover"
                                        onRemove={() => {
                                            if (e.action === UpdateImageAction.NOTHING) {
                                                const newImage = { ...e, action: UpdateImageAction.REMOVE };
                                                const index = transferUpdate.updateImages.findIndex(img => img.id === e.id);
                                                const updatedList = [...transferUpdate.updateImages];
                                                updatedList[index] = newImage;
                                                dispatch(setUploadImageList(updatedList));
                                            } else if (e.action === UpdateImageAction.REMOVE) {
                                                const newImage = { ...e, action: UpdateImageAction.NOTHING };
                                                const index = transferUpdate.updateImages.findIndex(img => img.id === e.id);
                                                const updatedList = [...transferUpdate.updateImages];
                                                updatedList[index] = newImage;
                                                dispatch(setUploadImageList(updatedList));
                                            }
                                            else {
                                                const updatedList = transferUpdate.updateImages.filter(img => img.id !== e.id);
                                                dispatch(setUploadImageList(updatedList));
                                            }
                                        }}
                                    />
                                })}
                            <Box>
                                <Button
                                    onClick={() => {
                                        setShowImageUpload(true);
                                    }}
                                >
                                    <ExtendedBox
                                        justify="center"
                                        align="center"
                                        height="125px"
                                        width="210px"
                                        key="uploadImage"
                                        border="all"
                                        round="8px"
                                    >
                                        <Add size='large' />
                                    </ExtendedBox>
                                </Button>
                            </Box>
                        </> : transfer.images.map(e => {
                            return <GridImage
                                key={e.id}
                                image={e}
                                fit="cover"
                            />
                        })
                }
                {showImageUpload && (
                    <SelectDefaultImage
                        onImageSelect={(img) => {
                            const newImage = { ...img, action: UpdateImageAction.ADD_DEFAULT };
                            const updatedList = [...transferUpdate.updateImages, newImage];
                            dispatch(setUploadImageList(updatedList));
                            setShowImageUpload(false);
                        }}
                        onFileSelect={(file: File, base64: string) => {
                            const newImage: UpdateImageDto = {
                                id: uuidv4(),
                                type: MediaType.IMAGE,
                                file,
                                base64,
                                action: UpdateImageAction.ADD_FILE,
                            };
                            const updatedList = [...transferUpdate.updateImages, newImage];
                            dispatch(setUploadImageList(updatedList));
                            setShowImageUpload(false);
                        }}
                        onClose={() => {
                            setShowImageUpload(false);
                        }}
                    />
                )}
            </Grid>
        </Box>
    )
}
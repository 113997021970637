import { Box } from "grommet";
import { useDispatch, useSelector } from "react-redux";
import FormTextArea from "src/components/utils/FormTextArea";
import FormTextInput from "src/components/utils/FormTextInput";
import { validators } from "src/helpers/validators";
import { getAddTransfer, setAddTransferDto } from "src/store/transfer/transfer.slice";
import { DisclaimerMessages } from "../../components/DisclaimerMessages";

export default function DetailsTab() {
    const dispatch = useDispatch();
    const { payload } = useSelector((state) => getAddTransfer(state));

    function instructionsOnChange(event) {
        dispatch(setAddTransferDto({ ...payload, instructions: event.target.value }))
    }

    function taxAndVatOnChange(event) {
        dispatch(setAddTransferDto({ ...payload, taxAndVatInfo: event.target.value }))
    }

    function videoLinkOnChange(event) {
        dispatch(setAddTransferDto({ ...payload, pickupVideoTutLink: event.target.value }))
    }

    function messagesOnChange(index: number, event) {
        const data = [...payload.disclaimerMessages];
        data[index] = event.target.value;
        dispatch(setAddTransferDto({
            ...payload,
            disclaimerMessages: data,
        }));
    }

    function messagesOnRemove(index: number, event) {
        dispatch(setAddTransferDto({
            ...payload,
            disclaimerMessages: payload.disclaimerMessages.filter((e, messageIndex) => index !== messageIndex),
        }));
    }

    function messagesOnAdd(event) {
        dispatch(setAddTransferDto({
            ...payload,
            disclaimerMessages: [...payload.disclaimerMessages, ""],
        }));
    }

    return (
        <Box pad="10px">
            <FormTextArea
                id="instructions"
                label="Pick Up Instructions"
                name="instructions"
                validate={[validators.required("Instructions")]}
                value={payload.instructions}
                onChange={instructionsOnChange}
                rows={5}
            />
            <FormTextInput
                id="videoLinkInput"
                label="Pick Up Video Link"
                name="pickupVideoTutLink"
                value={payload.pickupVideoTutLink}
                onChange={videoLinkOnChange}
            />
            <FormTextArea
                id="taxAndVatInput"
                label="Tax And Vat Info"
                name="taxAndVatInfo"
                validate={[validators.required("Tax and vat info")]}
                value={payload.taxAndVatInfo}
                onChange={taxAndVatOnChange}
                rows={3}
            />
            <DisclaimerMessages
                messages={payload.disclaimerMessages}
                messagesOnAdd={messagesOnAdd}
                messagesOnRemove={messagesOnRemove}
                messagesOnChange={messagesOnChange}
            />
        </Box>
    )
}

import { PageMetaDto } from "src/models/page-meta-data";
import { TransferCompany } from "../company/company.types";
import { TransferLocation } from "../location/location.types";
import { CancellationPolicy } from "../policy/policy.types";

export interface TransferState {
  transfers: {
    data: Array<Transfer>,
    meta: PageMetaDto,
  },
  loading: boolean;
  locations: TransferLocation[];
  policies: {
    data: Array<CancellationPolicy>,
    meta: PageMetaDto,
  },
  companies: {
    data: Array<TransferCompany>,
    meta: PageMetaDto,
  },
  addTransfer: {
    payload: Transfer,
    loading: boolean,
    images: UpdateImageDto[],
    activeTabIndex: number,
    formStatuses: {
      transferDetailsTab: {
        updateFields: string[],
        errorCount: number,
      },
      vehicleDetailsTab: {
        updateFields: string[],
        errorCount: number,
      },
      imagesTab: {
        updateCount: number,
        errorCount: number,
      },
      amenitiesTab: {
        updateCount: number,
        errorCount: number,
      },
      policyTab: {
        updateFields: string[],
        errorCount: number,
      },
      companyTab: {
        updateFields: string[],
        errorCount: number,
      },
      detailsTab: {
        updateFields: string[],
        errorCount: number,
      },
    },
  },
  transferDetail: {
    transfer: Transfer;
    loading: boolean;
    company: TransferCompany;
    isEditMode: boolean;
    transferUpdate: {
      updateDto: UpdateTransferPayload,
      selectedCompany: TransferCompany,
      selectedPolicy: CancellationPolicy,
      updateImages: UpdateImageDto[],
      activeTabIndex: number,
      formStatuses: {
        transferDetailsTab: {
          updateFields: string[],
          errorCount: number,
        },
        vehicleDetailsTab: {
          updateFields: string[],
          errorCount: number,
        },
        imagesTab: {
          updateCount: number,
          errorCount: number,
        },
        amenitiesTab: {
          updateCount: number,
          errorCount: number,
        },
        policyTab: {
          updateFields: string[],
          errorCount: number,
        },
        companyTab: {
          updateFields: string[],
          errorCount: number,
        },
        detailsTab: {
          updateFields: string[],
          errorCount: number,
        },
      },
    },
  },
}

export interface TransferRowType {
  id: number;
  name: string;
  status: TransferStatus;
  brand: string;
  model: string;
  type: string;
  vehicle: VehicleType;
  capacity: number;
  price: number;
  currency: string;
  origin: any;
  destination: any;
  duration: number;
}

export interface Transfer {
  id?: number;
  companyId: number;
  company?: TransferCompany;
  name: string;
  description: string;
  status: TransferStatus;
  brand: string;
  model: string;
  type: TransferType;
  vehicle: VehicleType;
  capacity: number;
  price: number;
  basePrice?: number;
  currency: CurrencyType;
  chauffeurMaxWaitTime: number;
  originId: number;
  origin: TransferLocation;
  destinationId: number;
  destination: TransferLocation;
  duration: number;
  cancellationPolicyId: number;
  cancellationPolicy?: CancellationPolicy;
  amenities: AmenitiesDto;
  luggageAllowance: LuggageAllowanceDto;
  images: ImageDto[];
  instructions: string;
  taxAndVatInfo: string;
  pickupVideoTutLink?: string;
  disclaimerMessages: string[];
}

export enum CurrencyType {
  USD = "USD",
  EUR = "EUR",
  TRY = "TRY"
}

export enum TransferStatus {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  PASSIVE = "PASSIVE"
}

export enum TransferType {
  PRIVATE = "PRIVATE",
  SHARED = "SHARED",
  LUXURY = "LUXURY"
}

export enum VehicleType {
  CAR = "CAR",
  VAN = "VAN",
  BUS = "BUS"
}

export class AmenitiesDto {
  wifi: boolean;
  chargeUnit: boolean;
  waterBottle: boolean;
  tv: boolean;
  fridge: boolean;
  coldDrinks: boolean;
  snacks: boolean;
}

export enum MediaType {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

export class ImageDto {
  id: string;
  type: MediaType.IMAGE;
  url: string;
}

export class UpdateImageDto {
  id: string;
  type: MediaType.IMAGE;
  url?: string;
  file?: File;
  base64?: string;
  action: UpdateImageAction;
}

export enum UpdateImageAction {
  NOTHING = "NOTHING",
  REMOVE = "REMOVE",
  ADD_FILE = "ADD_FILE",
  ADD_DEFAULT = "ADD_DEFAULT",
}

export class LuggageAllowanceDto {
  carryOn: number;
  checkIn: number;
}

export interface UpdateTransferPayload {
  companyId?: number;
  name?: string;
  description?: string;
  status?: TransferStatus;
  brand?: string;
  model?: string;
  type?: TransferType;
  vehicle?: VehicleType;
  capacity?: number;
  price?: number;
  currency?: CurrencyType;
  chauffeurMaxWaitTime?: number;
  originId?: number;
  destinationId?: number;
  duration?: number;
  cancellationPolicyId?: number;
  amenities?: AmenitiesDto;
  luggageAllowance?: LuggageAllowanceDto;
  instructions?: string;
  taxAndVatInfo?: string;
  pickupVideoTutLink?: string;
  disclaimerMessages?: string[];
}

export interface SearchTransferPayload {
  origin?: number;
  destination?: number;
  adultCount?: number;
  childCount?: number;
  infantCount?: number;
  babySeatCount?: number;
  page?: number;
  take?: number;
}
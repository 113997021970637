import { logout, setForgotPasswordFailed, setForgotPasswordLoading, setLoginFailed, setLoginLoading, setLoginSuccess, setRegisterFailed, setRegisterLoading, setRegisterSuccess, setResendMailVerificationLoading, setResetPasswordFailed, setResetPasswordLoading } from "./auth.slice";
import { LoginPayload, RegisterInvitationPayload, RegisterPayload, ResetPasswordPayload, UpdatePasswordPayload } from "./auth.types";
import * as AuthService from './auth.service';
import { removePersistRoot, setToken } from "src/helpers/localdb";
import i18n from "src/config/i18n/i18n-config";
import appHistory from "src/helpers/history";
import { setToastAction } from "../util/util.action";
import { REACT_APP_SERVER_HOST } from "src/config/http";

export const loginAction = (user: LoginPayload) => {
  return async (dispatch) => {
    dispatch(setLoginLoading(true));
    try {
      const payload = await AuthService.login(user);
      setToken(payload.token);
      dispatch(setLoginSuccess(payload.user));
    } catch (err: any) {
      dispatch(setLoginFailed(err?.response?.data));
      if (err?.response?.status === 403) {
        appHistory.push(`/verify-email-info?email=${user.email}`);
      }
    }
  };
};

export const logoutAction = () => {
  return async (dispatch) => {
    // await AuthService.logOut();
    dispatch(logout());
    removePersistRoot();
  }
};

export const registerAction = (user: RegisterPayload) => {
  return async (dispatch) => {
    dispatch(setRegisterLoading(true));
    try {
      const payload = await AuthService.register(user);
      dispatch(setRegisterSuccess(payload));
      appHistory.push(`/verify-email-info?email=${user.email}`);
    } catch (err: any) {
      dispatch(setRegisterFailed(err?.response?.data));
    }
  }
};

export const registerWithInviteAction = (user: RegisterInvitationPayload, reservationCode: string) => {
  return async (dispatch) => {
    dispatch(setRegisterLoading(true));
    try {
      const payload = await AuthService.registerInvitation(user);
      setToken(payload.token);
      dispatch(setLoginSuccess(payload.user));
      appHistory.push(`/reservations/${reservationCode}`);
    } catch (err: any) {
      dispatch(setRegisterFailed(err?.response?.data));
    }
  }
};

export const resendMailVerificationTokenAction = (
  userId: string
) => {
  return async (dispatch) => {
    dispatch(setResendMailVerificationLoading(true));
    try {
      await AuthService.resendMailVerificationToken(userId);
      dispatch(setResendMailVerificationLoading(false));

      setToastAction(
        'ok',
        i18n.t('ToastMessages.verificationEmailSent')
      )(dispatch);
      appHistory.replace('/login');
    } catch (err: any) {
      dispatch(setResendMailVerificationLoading(false));
    }
  };
};

export const resetPasswordRequestAction = (email: string) => {
  return async (dispatch) => {
    dispatch(setForgotPasswordLoading(true));
    try {
      await AuthService.resetPasswordRequest(email);
      dispatch(setForgotPasswordLoading(false));
      setToastAction(
        'ok',
        i18n.t('ToastMessages.passwordResetLinkSent', { email })
      )(dispatch);
      appHistory.replace('/login');
    } catch (err: any) {
      dispatch(setForgotPasswordFailed(err?.response?.data));
    }
  };
};

export const resetPasswordAction = (body: ResetPasswordPayload) => {
  return async (dispatch) => {
    dispatch(setResetPasswordLoading(true));
    try {
      await AuthService.resetPassword(body);
      setToastAction(
        'ok',
        i18n.t('ToastMessages.passwordResetSuccess')
      )(dispatch);
      appHistory.replace('/login');
    } catch (err: any) {
      dispatch(setResetPasswordFailed(err?.response?.data));
    }
  };
};

export const updatePasswordAction = (body: UpdatePasswordPayload, onDone: any) => {
  return async (dispatch) => {
    // dispatch(setResetPasswordLoading(true));
    try {
      await AuthService.updatePassword(body);
      onDone();
      setToastAction(
        'ok',
        i18n.t('ToastMessages.passwordUpdateSuccess')
      )(dispatch);
    } catch (err: any) {
      // dispatch(setResetPasswordFailed(err?.response?.data));
    }
  };
};

export const getThirdPartyUrlAction = (name: string) => {
  return async () => {
    window.location.href = `${REACT_APP_SERVER_HOST}/v1/auth/third-party/${name}`;
  };
};

export const authenticateWithThirdPartyCodeAction = (
  name: string,
  code: string | null,
  email: string | null
) => {
  return async (dispatch) => {
    try {
      const payload = await AuthService.authenticateWithThirdPartyCode(
        name,
        code,
        email
      );
      setToken(payload.token);
      dispatch(setLoginSuccess(payload.user));
    } catch (err: any) {
      appHistory.replace('/login');
    }
  };
};
import { CheckBox } from 'grommet'

export default function CustomCheckBox(props) {
    const { label, value, onChange } = props;
    return (
        <CheckBox
            label={label}
            pad="10px"
            checked={value}
            onChange={onChange}
        />
    )
}

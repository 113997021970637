import React, { FC, useEffect } from 'react';
import { Box, Form, Text } from 'grommet';
import PrivatePageLayout from 'src/components/layouts/PrivatePageLayout/PrivatePageLayout';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from 'src/components/utils/CustomButton';
import FormTextInput from 'src/components/utils/FormTextInput';
import { validators } from 'src/helpers/validators';
import { RootState } from 'src/store/store';
import { getLocations } from 'src/store/location/location.slice';
import { addLocationAction, searchLocationsAction } from 'src/store/location/location.action';

const AddLocation: FC = () => {
  const dispatch = useDispatch();
  const locations = useSelector((state: RootState) => getLocations(state));

  useEffect(() => {
    if (locations.length === 0)
      dispatch(searchLocationsAction());
  }, []);

  function onSubmit(event) {
    dispatch(addLocationAction(event.value));
  }

  return (
    <PrivatePageLayout title='Add Location'>
      <Box>
        <Form
          onSubmit={onSubmit}
          validate="change"
        >
          <Box direction='row' justify='between' align='center'>
            <Text textAlign="center" size='large' weight={900} color="dark">Add Location</Text>
            <Box>
              <CustomButton
                primary
                type="submit"
                label="Save"
              />
            </Box>
          </Box>
          <Box pad="medium" width="100%">
            <FormTextInput
              id="nameInput"
              label="Name"
              name="name"
              validate={[validators.required("Name")]}
            />
          </Box>
        </Form>
      </Box>
    </PrivatePageLayout >
  )
};

export default AddLocation;
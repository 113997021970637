import React, { FC, useEffect } from 'react';
import { Box, Form } from 'grommet';
import PrivatePageLayout from 'src/components/layouts/PrivatePageLayout/PrivatePageLayout';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import {
  getTransferDetail,
  resetTransferUpdateDto,
  setTransferDetailEditMode,
  setTransferDetailsFormErrorCount,
} from 'src/store/transfer/transfer.slice';
import { useParams } from 'react-router-dom';
import {
  getTransferByIdAction,
  getTransferCompanyAction,
  getTransferLocationsAction,
  updateTransferAction,
} from 'src/store/transfer/transfer.action';
import NotFound from '../../NotFound';
import Loading from 'src/components/utils/Loading';
import TransferHeaderCard from './components/TransferHeaderCard';
import TransfersTabs from './components/TransferTabs';
import { UpdateImageAction } from 'src/store/transfer/transfer.types';

const TransferDetails: FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<any>();
  const {
    transfer,
    loading,
    isEditMode,
    transferUpdate,
  } = useSelector((state: RootState) => getTransferDetail(state));

  useEffect(() => {
    dispatch(setTransferDetailEditMode(false));
    if (!transfer || (transfer && transfer.id != id)) {
      dispatch(getTransferByIdAction(id));
    }
  }, []);

  useEffect(() => {
    if (transfer) {
      dispatch(getTransferCompanyAction(transfer.companyId));
      dispatch(getTransferLocationsAction());
      dispatch(resetTransferUpdateDto());
    }
  }, [transfer]);


  function onSubmit(event) {
    if (!isEditMode)
      return dispatch(setTransferDetailEditMode(true));

    let updateCount = 0;
    const entries = Object.entries(event.touched);
    for (const [key, value] of entries) {
      let transferValue = transfer[key];
      const eventValue = event.value[key];

      if (key === "luggageCarryOn") {
        transferValue = transfer.luggageAllowance.carryOn;
      }
      else if (key === "luggageCheckIn") {
        transferValue = transfer.luggageAllowance.checkIn;
      }

      if (value && eventValue != transferValue) {
        updateCount++;
      }
    }

    if (transferUpdate.selectedCompany && transferUpdate.selectedCompany.id !== transfer.companyId) updateCount++;
    if (transferUpdate.selectedPolicy && transferUpdate.selectedPolicy.id !== transfer.cancellationPolicyId) updateCount++;
    if (JSON.stringify(transferUpdate.updateDto.amenities) !== JSON.stringify(transfer.amenities)) updateCount++;

    const images = transferUpdate.updateImages;
    const defaultImages = images.filter(e => e.action === UpdateImageAction.ADD_DEFAULT).map(e => e.id);
    const fileImages = images.filter(e => e.action === UpdateImageAction.ADD_FILE).map(e => e.file);
    const removeImages = images.filter(e => e.action === UpdateImageAction.REMOVE).map(e => e.id);

    if (updateCount > 0 || defaultImages.length > 0 || fileImages.length > 0 || removeImages.length || 0) {
      const payload = { ...transferUpdate.updateDto };
      payload.disclaimerMessages = payload.disclaimerMessages.filter(e => e.trim() !== "");
      dispatch(updateTransferAction(transfer.id, payload, fileImages, defaultImages, removeImages));
    }
  }

  function onValidate(formEvent) {
    console.log("ONVALIDATE", formEvent);
    if (formEvent.errors) {
      const keys = Object.keys(formEvent.errors);
      dispatch(setTransferDetailsFormErrorCount(keys));
    }
  }

  return (
    <PrivatePageLayout title='Transfers'>
      <Box>
        {loading ?
          <Loading />
          : transfer ?
            <Form
              value={transferUpdate.updateDto}
              onSubmit={onSubmit}
              onValidate={onValidate}
              validate="change"
            >
              <TransferHeaderCard />
              <TransfersTabs />
            </Form>
            : <NotFound />
        }
      </Box>
    </PrivatePageLayout >
  )
};

export default TransferDetails;
import { Box, } from "grommet";
import { useDispatch, useSelector } from "react-redux";
import { getAddTransfer, setAddTransferDto } from "src/store/transfer/transfer.slice";
import CustomCheckBox from "src/components/utils/CustomCheckBox";

export default function AmenitiesTab() {
    const dispatch = useDispatch();
    const { payload } = useSelector((state) => getAddTransfer(state));

    return (
        <Box pad="medium">
            <CustomCheckBox
                label="TV"
                value={payload.amenities.tv}
                onChange={(event) => {
                    dispatch(setAddTransferDto({
                        ...payload,
                        amenities: {
                            ...payload.amenities,
                            tv: event.target.checked
                        }
                    }));
                }}
            />
            <CustomCheckBox
                label="Wifi"
                value={payload.amenities.wifi}
                onChange={(event) => {
                    dispatch(setAddTransferDto({
                        ...payload,
                        amenities: {
                            ...payload.amenities,
                            wifi: event.target.checked
                        }
                    }));
                }}
            />
            <CustomCheckBox
                label="Charge Unit"
                value={payload.amenities.chargeUnit}
                onChange={(event) => {
                    dispatch(setAddTransferDto({
                        ...payload,
                        amenities: {
                            ...payload.amenities,
                            chargeUnit: event.target.checked
                        }
                    }));
                }}
            />
            <CustomCheckBox
                label="Water Bottle"
                value={payload.amenities.waterBottle}
                onChange={(event) => {
                    dispatch(setAddTransferDto({
                        ...payload,
                        amenities: {
                            ...payload.amenities,
                            waterBottle: event.target.checked
                        }
                    }));
                }}
            />
            <CustomCheckBox
                label="Cold Drinks"
                value={payload.amenities.coldDrinks}
                onChange={(event) => {
                    dispatch(setAddTransferDto({
                        ...payload,
                        amenities: {
                            ...payload.amenities,
                            coldDrinks: event.target.checked
                        }
                    }));
                }}
            />
            <CustomCheckBox
                name="fridge"
                label="Fridge"
                value={payload.amenities.fridge}
                onChange={(event) => {
                    dispatch(setAddTransferDto({
                        ...payload,
                        amenities: {
                            ...payload.amenities,
                            fridge: event.target.checked
                        }
                    }));
                }}
            />
            <CustomCheckBox
                name="snacks"
                label="Snacks"
                value={payload.amenities.snacks}
                onChange={(event) => {
                    dispatch(setAddTransferDto({
                        ...payload,
                        amenities: {
                            ...payload.amenities,
                            snacks: event.target.checked
                        }
                    }));
                }}
            />
        </Box>
    )
}

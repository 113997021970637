import { http } from '../../config/http';
import { stringify as stringifyQuery } from 'querystring';
import { CreateReservationPayload, ReservationStatus } from './reservation.types';

export const getReservations = async (page = 1, take = 10, keyword?: string): Promise<any> => {
  const stringifiedQuery = stringifyQuery({ page: page, take: take, keyword: keyword });
  return http
    .get(`/transfers/reservations?${stringifiedQuery}`)
    .then((res) => res.data);
}

export const getReservationByCode = async (code: number): Promise<any> => {
  return http
    .get(`/transfers/reservations/${code}`)
    .then((res) => res.data);
}

export const getPassengerReservations = async (page = 1, take = 10, keyword?: string): Promise<any> => {
  const stringifiedQuery = stringifyQuery({ page: page, take: take, keyword: keyword });
  return http
    .get(`/transfers/reservations/my?${stringifiedQuery}`)
    .then((res) => res.data);
}

export const createReservation = async (body: CreateReservationPayload): Promise<any> => {
  return http
    .post(`/transfers/reservations`, body)
    .then((res) => res.data);
}

export const updateReservationStatus = async (id: number, status: ReservationStatus): Promise<any> => {
  return http
    .put(`/transfers/reservations/${id}/status`, { status })
    .then((res) => res.data);
}
import * as ReservationService from "src/store/reservation/reservation.service";
import { setReservationDetails, setReservationDetailsLoading, setReservations, setReservationsLoading } from "./reservation.slice";
import { CreateReservationPayload, ReservationStatus } from "./reservation.types";
import appHistory from "src/helpers/history";
import { setToastAction } from "../util/util.action";

export const searchReservationsAction = (page?: number, take?: number) => {
  return async (dispatch) => {
    try {
      dispatch(setReservationsLoading(true));
      const payload = await ReservationService.getReservations(page, take);
      dispatch(setReservations(payload));
      dispatch(setReservationsLoading(false));
    } catch (err: any) {
      dispatch(setReservationsLoading(false));
    }
  };
};

export const getPassengerReservationsAction = (page?: number, take?: number) => {
  return async (dispatch) => {
    try {
      dispatch(setReservationsLoading(true));
      const payload = await ReservationService.getPassengerReservations(page, take);
      dispatch(setReservations(payload));
      dispatch(setReservationsLoading(false));
    } catch (err: any) {
      dispatch(setReservationsLoading(false));
    }
  };
};

export const getReservationByCodeAction = (code: number) => {
  return async (dispatch) => {
    try {
      dispatch(setReservationDetailsLoading(true));
      const payload = await ReservationService.getReservationByCode(code);
      dispatch(setReservationDetails(payload));
      dispatch(setReservationDetailsLoading(false));
    } catch (err: any) {
      dispatch(setReservationDetailsLoading(false));
    }
  };
};

export const createReservationAction = (body: CreateReservationPayload) => {
  return async (dispatch) => {
    try {
      dispatch(setReservationDetailsLoading(true));
      const payload = await ReservationService.createReservation(body);
      dispatch(setReservationDetails(payload));
      dispatch(setToastAction('ok', "Reservation created"));
      appHistory.push(`/reservations/${payload.code}`);
      dispatch(setReservationDetailsLoading(false));
    } catch (err: any) {
      dispatch(setReservationDetailsLoading(false));
    }
  };
};

export const updateReservationStatusAction = (id: number, status: ReservationStatus) => {
  return async (dispatch) => {
    try {
      dispatch(setReservationDetailsLoading(true));
      const payload = await ReservationService.updateReservationStatus(id, status);
      dispatch(setReservationDetails(payload));
      dispatch(setReservationDetailsLoading(false));
    } catch (err: any) {
      dispatch(setReservationDetailsLoading(false));
    }
  };
};
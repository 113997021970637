import React, { useState } from 'react'
import { Box, Button, FormField, TextInput } from 'grommet'
import { Hide, View } from 'grommet-icons';

export default function FormPasswordInput(props) {
    const { id, name, label, margin, placeholder, value, onChange, validate } = props;
    const [passwordVisible, setPasswordVisible] = useState(false);
    return (
        <FormField
            label={label}
            name={name}
            htmlFor={id}
            validate={validate}
            margin={margin || "0px"}
        >
            <Box
                height="38px"
                direction="row"
                justify="between"
                align="center"
                round="small"
                margin="0px"
            >
                <TextInput
                    id={id}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    plain
                    focusIndicator={false}
                    type={passwordVisible ? 'text' : 'password'}
                />

                <Button
                    icon={
                        passwordVisible ? (
                            <View size="medium" />
                        ) : (
                            <Hide size="medium" />
                        )
                    }
                    onClick={() =>
                        setPasswordVisible(!passwordVisible)
                    }
                />
            </Box>
        </FormField>
    )
}
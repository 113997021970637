import { http } from '../../config/http';
import { stringify as stringifyQuery } from 'querystring';
import { SearchTransferPayload, Transfer, UpdateTransferPayload } from './transfer.types';
import { serialize } from 'object-to-formdata';

export const adminSearchTransfers = async (body: SearchTransferPayload): Promise<any> => {
  const stringifiedQuery = stringifyQuery({
    ...body,
    page: body.page ?? 1,
    take: body.take ?? 10,
  });
  return http
    .get(`/transfers/search?${stringifiedQuery}`)
    .then((res) => res.data);
}

export const searchTransfers = async (body: SearchTransferPayload): Promise<any> => {
  const stringifiedQuery = stringifyQuery({
    ...body,
    page: body.page ?? 1,
    take: body.take ?? 10,
  });
  return http
    .get(`/transfers?${stringifiedQuery}`)
    .then((res) => res.data);
}

export const getTransferById = async (id: number): Promise<any> => {
  return http
    .get(`/transfers/${id}`)
    .then((res) => res.data);
}

export const getTransferCompany = async (id: number): Promise<any> => {
  return http
    .get(`/transfers/companies/${id}`)
    .then((res) => res.data);
}

export const updateTransfer = async (
  id: number,
  updateDto: UpdateTransferPayload,
  fileImages: string[],
  defaultImages: string[],
  removeImages: string[],
): Promise<any> => {
  const formData = serialize(updateDto);
  for (const file of fileImages) formData.append("fileImages", file);
  for (const img of defaultImages) formData.append("defaultImages[]", img);
  for (const img of removeImages) formData.append("removeImages[]", img);

  return http
    .put(`/transfers/${id}`, formData)
    .then((res) => res.data);
}

export const addTransfer = async (
  payload: Transfer,
  fileImages: string[],
  defaultImages: string[],
): Promise<any> => {
  const formData = serialize(payload);
  for (const file of fileImages) formData.append("fileImages", file);
  for (const img of defaultImages) formData.append("defaultImages[]", img);

  return http
    .post(`/transfers`, formData)
    .then((res) => res.data);
}

export const duplicateTransfer = async (id: number): Promise<any> => {
  return http
    .post(`/transfers/${id}/duplicate`)
    .then((res) => res.data);
}
import React, { FC, useState } from 'react';
import { Box, Button, Form, Text } from 'grommet';
import PrivatePageLayout from 'src/components/layouts/PrivatePageLayout/PrivatePageLayout';
import { useDispatch } from 'react-redux';
import CustomButton from 'src/components/utils/CustomButton';
import { addCompanyAction } from 'src/store/company/company.action';
import FormTextArea from 'src/components/utils/FormTextArea';
import FormTextInput from 'src/components/utils/FormTextInput';
import { validators } from 'src/helpers/validators';
import { CustomPhoneInput } from 'src/components/utils/CustomPhoneInput/CustomPhoneInput';
import { faAdd, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AddCompany: FC = () => {
  const dispatch = useDispatch();
  const newPhoneField = {
    phone: "",
    isValid: false,
    countryCode: "90"
  };

  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [phoneCountryCode, setPhoneCountryCode] = useState<string>("90");
  const [phoneNumbers, setPhoneNumbers] = useState<any>([{ ...newPhoneField }]);
  const [isPhoneValid, setIsPhoneValid] = useState(false);

  const handlePhoneNumberChange = (countryCode: string, phone: string, valid: boolean): void => {
    setPhoneNumber(phone);
    setPhoneCountryCode(countryCode);
    setIsPhoneValid(valid);
  };

  const handleCompanyPhoneNumbersChange = (
    countryCode: string,
    phone: string,
    valid: boolean,
    index: number,
  ) => {
    const numbers = [...phoneNumbers];
    numbers[index].phone = phone;
    numbers[index].countryCode = countryCode;
    numbers[index].isValid = valid;
    setPhoneNumbers(numbers);
  };

  function onSubmit(event) {
    if (!isPhoneValid || phoneNumbers.some(e => !e.isValid)) return;
    const payload = {
      ...event.value,
      contactPhone: phoneNumber,
      contactCountryPhoneCode: phoneCountryCode,
      phoneNumbers: phoneNumbers.map(e => {
        return {
          phone: e.phone,
          countryPhoneCode: e.countryCode,
        }
      }),
    };
    dispatch(addCompanyAction(payload));
  }

  return (
    <PrivatePageLayout title='Add Company'>
      <Box>
        <Form
          onSubmit={onSubmit}
          validate="change"
        >
          <Box direction='row' justify='between' align='center'>
            <Text textAlign="center" size='large' weight={900} color="dark">Add Company</Text>
            <Box>
              <CustomButton
                primary
                type="submit"
                label="Save"
              />
            </Box>
          </Box>
          <Box pad="medium" width="100%">
            <FormTextInput
              id="companyNameInput"
              label="Company Name"
              name="companyName"
              validate={[validators.required("Company Name")]}
            />
            <FormTextInput
              id="taxNumberInput"
              label="Tax Number"
              name="taxNumber"
              validate={[validators.required("Tax Number")]}
            />
            <FormTextArea
              id="addressInput"
              label="Address"
              name="address"
              type="number"
              validate={[
                validators.required("Address"),
              ]}
            />
            <FormTextInput
              id="contactNameInput"
              label="Contact Name"
              name="contactName"
              validate={[validators.required("Contact Name")]}
            />
            <FormTextInput
              id="contactEmailSelect"
              label="Contact Email"
              name="contactEmail"
              validate={[validators.required("Contact Email")]}
            />
            <CustomPhoneInput
              label='Contact Phone'
              required
              value={phoneNumber}
              countryCode={phoneCountryCode}
              onChange={handlePhoneNumberChange}
            />
            <Box direction='row' margin={{ top: "10px", bottom: "5px", left: "15px" }}>
              <Text style={{ fontSize: "14px" }}>Company Phone Numbers</Text>
              <Button
                margin={{ left: "10px" }}
                onClick={() => {
                  setPhoneNumbers([...phoneNumbers, { ...newPhoneField }]);
                }}><FontAwesomeIcon icon={faAdd} /></Button>
            </Box>
            {phoneNumbers.map((e, i) => {
              return <Box
                key={i}
                direction='row'
                align='center'
              >
                <CustomPhoneInput
                  width='95%'
                  required
                  value={e.phone}
                  countryCode={e.countryCode}
                  onChange={(countryCode: string, phone: string, valid: boolean): void => {
                    handleCompanyPhoneNumbersChange(countryCode, phone, valid, i);
                  }}
                />
                <Box margin={{ bottom: "10px" }} width="5%" align='center' justify='center'>
                  <Button
                    disabled={i === 0}
                    onClick={() => {
                      const numbers = [...phoneNumbers];
                      numbers.splice(i, 1);
                      setPhoneNumbers(numbers);
                    }}>
                    <FontAwesomeIcon icon={faRemove} />
                  </Button>
                </Box>
              </Box>
            })}
          </Box>
        </Form>
      </Box>
    </PrivatePageLayout >
  )
};

export default AddCompany;
import React, { FC, useEffect, useState } from 'react';
import {
  Box, ColumnConfig, DataTable, Pagination, Text
} from 'grommet';
import PrivatePageLayout from 'src/components/layouts/PrivatePageLayout/PrivatePageLayout';
import CustomButton from 'src/components/utils/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { useHistory } from 'react-router-dom';
import Loading from 'src/components/utils/Loading';
import { ReservationRowType } from 'src/store/reservation/reservation.types';
import { getReservations, getReservationsLoading, setReservationDetails } from 'src/store/reservation/reservation.slice';
import { getPassengerReservationsAction, searchReservationsAction } from 'src/store/reservation/reservation.action';
import { getUser } from 'src/store/auth/auth.slice';
import { Role } from 'src/models/enums';

const columns: ColumnConfig<ReservationRowType>[] = [
  {
    property: 'code',
    header: "Code",
    primary: true,
  },
  {
    property: 'price',
    header: 'Price',
    render: (datum) => `${datum.price} ${datum.currency}`,
  },
  {
    property: 'pickupDate',
    header: "Date"
  },
  {
    property: 'fromAddress',
    header: "From"
  },
  {
    property: 'toAddress',
    header: 'To',
  },
  {
    property: 'status',
    header: 'Status',
  },
];

const Reservations: FC = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state: RootState) => getUser(state));
  const reservations = useSelector((state: RootState) => getReservations(state));
  const loading = useSelector((state: RootState) => getReservationsLoading(state));
  const [page, setPage] = useState(1);
  const [indices, setIndices] = useState([0, 10]);

  useEffect(() => {
    if (user.role === Role.USER)
      dispatch(getPassengerReservationsAction(page));
    else
      dispatch(searchReservationsAction(page));
  }, [page]);

  useEffect(() => {
    setIndices([0, reservations.data.length]);
  }, [reservations]);

  const handleChange = ({ page, startIndex, endIndex }) => {
    setPage(page);
    setIndices([startIndex, Math.min(endIndex, reservations.meta.itemCount)]);
  };

  return (
    <PrivatePageLayout title='Reservations'>
      {(user.role === Role.SUPER_ADMIN || user.role === Role.ADMIN) &&
        <Box direction='row' justify='between' align='center'>
          <Text textAlign="center" size='large' weight={900} color="dark">Reservations</Text>
          <Box>
            <CustomButton
              primary
              type="button"
              label="Add Reservation"
              onClick={() => history.push('/reservations/add')}
            />
          </Box>
        </Box>
      }
      <Box margin={{ top: "medium" }}>
        {loading ?
          <Loading /> :
          <>
            <DataTable
              columns={columns}
              data={reservations.data}
              sortable={false}
              resizeable={true}
              step={reservations.meta.take}
              onClickRow={(event) => {
                const reservation = reservations.data.find(e => e.code == event.datum.code);
                dispatch(setReservationDetails(reservation));
                history.push(`/reservations/${event.datum.code}`);
              }}
            />
            <Box direction='row' align="center" margin={{ top: "20px" }} justify="between" height="20px" width="100%">
              <Text>
                Showing {indices[0] + 1} - {indices[1]} of {reservations.meta.itemCount}
              </Text>
              <Pagination numberItems={reservations.meta.itemCount} page={page} onChange={handleChange} />
            </Box>
          </>
        }
      </Box>
    </PrivatePageLayout>
  )
};

export default Reservations;
import React, { FC, useState } from 'react';
import {
    Box,
    Button,
    Form,
    Text,
} from 'grommet';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/store/store';
import { getUser } from 'src/store/auth/auth.slice';
import { validators } from 'src/helpers/validators';
import PrivatePageLayout from 'src/components/layouts/PrivatePageLayout/PrivatePageLayout';
import FormTextInput from 'src/components/utils/FormTextInput';
import { CustomPhoneInput } from 'src/components/utils/CustomPhoneInput/CustomPhoneInput';
import FormPasswordInput from 'src/components/utils/FormPasswordInput';
import { UpdateProfilePayload } from 'src/store/user/user.types';
import { updateProfileInfoAction } from 'src/store/user/user.action';
import { updatePasswordAction } from 'src/store/auth/auth.action';
import { UpdatePasswordPayload } from 'src/store/auth/auth.types';

const Profile: FC = (props) => {
    const user = useSelector((state: RootState) => getUser(state));
    const dispatch = useDispatch();

    const [userPayload, setUserPayload] = useState<UpdateProfilePayload>({
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        phone: user.phone || '',
        countryPhoneCode: user.countryPhoneCode || '90',
    });

    const [passwordPayload, setPasswordPayload] = useState<UpdatePasswordPayload>({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    });

    const { t } = useTranslation();

    if (!user) return null;

    const isFormInitialState =
        userPayload.firstName === user.firstName &&
        userPayload.lastName === user.lastName &&
        userPayload.phone === user.phone &&
        userPayload.countryPhoneCode === user.countryPhoneCode;

    const isPasswordFormInitialState =
        passwordPayload.currentPassword === '' ||
        passwordPayload.newPassword === '' ||
        passwordPayload.confirmNewPassword === '';

    const handleSubmitPassword = ({ value }) => {
        dispatch(updatePasswordAction(value, () => {
            window.location.reload();
        }));
    };

    const handleSaveChanges = () => {
        if (isFormInitialState || (userPayload.phone && !isPhoneValid)) return;
        const body = { ...userPayload };
        if (body.phone.length === 0) {
            body.phone = null;
            body.countryPhoneCode = null;
        }
        dispatch(updateProfileInfoAction(body));
    };

    const [isPhoneValid, setPhoneValid] = useState(true);
    const handlePhoneNumberChange = (countryCode: string, phone: string, valid: boolean) => {
        setPhoneValid(valid);
        setUserPayload({ ...userPayload, phone: phone, countryPhoneCode: countryCode });
    };

    return (
        <PrivatePageLayout title="Profile">
            <Box>
                <Form onSubmit={handleSaveChanges}>
                    <Text style={{ fontWeight: "bold" }}>Personel Information</Text>
                    <FormTextInput
                        id="firstNameInput"
                        label="First Name"
                        name="firstName"
                        validate={[validators.required("First Name")]}
                        value={userPayload.firstName}
                        onChange={(event) => {
                            setUserPayload({ ...userPayload, firstName: event.target.value });
                        }}
                    />
                    <FormTextInput
                        id="lastNameInput"
                        label="Last Name"
                        name="lastName"
                        validate={[validators.required("Last Name")]}
                        value={userPayload.lastName}
                        onChange={(event) => {
                            setUserPayload({ ...userPayload, lastName: event.target.value });
                        }}
                    />
                    <CustomPhoneInput
                        label='Contact Phone'
                        required={false}
                        value={userPayload.phone}
                        countryCode={userPayload.countryPhoneCode}
                        onChange={handlePhoneNumberChange}
                    />
                    <Button
                        primary
                        disabled={isFormInitialState}
                        label="Save"
                        type='submit'
                    />
                </Form>
                <Box margin={{ vertical: "30px" }} gap="small">
                    <Form
                        id="passwordForm"
                        onSubmit={handleSubmitPassword}
                    >
                        <Text style={{ fontWeight: "bold" }}>Change Password</Text>
                        <FormPasswordInput
                            id="currentPassword"
                            label="Current Password"
                            name="currentPassword"
                            value={passwordPayload.currentPassword}
                            validate={[
                                validators.required("Current Password")
                            ]}
                            onChange={(event) => {
                                setPasswordPayload({ ...passwordPayload, currentPassword: event.target.value });
                            }}
                        />
                        <FormPasswordInput
                            id="newPassword"
                            label="New Password"
                            name="newPassword"
                            value={passwordPayload.newPassword}
                            validate={[
                                validators.required("Current Password"),
                                validators.minLength(t('common.password'), 6),
                                validators.equalsField(
                                    'confirmNewPassword',
                                    t('common.passwords')
                                ),
                            ]}
                            onChange={(event) => {
                                setPasswordPayload({ ...passwordPayload, newPassword: event.target.value });
                            }}
                        />
                        <FormPasswordInput
                            id="confirmNewPassword"
                            label="Confirm New Password"
                            name="confirmNewPassword"
                            value={passwordPayload.confirmNewPassword}
                            validate={[
                                validators.required("Current Password"),
                                validators.minLength(t('common.password'), 6),
                                validators.equalsField('newPassword', t('common.passwords')),
                            ]}
                            onChange={(event) => {
                                setPasswordPayload({ ...passwordPayload, confirmNewPassword: event.target.value });
                            }}
                        />
                        <Button
                            primary
                            disabled={isPasswordFormInitialState}
                            margin={{ vertical: "10px" }}
                            type="submit"
                            label="Update Password"
                        />
                    </Form>
                </Box>
            </Box>
        </PrivatePageLayout>
    );
};

export default Profile;
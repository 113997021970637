import { Box, Spinner } from 'grommet'
import React from 'react'

export default function Loading() {
    return (
        <Box align="center" justify="center" width="100%" height="100%">
            <Spinner size='medium' />
        </Box>
    )
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { DefaultImagesState } from "./images.types";

const DefaultImagesSliceInitialState: DefaultImagesState = {
    images: [],
    loading: false,
};

export const imageSlice: any = createSlice({
    name: "default-images",
    initialState: DefaultImagesSliceInitialState,
    reducers: {
        setImagesLoading: (state, action: PayloadAction<any>) => {
            state.loading = action.payload;
        },
        setImages: (state, action: PayloadAction<any>) => {
            state.images = action.payload;
            state.loading = false;
        },
        addImage: (state, action: PayloadAction<any>) => {
            state.images.push(action.payload);
            state.loading = false;
        },
        setImageRemoved: (state, action: PayloadAction<any>) => {
            state.images = state.images.filter(e => e.id !== action.payload);
            state.loading = false;
        },
    },
});

export const {
    setImagesLoading,
    setImages,
    setImageRemoved,
    addImage,
} = imageSlice.actions;

export const getImages = (state: RootState) => state.defaultImages.images;
export const getImagesLoading = (state: RootState) => state.defaultImages.loading;
export const getImageUploadLoading = (state: RootState) => state.defaultImages.uploadLoading;

export default imageSlice.reducer;

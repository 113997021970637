import { nanoid } from 'nanoid';
import { ToastStatus } from './util.types';
import { removeToast, setToast } from './util.slice';

export const setToastAction = (
  status: ToastStatus,
  message: string,
  timeOut = 5000,
  toastId?: string
) => {
  return (dispatch) => {
    const id = toastId || nanoid();
    dispatch(setToast({
      status,
      toastId: id,
      message,
    }));

    if (timeOut) {
      setTimeout(() => {
        dispatch(removeToastAction(id));
      }, timeOut);
    }
  };
};

export const removeToastAction = (id: string) => {
  return (dispatch) =>
    dispatch(removeToast(id));
};

import { Avatar, Box, Button, Header, Layer } from 'grommet';
import React, { FC, useState } from 'react';
import CustomHeader from './Header';
import CustomSideBar from './SideBar';

interface Props {
  topComponent?: React.ReactNode;
  rightComponent?: React.ReactNode;
}

const Mobile: FC<Props> = (props) => {
  const [showLeftSidebar, setShowLeftSidebar] = useState(false);
  return (
    <Box elevation="xlarge" width="100%" height='100vh' style={{ height: "100vh" }}>
      <CustomHeader showLeftMenuButton={true} leftMenuButtonOnClick={() => { setShowLeftSidebar(!showLeftSidebar) }} />
      <Box direction="row" width="100%">
        {showLeftSidebar && <CustomSideBar />}
        <Box pad={{ vertical: 'medium', horizontal: "medium" }} width="100%" style={{ overflow: "auto" }}>
          {props.children}
        </Box>
      </Box>
    </Box >
  );
};

export default Mobile;

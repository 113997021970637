import { persistor } from "src/store/store";

const ACCESS_TOKEN_KEY = `WESTLEYBLUE_ACCESS_TOKEN`;

export const setToken = (token: string) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
}

export const getToken = () => {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}

export const removeToken = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
}

export const removePersistRoot = () => {
  persistor.pause();
  persistor.flush().then(() => {
    return persistor.purge();
  });
}

export const setRedirectPath = (path: string) => {
  localStorage.setItem("redirectPath", path);
}

export const getRedirectPath = () => {
  return localStorage.getItem("redirectPath");
}

export const removeRedirectPath = () => {
  localStorage.removeItem("redirectPath");
}
import React from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
  useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { getRedirectPath } from 'src/helpers/localdb';

interface Props extends RouteProps {
  component:
  | React.ComponentType<RouteComponentProps<any>>
  | React.ComponentType<any>;
}

const PublicRoute: React.FC<Props> = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useSelector(
    (state: RootState) => state.auth
  );
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated) return <Component {...props} />;
        const path = getRedirectPath();
        if (path) return <Redirect to={{
          pathname: path,
          search: location.search,
        }} />;
        return <Redirect to="/home" />;
      }}
    />
  );
};

export default PublicRoute;

import { faAdd, faSubtract } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Text } from "grommet";
import { useDispatch, useSelector } from "react-redux";
import FormTextArea from "src/components/utils/FormTextArea";
import FormTextInput from "src/components/utils/FormTextInput";
import { validators } from "src/helpers/validators";
import { addDetailsTabUpdateField, getTransferDetail, removeDetailsTabUpdateField, setTransferUpdateDto } from "src/store/transfer/transfer.slice";
import { DisclaimerMessages } from "../../components/DisclaimerMessages";

export default function DetailsTab() {
    const dispatch = useDispatch();
    const { transfer, isEditMode, transferUpdate } = useSelector((state) => getTransferDetail(state));

    function handleUpdateCount(condition: boolean, field: string) {
        if (condition) dispatch(addDetailsTabUpdateField(field));
        else dispatch(removeDetailsTabUpdateField(field));
    }

    function instructionsOnChange(event) {
        dispatch(setTransferUpdateDto({ ...transferUpdate.updateDto, instructions: event.target.value }))
        handleUpdateCount(event.target.value != transfer.instructions, "instructions");
    }

    function taxAndVatOnChange(event) {
        dispatch(setTransferUpdateDto({ ...transferUpdate.updateDto, taxAndVatInfo: event.target.value }))
        handleUpdateCount(event.target.value != transfer.taxAndVatInfo, "taxAndVatInfo");
    }

    function videoLinkOnChange(event) {
        dispatch(setTransferUpdateDto({ ...transferUpdate.updateDto, pickupVideoTutLink: event.target.value }))
        handleUpdateCount(event.target.value != transfer.pickupVideoTutLink, "pickupVideoTutLink");
    }

    function messagesOnChange(index: number, event) {
        const data = [...transferUpdate.updateDto.disclaimerMessages];
        data[index] = event.target.value;
        dispatch(setTransferUpdateDto({
            ...transferUpdate.updateDto,
            disclaimerMessages: data,
        }));
        handleUpdateCount(JSON.stringify(data) !== JSON.stringify(transfer.disclaimerMessages), "disclaimerMessages");
    }

    function messagesOnRemove(index: number, event) {
        dispatch(setTransferUpdateDto({
            ...transferUpdate.updateDto,
            disclaimerMessages: transferUpdate.updateDto.disclaimerMessages.filter((e, messageIndex) => index !== messageIndex),
        }));
        handleUpdateCount(false, "disclaimerMessages");
    }

    function messagesOnAdd(event) {
        dispatch(setTransferUpdateDto({
            ...transferUpdate.updateDto,
            disclaimerMessages: [...transferUpdate.updateDto.disclaimerMessages, ""]
        }));
    }

    return (
        <Box pad="10px">
            {isEditMode ?
                <>
                    <FormTextArea
                        id="instructions"
                        label="Pick Up Instructions"
                        name="instructions"
                        validate={[validators.required("Instructions")]}
                        value={transferUpdate.updateDto.instructions}
                        onChange={instructionsOnChange}
                        rows={5}
                    />
                    <FormTextInput
                        id="videoLinkInput"
                        label="Pick Up Video Link"
                        name="videoTutLink"
                        value={transferUpdate.updateDto.pickupVideoTutLink}
                        onChange={videoLinkOnChange}
                    />
                    <FormTextArea
                        id="taxAndVatInput"
                        label="Tax And Vat Info"
                        name="taxAndVatInfo"
                        validate={[validators.required("Tax and vat info")]}
                        value={transferUpdate.updateDto.taxAndVatInfo}
                        onChange={taxAndVatOnChange}
                        rows={3}
                    />
                    <DisclaimerMessages
                        messages={transferUpdate.updateDto.disclaimerMessages}
                        messagesOnAdd={messagesOnAdd}
                        messagesOnRemove={messagesOnRemove}
                        messagesOnChange={messagesOnChange}
                    />

                </> :
                <Box round="8px" pad="10px">
                    <Box margin="10px">
                        <Text weight="bold" size="20px">Instructions</Text>
                        <Text>{transfer.instructions}</Text>
                    </Box>
                    <Box margin="10px">
                        <Text weight="bold" size="18px">Tax and Vat Info</Text>
                        <Text>{transfer.taxAndVatInfo}</Text>
                    </Box>
                    <Box margin="10px">
                        <Text margin={{ top: "18px" }} weight="bold" size="20px">Video Tutorial Link</Text>
                        <Text>{transfer.pickupVideoTutLink ?? "-"}</Text>
                    </Box>
                    <Box margin="10px">
                        <Text margin={{ top: "18px" }} weight="bold" size="20px">Disclaimer Messages</Text>
                        {
                            transfer.disclaimerMessages && transfer.disclaimerMessages.length > 0 ?
                                transfer.disclaimerMessages.map((e, i) => <Text key={i}>{e}</Text>)
                                : "-"
                        }
                    </Box>
                </Box>
            }

        </Box>
    )
}
